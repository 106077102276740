import API from "../store/utils/APIUtils";
const authToken = "";

export const applicantLoginAPIHelper = async (token) => {
  const response = await API.post(
    `/applicant/login/`,
    {
      token_id: token,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const recruiterLoginAPIHelper = async (token) => {
  const response = await API.post(
    `/recruiter/login/`,
    {
      token_id: token,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const applicantOnboardingAPIHelper = async (recruiter_data) => {
  const response = await API.post(`/applicant/onboarding/`, recruiter_data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const recruiterOnboardingAPIHelper = async (recruiter_data) => {
  const response = await API.post(`/recruiter/onboarding/`, recruiter_data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const fetchRecruiterProfileAPIHelper = async (accessToken) => {
  const response = await API.get(`/recruiter/profile/`, {
    headers: {
      Authorization: `token ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateRecruiterProfileAPIHelper = async (recruiter_data) => {
  const response = await API.post(
    `/recruiter/profile/`,
    {
      name: recruiter_data.values.name,
      phoneNumber: recruiter_data.values.phone,
      linkedInUrl: recruiter_data.values.linkedIn,
      // companyName: recruiter_data.values.companyName,
      // companyLogo: recruiter_data.values.companyLogo,
      // companyWebsite: recruiter_data.values.companyWebsite,
      // companyLocation: recruiter_data.values.companyLocation,
      // companyAddress: recruiter_data.values.companyAddress,
    },
    {
      headers: {
        Authorization: "token " + recruiter_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const fetchRecruiterDashboardAPIHelper = async (accessToken) => {
  const response = await API.get(`/recruiter/dashboard/`, {
    headers: {
      Authorization: `token ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};

// export const renewAccessTokenAPIHelper = async (refreshToken) => {
//   const response = await API.post(
//     `v1/operator/refresh/token/`,
//     {
//       refreshToken: refreshToken,
//     },
//     {
//       headers: {
//         // Authorization: "token " + accessToken,
//         "Content-Type": "application/json",
//       },
//     }
//   );

//   return response;
// };
