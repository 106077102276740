import React from "react";
import { Steps, Avatar, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CheckOutlined from '@ant-design/icons';

const { Step } = Steps;



function ApplicantPositionActivity(props) {

  const interviewDetails = useSelector(
    (state) => state?.jobReducer?.InterviewList
  );
  // console.log(interviewDetails[1].evaluator, "hvfhvsehfbervbjh");

  return (
    <>
      <Row>
        {/* {console.log(props.jobDashboard, "JOBDASHBOARD 1")} */}
        <Steps current={4} direction="vertical">
          <Step
            title="Online Exam"
            labelPlacement="horizontal"
            description={`Interview By : ${interviewDetails[0]?.evaluator}, Scheduled By : ${interviewDetails[0]?.createdBy}`}
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#abf7b1" }}
                size={32}
              >
                {props?.jobDashboard?.totalappliedcount
                  ? props?.jobDashboard?.totalappliedcount
                  : <CheckOutlined />}
              </Avatar>
            }
          />
          <Step
            title="Technical"
            description={`Interview By : ${interviewDetails[1]?.evaluator}, Scheduled By : ${interviewDetails[1]?.createdBy}`}
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#83f28f" }}
                size={32}
              >
                {props?.jobDashboard?.shortlistedCount
                  ? props?.jobDashboard?.shortlistedCount
                  : <CheckOutlined />}
              </Avatar>
            }
          />
          <Step
            title="Managerial"
            // description={`Interview By : ${interviewDetails[2].evaluator}, Scheduled By : ${interviewDetails[2].createdBy}`}
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#5ced73" }}
                size={32}
              >
                {props?.jobDashboard?.offeredCount
                  ? props?.jobDashboard?.offeredCount
                  : <CheckOutlined />}
              </Avatar>
            }
          />
          <Step
            title="HR"
            // description={`Interview By : ${interviewDetails[3].evaluator}, Scheduled By : ${interviewDetails[3].createdBy}`}
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#39e75f" }}
                size={32}
              >
                {props?.jobDashboard?.hiredCount
                  ? props?.jobDashboard?.hiredCount
                  : <CheckOutlined />}
              </Avatar>
            }
          />
          <Step
            title="Onboarding"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#1fd655" }}
                size={32}
              >
                {props?.jobDashboard?.rejectedCount
                  ? props?.jobDashboard?.rejectedCount
                  : <CheckOutlined />}
              </Avatar>
            }
          />
        </Steps>
      </Row>
    </>
  );
}

export default ApplicantPositionActivity;
