import React, { useState } from "react";
import { Button, Col, Drawer, Form, Input, Row, Select, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addMember, fetchMember } from "../store/reducers/AddTeamMemberReducer";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";

function AddTeamMember() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);
  const choiceOptions = useSelector(
    (state) => state?.otherReducer?.Choices
  );
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    var raw = JSON.stringify({
      name: values.name,
      role: values.role,
      email: values.email,
    });

    const member_data = { values: raw, accessToken: authData?.token };
    dispatch(addMember(member_data))
      .then((response) => {
        if(response.payload.data.success==true) {
          message.success(" Successfully invited team member")
          dispatch(fetchMember(authData?.token));
          dispatch(fetchRecruiterDashboard(authData?.token)).then((response) => onClose());
        }
        else{
          message.error("member already invited");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        style={{
          color: "white",
          backgroundColor: "#1890FF",
          border: "none",
        }}
      >
        Invite
      </Button>
      <Drawer
        title="Add Team Member"
        width="55%"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onClose={onClose}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item name="role" label="Role">
              <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Role"
                  optionLabelProp="label"
                  options={
                    choiceOptions &&
                    choiceOptions?.Roles?.map((e) => ({
                      label: e[1]?.slice(0, 1).toUpperCase() + e[1]?.slice(1),
                      value: e[0],
                    }))
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
}
export default AddTeamMember;
