import API from "../store/utils/APIUtils";

export const fetchJobAPIsHelper = async (token) => {
  const response = await API.get(`/recruiter/jobs/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchJobAPIsHelper
};

export const addJobAPIsHelper = async (job_data) => {
  const response = await API.post(`/recruiter/jobs/`, job_data.values, {
    headers: {
      Authorization: "Token " + job_data.accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // addJobAPIsHelper
};

export const addBulkJobAPIsHelper = async (job_data) => {
  const response = await API.post(`/recruiter/bulk-jobs/`, job_data.values, {
    headers: {
      Authorization: "Token " + job_data.accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // addJobAPIsHelper
};

export const updateJobAPIsHelper = async (job_data) => {
  if (job_data) {
    const response = await API.put(
      `recruiter/update/${job_data.jobId}/job/`,
      job_data.values,
      {
        headers: {
          Authorization: `token ${job_data.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // updateJobAPIsHelper
  }
};

export const deleteJobAPIsHelper = async (job_data) => {
  const response = await API.delete(
    // `//?applicantId=${job_data.id}`,
    `/recruiter/update/${job_data.id}/job/`,
    {
      headers: {
        Authorization: "Token " + job_data.access_token,
        "Content-Type": "application/json",
      },
    }
  );

  return response; // deleteJobAPIsHelper
};
