import React, { useState, useEffect } from "react";
import { Card, Typography, Steps, Col, Table, Button, Row, Space } from "antd";
import { useSelector } from "react-redux";
import ViewResume from "../ats_components/ViewResume";
import { Link } from "react-router-dom";

const { Title } = Typography;

function UpcomingInterviews() {
  const data = useSelector((state) => state?.jobReducer?.jobs?.jobs);
  const profileDetails = useSelector((state) => state?.auth?.auth_profile);
  console.log(data, "knfjksnknenke");

  const results = data?.map((row) => ({
    key: row?.id, // I added this line
    name: row?.name,
    round: (
      <>
        {data?.map((e) => (
          <div key={e?.round}>
            <p>
              {e?.round?.name} ({e?.round?.round})
            </p>
          </div>
        ))}
      </>
    ),
    scheduledDate: row?.scheduledDate,
    evaluator: row?.evaluator,
    resume: row?.resume,
  }));

  // console.log(results, "hgvchshcvvvbdbv");

  const columns = [
    {
      title: "Batches",
      dataIndex: ["title"],
      key: "title",
      render: (text, row) => (
        <p>
          <b>{row["title"]}</b>
        </p>
      ),
    },
    // {
    //   title: "Actions",
    //   dataIndex: "id",
    //   key: "id",
    //   render(_, { id, is_published }) {
    //     if (is_published === false) {
    //       return profileDetails.role === "admin" ||
    //         profileDetails.role === "manager" ||
    //         profileDetails.role === "Hiring-manager" ? (
    //         <div>
    //           <Link to={{ pathname: `./positionSummary/${id}` }}>
    //             <Button style={{ color: "#d48806" }}> View Batch </Button>
    //           </Link>
    //         </div>
    //       ) : (
    //         <div>
    //           {/* <Link to={{ pathname: `./positionSummary/${id}` }}> */}
    //           <p style={{ color: "black" }}> - </p>
    //           {/* </Link> */}
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div>
    //           <Link to={{ pathname: `./positionSummary/${id}` }}>
    //             <Button> View Batch </Button>
    //           </Link>
    //         </div>
    //       );
    //     }
    //   },
    // },
    // =>
    // is_published === true ||
    // profileDetails.role === "admin" ||
    // profileDetails.role === "manager" ||
    // profileDetails.role === "Hiring-manager" ? ({
    //   if(is_published === false){
    //   return(
    //     <div>
    //     <Link to={{ pathname: `./positionSummary/${id}` }}>
    //       <a> Info </a>
    //     </Link>
    //   </div>)}
    // }) : (
    //   <div>
    //     {/* <Link to={{ pathname: `./positionSummary/${id}` }}> */}
    //     <a style={{ color: "white" }}> Info </a>
    //     {/* </Link> */}
    //   </div>
    // ),
    // },
    {
      title: "Instructor",
      dataIndex: ["hiringManager", "contributors"],
      key: "contributors",
      render: (_, { contributors, hiringManager }) => (
        <>
          {hiringManager.map((e) => {
            return <Space>Lead Instructor : {e?.name}</Space>;
          })}
          {contributors.map((e) => {
            return <Space>Asst Instructor : {e?.name}</Space>;
          })}
        </>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: ["is_published", "desired_start_date", date2],
    //   key: "status",
    //   render(_, { desired_start_date, is_published }) {
    //     if (
    //       Number(date2 - desired_start_date?.slice(0, 10).split("-").join("")) <
    //         0 &&
    //       is_published === true
    //     ) {
    //       return (
    //         <div>
    //           <b>
    //             <p>Upcoming</p>
    //           </b>
    //         </div>
    //       );
    //     } else if (
    //       (Number(
    //         date2 - desired_start_date?.slice(0, 10).split("-").join("")
    //       ) >= 0 &&
    //         is_published === true) ||
    //       is_published === true
    //     ) {
    //       return (
    //         <div>
    //           <b>
    //             <p style={{ color: "#389e0d" }}>Ongoing</p>
    //           </b>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div>
    //           <b>
    //             <p style={{ color: "#d48806" }}>Completed
    //             </p>
    //           </b>
    //         </div>
    //       );
    //     }
    //   },
    //   width: "10%" ,
    // },
    {
      title: "Start Date",
      // dataIndex: ["updatedOn", "filledOn"],
      dataIndex: "desired_start_date",
      key: "desired_start_date",
      render: (desired_start_date) => (
        <>
          <p>
            {" "}
            {desired_start_date !== null
              ? desired_start_date?.slice(0, 10)
              : desired_start_date?.slice(0, 10)}{" "}
          </p>
        </>
      ),
      // sorter: {
      //   compare: (a, b) =>
      //     moment(a?.updatedOn || a?.filledOn, "YYYY-MM-DD") -
      //     moment(b?.updatedOn || b?.filledOn, "YYYY-MM-DD"),
      // },
    },
    {
      title: "Trainees",
      dataIndex: "jobApplicantsCount",
      key: "jobApplicantsCount",
      sorter: (a, b) => a?.jobApplicantsCount - b?.jobApplicantsCount,
      sortDirections: ["descend", "ascend"],
      render: (jobApplicantsCount) => (
        <>
          <p> {jobApplicantsCount} </p>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render(_, { id, is_published }) {
        if (is_published === false) {
          return profileDetails.role === "admin" ||
            profileDetails.role === "manager" ||
            profileDetails.role === "Hiring-manager" ? (
            <div>
              <Link to={{ pathname: `/requisition/positionSummary/${id}` }}>
                <Button style={{ color: "#d48806" }}> View Batch </Button>
              </Link>
            </div>
          ) : (
            <div>
              {/* <Link to={{ pathname: `./positionSummary/${id}` }}> */}
              <p style={{ color: "black" }}> - </p>
              {/* </Link> */}
            </div>
          );
        } else {
          return (
            <div>
              <Link to={{ pathname: `/requisition/positionSummary/${id}` }}>
                <Button> View Batch </Button>
              </Link>
            </div>
          );
        }
      },
    },
  ];

  // const columns = [
  //   {
  //     title: "Batch ID",
  //     dataIndex: ["title", "id"],
  //     key: "name",
  //     width: "10rem",
  //   },
  //   {
  //     title: "Stage",
  //     dataIndex: "round",
  //     key: "round",
  //     width: "10rem",
  //     render: (round) => (
  //       <>
  //         {round?.name} ({round?.round})
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Start Date",
  //     dataIndex: "desired_start_date",
  //     key: "desired_start_date",
  //     width: "10rem",
  //   },
  //   {
  //     title: "Instructor",
  //     dataIndex: "evaluator",
  //     key: "evaluator",
  //     width: "10rem",
  //   },
  //   // {
  //   //   title: "Resume",
  //   //   dataIndex: "resume",
  //   //   key: "resume",
  //   //   width: "10rem",
  //   //   render: (resume_url) => (
  //   //     <Space wrap>
  //   //       {/* <Link to={"/applicants/resume"} state={{ resume_url: resume_url }} > */}
  //   //       <ViewResume resume_url={resume_url} />
  //   //       {/* </Link> */}
  //   //     </Space>
  //   //   ),
  //   // },
  // ];

  // Object.keys(data).forEach(function(key, index) {
  //   // console.log(key, "ugeduwhfbhfbeh");
  // });

  const source = [];
  for (let i = 0; i < data?.length - 1; i++) {
    source.push({
      key: i,
      name: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.name}>
                <p>{e?.name}</p>
              </div>
            ))}
          </div>
        </>
      ),
      round: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.round}>
                <p>
                  {e?.round?.name} ({e?.round?.round})
                </p>
              </div>
            ))}
          </div>
        </>
      ),
      scheduledDate: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.scheduledDate}>
                <p>{e?.scheduledDate}</p>
              </div>
            ))}
          </div>
        </>
      ),
      evaluator: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.evaluator}>
                <p>{e?.evaluator}</p>
              </div>
            ))}
          </div>
        </>
      ),
      // resume: (
      //   <>
      //     <div>
      //       {data.map((e) => (
      //         <div key={e.resume_url}>
      //           <p>
      //             {e.resume_url}
      //             <Space wrap>

      //               <ViewResume resume_url={e.resume_url} />

      //             </Space>
      //           </p>
      //         </div>
      //       ))}
      //     </div>
      //   </>
      // ),
    });
  }
  return (
    <Card style={{ marginBlock: "0.05%" }}>
      {/* <Row style={{ justifyContent: "space-between" }}> */}
      <Title level={3} style={{ fontSize: "22px", fontWeight: "normal" }}>
        Current Batches
      </Title>
      {/* </Row> */}
      {/* <Card style={{ width: "100%", height:"30vh" }}> */}
      <Table
        style={{ padding: "0px", textAlign: "center", height: "35vh" }}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 200 }}
        size="small"
        // rowKey={Object.keys(data).forEach(function(key, index) {
        //   // console.log(key, "ugeduwhfbhfbeh");
        // })}
      />
      {/* </Card> */}
    </Card>
  );
}

export default UpcomingInterviews;
