import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";
import Header from "./ats_components/Header";
import MenuBar from "./ats_components/MenuBar";
import ViewResume from "./ats_components/ViewResume";
import ApplicantTracker from "./ats_pages/ApplicantTracker";
import PositionTracker from "./ats_pages/PositionTracker";
import Requisition from "./ats_pages/RequisitionPage";
import Dashboard from "./ats_pages/Dashboard";
import Home from "./ats_pages/Home";
import { useSelector } from "react-redux";
import LoginPage from "./ats_pages/LoginPage";
import AllApplicant from "./ats_pages/AllApplicant";
import ResumeProcess from "./ats_pages/ResumeProcess";
import ApplicantDashboard from "./ats_pages/ApplicantDashboard";
const { Sider } = Layout;

function App() {
  const djangoToken = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(false);
  };
  const toggleCloseCollapsed = () => {
    setCollapsed(true);
  };
  return (
    <>
      {djangoToken.is_authenticated && djangoToken.firebaseResponse ? (
        <Layout
          style={{
            minHeight: "100vh",
            minWidth: "100%",
            backgroundColor: "#F0F2F4",
          }}
        >
          <Header />
          {djangoToken.is_applicantPortal ? (
            <>
              <Routes>
                <Route path="/applicant/home" element={<ApplicantDashboard />} />
              </Routes>
            </>
          ) : (
            <Layout
              style={{
                border: "shadow",
              }}
            >
              <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onMouseEnter={toggleCollapsed}
                onMouseLeave={toggleCloseCollapsed}
              >
                <MenuBar />
              </Sider>
              <Layout style={{ overflowY: "scroll", height: "85vh" }}>
                <Routes>
                  <Route
                    path="/requisition/positionSummary/:id/applicantSummary/:applicantid"
                    element={<ApplicantTracker />}
                  />
                  <Route
                    path="/requisition/positionSummary/:id"
                    element={<PositionTracker />}
                  />
                  <Route path="/requisition" element={<Requisition />} />
                  <Route path="/applicants/resume" element={<ViewResume />} />
                  <Route path="/applicants" element={<AllApplicant />} />
                  <Route path="/profile" element={<Dashboard />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </Layout>
            </Layout>
          )}
        </Layout>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/resumeParsing" element={<ResumeProcess />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </>
  );
}

export default App;
