import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Card,
  Grid,
  message,
  Divider,
  Typography,
  Select,
  Modal,
  Space,
} from "antd";
import PhoneInput from "react-phone-number-input";
import {
  BankFilled,
  BankTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleprovider, microsoftprovider } from "../config/firebaseConfig";
import {
  saveFirebaseIdToken,
  signInApplicant,
  signInUser,
} from "../store/reducers/authReducer";
import {
  GoogleLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";
import Signup from "./Signup";

const { Option } = Select;
const { Text, Link } = Typography;

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const [checksignedIn, setChecksignedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDecisionModalOpen, setIsDecisionModalOpen] = useState(true);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [isapplicantModalOpen, setIsApplicantModalOpen] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  const showApplicantModal = () => {
    setIsApplicantModalOpen(true);
  };
  const onApplicantClose = () => {
    setIsApplicantModalOpen(false);
  };

  const handleSigninType = () => {
    setChecksignedIn(false);
  };
  const handleLoginType = () => {
    setChecksignedIn(true);
  };

  const onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (
      (!Number.isNaN(value) && reg.test(value)) ||
      value === "" ||
      value === "-"
    ) {
      this?.props?.onChange(value);
    }
  };

  const onFinish = (values) => {
    let errormsg = document.getElementById("Errormsg");
    if (checksignedIn === false) {
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((res) => {
          res.user
            .getIdToken()
            .then((idToken) => {
              dispatch(saveFirebaseIdToken(idToken));
              if (isRecruiter == true) {
                showModal();
              } else {
                showApplicantModal();
              }
            })
            .catch((err) => console.error("failed to get IdToken"));
        })
        .catch((err) => {
          errormsg.innerText = "Email Already In Use";
        });
    } else {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((res) => {
          res.user
            .getIdToken()
            .then((idToken) => {
              dispatch(saveFirebaseIdToken(idToken));
              if (isRecruiter == true) {
                dispatch(signInUser(idToken)).then((response) => {
                  navigate({ pathname: "/home" });
                });
              } else {
                dispatch(signInApplicant(idToken)).then((response) => {
                  navigate({ pathname: "/applicant/home" });
                });
              }
            })
            .catch((err) => console.error("failed to get IdToken"));
        })
        .catch((err) => {
          errormsg.innerText = "Incorrect username or password";
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onSubmit = (event) => {
    event.preventDefault();
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        auth.currentUser
          .getIdToken(true)
          .then((val) => {
            // console.log(val);
          })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {});
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      }.bind(this),
    },
    signInFlow: "popup",
    signInSuccessUrl: "any url to redirect after success",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      GoogleAuthProvider.PROVIDER_ID,
      "microsoft.com",
    ],
    tosUrl: "<your-tos-url>",
    privacyPolicyUrl: "<your-privacy-policy-url>",
  };

  const GoogleLogin = (e) => {
    e.preventDefault();
    signInWithPopup(auth, googleprovider)
      .then((result) => {
        result.user.getIdToken().then((idToken) => {
          dispatch(saveFirebaseIdToken(idToken));
          if (isRecruiter == true) {
            dispatch(signInUser(idToken))
              .then((response) => {
                if (response.payload.data.success === true) {
                  navigate({ pathname: "/home" });
                  message.success("login success");
                } else {
                  showModal();
                  message.success("sign up success");
                }
              })
              .catch((error) => {
                console.log(error, "ERROR");
              });
          } else {
            dispatch(signInApplicant(idToken))
              .then((response) => {
                if (response.payload.data.success === true) {
                  navigate({ pathname: "/applicant/home" });
                  message.success("login success");
                } else {
                  showApplicantModal();
                  message.success("sign up success");
                }
              })
              .catch((error) => {
                console.log(error, "ERROR");
              });
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        {/* <Option value="86">+86</Option> */}
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const formItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
  };

  const MicrosoftLogin = (e) => {
    e.preventDefault();
    signInWithPopup(auth, microsoftprovider)
      .then((result) => {
        result.user.getIdToken().then((idToken) => {
          dispatch(saveFirebaseIdToken(idToken)); // TODO: why are we saving this? not needed
          if (isRecruiter == true) {
            dispatch(signInUser(idToken)).then((response) => {
              if (response.payload.data.success === true) {
                navigate({ pathname: "/home" });
                message.success("login success");
              } else {
                showModal();
                message.success("sign up success");
              }
            });
          } else {
            dispatch(signInApplicant(idToken))
              .then((response) => {
                if (response.payload.data.success === true) {
                  navigate({ pathname: "/applicant/home" });
                  message.success("login success");
                } else {
                  showApplicantModal();
                  message.success("sign up success");
                }
              })
              .catch((error) => {
                console.log(error, "ERROR");
              });
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Row xs={24} sm={12} lg={12}>
      <Card
        size={"default"}
        color={"green"}
        // title={checksignedIn ? "Create New Account" : "Login"}
        style={{
          // minWidth: window.innerWidth > 720 ? "50vw" : "100vw",
          width: "100vw",
          height: "100%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <Signup
          onClose={onClose}
          isModalOpen={isModalOpen}
          isapplicantModalOpen={isapplicantModalOpen}
          onApplicantClose={onApplicantClose}
        />

        {isDecisionModalOpen ? (
          <Card
            style={{ textAlign: "center", border: "none" }}
            title={<h3 > 👋 Welcome! </h3>}
            open={isDecisionModalOpen}
          >
            <Row>
              <Button
                style={{  }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  setIsRecruiter(false);
                  setIsDecisionModalOpen(false);
                }}
                block
                height="55"
              >
                <UserOutlined />
                Applicant Login

              </Button>
            </Row>
            <br/>
            <Row>
              <Button
                style={{  }}
                type="default"
                htmlType="submit"
                onClick={() => {
                  setIsRecruiter(true);
                  setIsDecisionModalOpen(false);
                }}
                block
              >
                <BankFilled />
                Organization Login

              </Button>
            </Row>
          </Card>
        ) : (
          <>
            <Row lg={24}>
              <Col span={24}>
                <GoogleLoginButton
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "32px",
                    fontSize: "14px",
                  }}
                  iconSize="14px"
                  block
                  text="Continue with Google"
                  onClick={(e) => GoogleLogin(e)}
                />
                <MicrosoftLoginButton
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "32px",
                    fontSize: "14px",
                    marginTop: 10,
                  }}
                  iconSize="14px"
                  className="login-form-button"
                  block
                  text="Continue with Microsoft"
                  onClick={(e) => MicrosoftLogin(e)}
                />
              </Col>
            </Row>
            <Divider style={{ fontSize: "12px" }}>OR SIGN UP VIA EMAIL</Divider>
            <Row lg={24}>
              <Col span={24}>
                <Form
                  {...formItemLayout}
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true, prefix: "91" }}
                  onSubmit={onSubmit}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  {checksignedIn === false ? (
                    <>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Enter valid Email!" },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: "Enter valid Password!" },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: "Enter Confirm Password!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Confirm Password"
                        />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        // label="Phone Number"
                        onChange={onChange}
                        rules={[
                          {
                            required: true,
                            // type: "regexp",
                            pattern: new RegExp(
                              /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
                            ),
                            message: "Enter valid phone number!",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={prefixSelector}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          justifyContent: "center",
                          display: "flex",
                          marginBottom: "5%",
                        }}
                      >
                        By clicking Sign Up or registering through a third party
                        you accept the Dronepad Terms of services and
                        acknowledge the Privacy policy and Cookie Policy.
                      </Text>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                      >
                        Sign Up
                      </Button>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Enter valid Email!" },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: "Enter valid Password!" },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          block
                        >
                          Log in
                        </Button>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    {/* <a className="login-form-forgot" href="">
                Forgot password
              </a> */}
                  </Form.Item>
                  <p id="Errormsg" style={{ color: "red" }}></p>
                </Form>
              </Col>
            </Row>
            <Row style={{ padding: 4, marginTop: 4 }}>
              {checksignedIn === false ? (
                <Row style={{ padding: 4, marginTop: 4 }}>
                  <p>
                    Already have an Account?{" "}
                    <a onClick={handleLoginType}> Sign In</a>
                  </p>
                </Row>
              ) : (
                <Row style={{ padding: 4, marginTop: 4 }}>
                  <p>
                    New here? <a onClick={handleSigninType}> Create Account </a>
                  </p>
                </Row>
              )}
            </Row>
          </>
        )}
      </Card>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    firebasetoken: state?.authReducer?.firebase_token,
  };
};

export default connect(mapStateToProps)(Login);
