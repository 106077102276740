import React from "react";
import { Steps, Typography, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Step } = Steps;

function ApplicantTimeline() {
  return (
    <>
    <Card style={{ width: "100%", height: "30vh", marginBlockStart:"0.1%" }}>
      <Title level={3} style={{ padding: "0px" , fontSize:'22px', fontWeight:'normal'}}>
        Timeline (Upcoming Feature)
        </Title>

      <Steps direction="vertical" current={1} labelPlacement="vertical">
        <Step
          title="Applicant Activity"
          labelPlacement="vertical"
          icon={<UserOutlined />}
          description="Activity Details"
        />
      </Steps>
      </Card>
    </>
  );
}

export default ApplicantTimeline;
