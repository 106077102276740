import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Drawer,
  Input,
  Form,
  Row,
  Space,
  Select,
  Card,
  Typography,
  Table,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  addClientCompany,
  fetchClientCompany,
} from "../store/reducers/otherReducer";
import { useParams } from "react-router-dom";

const { Text, Title } = Typography;

// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };

function AddClient(props) {
  const dispatch = useDispatch();
  const [llocation, setLlocation] = useState("");
  const authData = useSelector((state) => state?.auth);
  const clientData = useSelector((state) => state?.otherReducer?.clientCompany);
  let url_params = useParams();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(addClientCompany(authData?.token));
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    // if (place) {
    //   console.log(place.formatted_address);
    //   console.log(place.geometry.location.lat());
    //   console.log(place.geometry.location.lng());
    // }
  };

  const onFinish = (values) => {
    const client_data = {
      accessToken: authData?.token,
      client_values: {
        name: values?.name,
        location: values?.location,
      },
    };
    dispatch(addClientCompany(client_data))
      .then((response) => {
        dispatch(fetchClientCompany(authData?.token));
        if (response.payload.success === true) {
          message.success("Client Added successfully");
          props?.onClose();
        } else {
          message.error("Client already exist");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 100,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 100,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (id) => <p> {id} </p>,
      sorter: (a, b) => a?.address?.length - b?.address?.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      width: "85%",
      checked: true,
      ...getColumnSearchProps("name"),
      render: (name) => (
        <>
          <>
            <p>{name}</p>
          </>
        </>
      ),
    },
  ];

  return (
    <>
      <Drawer
        title="Add New Client"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Card
          style={{
            marginBottom: "1%",
            marginBottom: "3%",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-3px",
            }}
          >
            <Title level={4} style={{ fontSize: "22px", fontWeight: "normal" }}>
              Existing Clients
            </Title>
            {/* <Button type="primary" onClick={showDrawer}>
            Add Requisition
          </Button> */}
          </Row>
          <Table
            style={{ textAlign: "center", padding: "0px" }}
            columns={columns}
            pagination={false}
            scroll={{ x: 200, y: 200 }}
            size="medium"
            // rowSelection={{
            //   type: selectionType,
            //   // ...rowSelection,
            // }}
            dataSource={clientData}
          />
        </Card>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Client name",
                  },
                ]}
              >
                <Input placeholder="Enter Applicant name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="location" label="Location">
                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    type="text"
                    className="form-control  ant-input"
                    placeholder="Enter Location"
                    onChange={(event) => setLlocation(event.target.value)}
                  />
                </StandaloneSearchBox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space>
                <Button onClick={props?.onClose}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  // onClick={props?.onClose}
                >
                  Add Client
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default AddClient;
