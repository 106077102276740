import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; // fetchDriverAPIsHelper
import {
  fetchMemberAPIsHelper,
  addMemberAPIsHelper,
  updateMemberAPIsHelper,
  deleteMemberAPIsHelper,
} from "../../helpers/RecruiterMemberApi";

const initialState = {
  member: [],
  memberData: "",
};

// TODO :: is it one driver or all driver?? Rename appropriately/
export const fetchMember = createAsyncThunk(
  "recruiter/fetch-memberList",
  async (token, thunkAPI) => {
    try {
      const response = await fetchMemberAPIsHelper(token); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addMember = createAsyncThunk(
  "recruiter/add-member",
  async (member_data, thunkAPI) => {
    
    try {
      const response = await addMemberAPIsHelper(member_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateMember = createAsyncThunk(
  "recruiter/update-member",
  async (member_data, thunkAPI) => {
    try {
      const response = await updateMemberAPIsHelper(member_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// TODO: Rename function... how many are we deleting?
export const deleteMember = createAsyncThunk(
  "recruiter/delete-member",
  async (member_data, thunkAPI) => {
    try {
      const response = await deleteMemberAPIsHelper(member_data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const memberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    addMemberDetails: (state, action) => {
      state.memberData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TODO: Add cases for - deleted driver, updated driver,
    // TODO: everytime an action is made on a driver profile, we should update state.drivers array accordingly. Otherwise, stale data will be shown to users.
    builder
      .addCase(addMember.fulfilled, (state, action) => {
        // state.memberData = action.payload.data;
      })
      .addCase(addMember.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchMember.fulfilled, (state, action) => {
        state.member = action.payload.data.data;
        console.log(action, "sddscevfererfv"); 
      })
      .addCase(fetchMember.rejected, (state, action) => {
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        // state.memberData = action.payload.data;
      })
      .addCase(updateMember.rejected, (state, action) => {
        // state.jobs = null;
        // console.log("action", JSON.stringify(action));
      })
      .addCase(deleteMember.rejected, (state, action) => {
        // state.jobs = null;
        console.log("action", JSON.stringify(action));
      });
  },
});

// Action creators are generated for each case reducer function
export const { addMemberDetails } = memberSlice.actions;

export default memberSlice.reducer;
