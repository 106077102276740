import API from "../store/utils/APIUtils";

export const fetchApplicantAPIsHelper = async (token) => {
  const response = await API.get(`/recruiter/applicants/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchApplicantAPIsHelper
};

export const fetchApplicantDashboardAPIsHelper = async (token) => {
  const response = await API.get(`/applicant/dashboard/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchApplicantAPIsHelper portal
};

// THIS API IS NOT IN USE---------------------------------------------------------
export const addApplicantAPIsHelper = async (applicant_data) => {
  const response = await API.post(
    `/recruiter/applicants/`,
    applicant_data.values, // TODO:: what is 'values' ??
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // addDriverAPIsHelper
};
//----------------------------------------------------------------------------------

export const updateApplicantAPIsHelper = async (applicant_data) => {
  console.log(applicant_data, "applicant_data");
  if (applicant_data) {
    const response = await API.put(
      `/recruiter/applicants/?applicantId=${applicant_data.id}`,
      {
        name: applicant_data.name,
      },
      {
        headers: {
          Authorization: `token ${applicant_data.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // updateApplicantAPIsHelper
  }
};

export const deleteApplicantAPIsHelper = async (applicant_data) => {
  const response = await API.delete(
    `/recruiter/applicants/?applicantId=${applicant_data.id}`,
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response; // deleteApplicantAPIsHelper
};
