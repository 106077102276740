import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Divider, Typography } from "antd";
import AddSinglejob from "./AddSinglejob";
import { useSelector } from "react-redux";
const { Text, Title } = Typography;

function PositionsCount() {
  const auth = useSelector((state) => state?.auth);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AddSinglejob onClose={onClose} open={open} />
      <Card style={{ width: "100%", marginBlock: "0.5%" }}>
        <Row style={{ justifyContent: "space-between" }}>
          <Title
            level={4}
            style={{ padding: "0px", fontSize: "22px", fontWeight: "normal" }}
          >
            Total Batch 
            ({auth?.auth_dashboard ? auth?.auth_dashboard?.jobs?.jobsCount : 0})
          </Title>
          <Button type="primary" onClick={showDrawer}>
            Add Batch
          </Button>
        </Row>
        <Divider />
        <Row style={{ justifyContent: "space-around" }}>
          <Col
            style={{
              color: "#389e0d",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid fa-briefcase"></i>
              <b style={{ marginLeft: "5%" }}>
                {auth?.auth_dashboard
                  ? auth?.auth_dashboard?.jobs?.publishedJobCount
                  : 0}
              </b>
            </span>
            <Row>
              <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
                <b>Completed</b>
              </span>
            </Row>
          </Col>
          <Col
            style={{
              color: "#d48806",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid fa-briefcase"></i>
              <b style={{ marginLeft: "5%" }}>
                {auth?.auth_dashboard
                  ? auth?.auth_dashboard?.jobs?.unpublishedJobCount
                  : 0}
              </b>
            </span>
            <Row>
              <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
                <b>Current</b>
              </span>
            </Row>
          </Col>
          <Col
            style={{
              color: "#cf1322",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid fa-briefcase"></i>
              <b style={{ marginLeft: "5%" }}>
                {auth?.auth_dashboard
                  ? auth?.auth_dashboard?.jobs?.expiredJobsCount
                  : 0}
              </b>
            </span>
            <Row>
              <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
                <b>Upcoming</b>
              </span>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default PositionsCount;
