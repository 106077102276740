import { Card, Col, Divider, Row, Typography, Button, Space } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import UpdatePosition from "./UpdatePosition";
import ViewAnalytics from "./ViewAnalytics";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BulkUpload from "./BulkUpload";

const showTotal = (total) => `Total ${total} items`;

const { Text, Title } = Typography;

function PositionSummary(props) {
  let url_params = useParams();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const jobDashboard = useSelector(
    (state) => state?.jobApplicantReducer?.jobdashboard
  );

  const jobdetails = useSelector((state) => state?.jobReducer?.jobs?.jobs);
  // console.log(
  //   jobdetails.map((x) => x.id),
  //   "jobdetailsjobdetails"
  // );

  const ids = jobdetails?.map((x) => x?.id);
  // console.log(ids, "jjbjjhfbjwb");
  // for (let i = 0; i < jobdetails.length; i++) {
  // ids.push({

  // })}

  const [current, setCurrent] = useState(3);
  const onChange = (page) => {
    // console.log(page, "lksaakcwk");
    setCurrent(page);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <Link
          to={{
            pathname: `/requisition/positionSummary/${
              Number(url_params?.id) - 1
            }`,
          }}
        >
          <a>
            <LeftOutlined />
          </a>
        </Link>
      );
    }
    if (type === "next") {
      return (
        <Link
          to={{
            pathname: `/requisition/positionSummary/${
              Number(url_params?.id) + 1
            }`,
          }}
        >
          <a>
            <RightOutlined />
          </a>
        </Link>
      );
    }
    return originalElement;
  };

  return (
    <>
      <UpdatePosition
        onClose={onClose}
        open={open}
        updatepositionDetails={props.updatepositionDetails}
      />
      <Card
        style={{
          marginBlock: "0.1%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Col xs={8} sm={8} lg={11} xl={11}>
            <Title
              style={{ padding: "0px", fontSize: "22px", fontWeight: "normal" }}
              level={4}
            >
              Batch Details
            </Title>
          </Col>

          {/* <Col
            xs={14}
            sm={14}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              justifyContent: "center",
              float: "right",
            }}
          > */}
          {/* <Pagination
              size="small"
              total={50}
              // showTotal={showTotal}
              showQuickJumper
              current={current}
              onChange={onChange}
            /> */}
          {/* <Pagination
              defaultCurrent={url_params.id}
              // current={url_params.id}
              total={jobdetails.length*10}
              size="small"
              showSizeChanger={false}
              showLessItems
              showQuickJumper
              itemRender={itemRender}
              onChange={onChange}
            />
            {ids} */}
          {/* </Col> */}
          {/* <Col xs={2} sm={2} lg={1} xl={1}>
            <Button
              style={{ float: "right" }}
              type="primary"
              onClick={showDrawer}
            >
              Update Batch
            </Button>
          </Col> */}
        </Row>
        <Divider style={{ marginTop: "2px" }} />
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Text style={{ textAlign: "left" }}>
              Batch Name : <b>{props.title ? props.title : ""}</b>
            </Text>
            <Text>
              Batch ID : <b>{props.client_name ? props.client_name : ""}</b>
            </Text>
            <Text>
              Openings :{" "}
              <b>
                {jobDashboard?.totalappliedcount
                  ? jobDashboard?.totalappliedcount
                  : 0}{" "}
                / {props.openings ? props.openings : 0}
              </b>
            </Text>
            <Text>
              Instructor : <b>{props.instructor ? props.instructor : ""}</b>
            </Text>
          </Col>

          {/* <Col xs={14} sm={7} lg={5} xl={4} style={{float: "right"}}> 
            <ViewAnalytics /> 
            <BulkUpload />
           </Col> */}
          {/* </Row> */}
        </Row>
      </Card>
    </>
  );
}

export default PositionSummary;
