import API from "../store/utils/APIUtils";

export const fetchSkillAPIsHelper = async (token) => {
  const response = await API.get(`/skills/`, {
    headers: {
      //   Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchSkillsAPIsHelper
};

export const fetchChoicesAPIsHelper = async (data) => {
  const response = await API.get(`/choices/`, {
    headers: {
      //   Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchSkillsAPIsHelper
};

export const fetchCompanyProcessAPIsHelper = async (data) => {
  const response = await API.get(`recruiter/process/${data.jobId}`, {
    headers: {
      Authorization: "Token " + data.accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchCompanyProcessAPIsHelper
};

export const fetchCompanyClientsAPIsHelper = async (accessToken) => {
  const response = await API.get(`company/clients/`, {
    headers: {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchCompanyProcessAPIsHelper
};

export const addCompanyClientAPIsHelper = async (data) => {
  const response = await API.post(`/company/clients/`, data.client_values, {
    headers: {
      Authorization: "Token " + data.accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchClientAPIsHelper
};