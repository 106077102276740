import {
  Table,
  Input,
  Space,
  Card,
  Row,
  Form,
  Typography,
  message,
  Modal,
  Divider,
  Col,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { Layout, Button, Checkbox } from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ViewResume from "../ats_components/ViewResume";
import {
  addJobApplicant,
  fetchJobApplicant,
} from "../store/reducers/jobApplicantReducer";
import AddSingleApplicant from "../ats_components/AddSingleApplicant";
import AddRequisitionDrawer from "../ats_components/AddRequisitionDrawer";
import { fetchApplicant } from "../store/reducers/applicantReducer";

const { Text, Title } = Typography;
var addIDS = [];

const AllApplicant = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();
  const jobdetails = useSelector(
    (state) => state?.applicantReducer?.talentPool
  );
  const [searchText, setSearchText] = useState("");
  const [selectID, setSelectID] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const authData = useSelector((state) => state?.auth);
  const applicantData = useSelector(
    (state) => state?.applicantReducer?.talentPool
  );

  useEffect(() => {
    dispatch(fetchApplicant(authData?.token));
  }, []);

  let url_params = useParams();

  const [open, setOpen] = useState(false);
  const [reqOpen, setReqOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const requisitionDrawer = () => {
    setReqOpen(true);
  };
  const onCloseReq = () => {
    setReqOpen(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const addApplicant = () => {
    var applicantId = addIDS;
    const applicant_data = {
      accessToken: authData?.token,
      JobApplicant: applicantId,
      jobId: url_params?.id,
    };

    const data = { token: authData?.token, jobId: url_params?.id };
    dispatch(addJobApplicant(applicant_data))
      .then((response) => {
        if (response.payload.data.success === true) {
          message.success("Trainee Added successfully");
          dispatch(fetchJobApplicant(data));
        } else {
          message.error("Trainee already exist");
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const [modalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  // const handleOk = (e) => {
  //   // console.log(e);
  //   setOpen(false);
  // };
  // const handleCancel = (e) => {
  //   // console.log(e);
  //   setOpen(false);
  // };

  var handleUploadfile = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${authData.token}`);
    const data = new FormData();
    data.append("bulkApplicantFile", selectedFile);
    fetch(
      // "http://localhost:8000/api/recruiter/bulk-applicants/",
      "https://dronepad-api.alamanceinc.com/api/recruiter/bulk-applicants/",
      {
        method: "POST",
        headers: myHeaders,
        body: data,
      }
    )
      .then((response) => {
        // console.log(response,"jhhdbjbsdvbdjv");
        if (response.ok === true) {
          message.success("Trainee Added successfully");
          dispatch(fetchJobApplicant(data)).then((response) =>
            setModalOpen(false)
          );
        } else {
          if (selectedFile != null) {
            message.error("Please upload file in valid format");
          }
        }
        return response.text();
      })
      .catch((error) => console.log("error", error));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 100,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 100,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#3a86ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#3a86ff",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Actions",
      dataIndex: "id",
      width: "10%",
      render: (record) =>
        url_params.id == undefined ? (
          <div
            style={{
              width: "20px",
            }}
          >
            {" "}
            <Checkbox
              onClick={() => {
                if (addIDS.includes(record) === true) {
                  addIDS.pop(record);
                  setSelectID(addIDS);
                  if (addIDS.length == 0 || addIDS[0] === undefined) {
                    setSelectID([]);
                  }
                } else {
                  addIDS.push(record);
                  setSelectID(addIDS);
                }
              }}
            >
              {" "}
            </Checkbox>
          </div>
        ) : (
          <div
            style={{
              width: "20px",
            }}
          >
            {" "}
            <Button
              onClick={() => {
                if (addIDS === null) {
                  addIDS.push(record);
                } else {
                  addIDS.pop();
                  addIDS.push(record);
                }
                addApplicant();
              }}
            >
              {" "}
              Add{" "}
            </Button>
          </div>
        ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
      sorter: {
        compare: (a, b) => a?.total_experience - b?.total_experience,
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      width: "20%",
      // ...getColumnSearchProps("name"),
      render: (name) => (
        <>
          <>
            <b>{name}</b>
          </>
        </>
      ),
    },
    // {
    //   title: "Batch Id",
    //   dataIndex: "values.skills",
    //   key: "skills",
    //   width: "15%",
    //   checked: true,
    //   // ...getColumnSearchProps("skills"),
    //   render: (_, { skills }) => (
    //     <>
    //       {skills?.map((skill) => {
    //         return <p>{skill?.name}</p>;
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Stage",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => a?.total_experience - b?.total_experience,
      },
      sortDirections: ["descend", "ascend"],
      width: "10%",
    },
    {
      title: "Contact",
      dataIndex: ["phoneNumber", "email"],
      key: "email",
      width: "30%",
      // ...getColumnSearchProps(["phone", "email"]),
      render: (text, row) => (
        <view style={{ display: "flex", flexDirection: "column" }}>
          <Space>Email : {row["email"]}</Space>
          <Space>Phone : {row["phoneNumber"]}</Space>
        </view>
      ),
    },
    {
      title: "Documents",
      dataIndex: "resume",
      key: "resume",
      width: "20%",
      render: (resume_url) => (
        <Space wrap>
          {/* <Link to={"/applicants/resume"} state={{ resume_url: resume_url }} > */}
          <ViewResume resume_url={resume_url} />
          {/* </Link> */}
        </Space>
      ),
    },
  ];

  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <AddSingleApplicant onClose={onClose} open={open} />
      <Card
        style={{
          marginBottom: "1%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Col xs={8} sm={8} lg={16}>
            <Title level={4} style={{ fontSize: "22px", fontWeight: "normal" }}>
              All Trainees
            </Title>
          </Col>
          <Col
            xs={16}
            sm={16}
            lg={8}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Space>
              {/* <Button type="primary" onClick={showModal}>
                <UploadOutlined />
                Upload CSV File
              </Button> */}
              <Modal
                title="Add Bulk Trainee"
                open={modalOpen}
                centered
                // onOk={() => setModalOpen(false)}
                // onCancel={() => setModalOpen(false)}
                // okButtonProps={{
                //   disabled: true,
                // }}
              >
                <p>
                  Download the sample excel file and upload it back with your
                  data for bulk processing
                </p>
                <p>
                  <Button type="primary-outlined">
                    <a href="/JobApplicant (Sample Data).csv" download>
                      Download Sample
                    </a>
                  </Button>
                </p>
                <Divider></Divider>
                <p>
                  <b>Upload your file</b>
                </p>
                <Form>
                  <Form.Item
                    name="bulkApplicantFile"
                    // label="Resume"
                    rules={[
                      {
                        required: true,
                        message: "Please add your csv file",
                      },
                    ]}
                  >
                    <input
                      type="file"
                      className="custom-file-input"
                      accept=".csv"
                      onChange={changeHandler}
                    />
                  </Form.Item>
                  <br />
                  <br />
                  <Row>
                    <Col xs={0} sm={12} lg={16}></Col>
                    <Col
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button onClick={() => setModalOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleUploadfile}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Space>

            <Button type="primary" onClick={showDrawer}>
              Add Trainee
            </Button>
          </Col>
        </Row>
        <Table
          style={{ textAlign: "center", padding: "0px" }}
          columns={columns}
          pagination={false}
          scroll={{ x: 200 }}
          size="medium"
          dataSource={jobdetails}
          rowKey="id"
        />
        <Row>
          <div
            style={{
              marginTop: "2%",
            }}
          >
            {url_params.id === undefined && selectID.length !== 0 && (
              <>
                <AddRequisitionDrawer onClose={onCloseReq} open={reqOpen} />
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={requisitionDrawer}
                  >
                    <strong>Add</strong>
                  </Button>
                </div>
              </>
            )}
          </div>
        </Row>
      </Card>
    </Layout>
  );
};
export default AllApplicant;
