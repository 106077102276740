import { Card, Row } from "antd";
import React from "react";
import ApplicantInterviewSchedule from "./ApplicantInterviewSchedule";

function AppliedCandidate(props) {
  const cardStyle = {
    width: "100%",
    padding: "1vw",
    paddingRight: "2vw",
    // minHeight: "200px",
    marginBottom: "10px",
  };
  return (
    <>
      <Card style={cardStyle}>
        <Row>
          <div style={{ width: "100%" }}>
            <ApplicantInterviewSchedule
              // isAuthenticated={props?.isAuthenticated}
              // login={() => props?.login()}
            />
          </div>
        </Row>
      </Card>
    </>
  );
}

export default AppliedCandidate;
