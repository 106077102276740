import { Table, Input, Space, Divider, Form } from "antd";
import { useRef, useState } from "react";
import {
  Layout,
  Button,
  Card,
  Row,
  Typography,
  Col,
  message,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import { title } from "process";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AddSinglejob from "../ats_components/AddSinglejob";
import { format } from "date-fns";
import { useEffect } from "react";
import { fetchJob } from "../store/reducers/jobReducer";

const { Text, Title } = Typography;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  getCheckboxProps: (record) => ({
    disabled: record.Requisition === "Disabled User",
    // Column configuration not to be checked
    name: record.Requisition,
  }),
};
const Requisition = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);

  useEffect(() => {
    dispatch(fetchJob(authData?.token));
  }, []);

  const jobdetails = useSelector((state) => state?.jobReducer?.jobs?.jobs);
  // console.log(jobdetails, "ohdjfbjebfjbjf");
  const applicantdata = useSelector(
    (state) => state?.jobApplicantReducer?.jobdashboard
  );
  // console.log(applicantdata, "applicantdataapplicantdata");
  const [selectedFile, setSelectedFile] = useState();
  const profileDetails = useSelector((state) => state?.auth?.auth_profile);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [selectionType, setSelectionType] = useState("checkbox");
  const date1 = format(new Date(), "yyyy-MM-dd");
  const date2 = Number(date1.split("-").join(""));

  const candidateArray = [];
  for (let i = 0; i < jobdetails.length; i++) {
    if (jobdetails == applicantdata)
      candidateArray.push(applicantData.applicant.length);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const applicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobdashboard
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
            paddingLeft: "24px",
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const [bulkOpen, setBulkOpen] = useState(false);
  const showModal = () => {
    setBulkOpen(true);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  var handleUploadfile = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${authData.token}`);
    const data = new FormData();
    data.append("bulkJobFile", selectedFile);
    // console.log(data, "jbshjbascbjhsdbcj");
    // console.log(selectedFile, "hsjfjsjbjfbjbf");
    fetch(
      // "http://localhost:8000/api/recruiter/bulk-jobs/",
      "https://dronepad-api.alamanceinc.com/api/recruiter/bulk-jobs/",
      {
        method: "POST",
        headers: myHeaders,
        body: data,
      }
    )
      .then((response) => {
        // console.log(response, "ijihfjesfjsvj");
        if (response.ok == true) {
          message.success("Batch Added Successfully");
          dispatch(fetchJob(authData?.token));
          dispatch(fetchRecruiterDashboard(authData?.token)).then((response) =>
            setBulkOpen(false)
          );
        } else {
          if (selectedFile != null) {
            message.error("Please upload file in valid format");
          }
        }
        return response.text();
      })
      .catch((error) => console.log("errorjhdjawdj", error));
  };

  // const results= jobdetails?.map(row => ({
  //   key: row?.id, // I added this line
  //   name: row?.name,
  //   round: {render: (round) => (
  //     <>
  //       {round?.name} ({round?.round})
  //     </>
  //   ),},
  //   scheduledDate: row?.scheduledDate,
  //   evaluator: row?.evaluator,
  //   resume: row?.resume
  // }))

  // console.log(results, "hgvchshcvvvbdbv");

  const columns = [
    {
      title: "Batches",
      dataIndex: ["id","title"],
      key: "title",
      render: (text, row) => (
        <p>
          <b>{row["title"]} - {row["id"]}</b>
        </p>
      ),
    },

    // =>
    // is_published === true ||
    // profileDetails.role === "admin" ||
    // profileDetails.role === "manager" ||
    // profileDetails.role === "Hiring-manager" ? ({
    //   if(is_published === false){
    //   return(
    //     <div>
    //     <Link to={{ pathname: `./positionSummary/${id}` }}>
    //       <a> Info </a>
    //     </Link>
    //   </div>)}
    // }) : (
    //   <div>
    //     {/* <Link to={{ pathname: `./positionSummary/${id}` }}> */}
    //     <a style={{ color: "white" }}> Info </a>
    //     {/* </Link> */}
    //   </div>
    // ),
    // },
    {
      title: "Instructor",
      dataIndex: ["hiringManager", "contributors"],
      key: "contributors",
      render: (_, { contributors, hiringManager }) => (
        <view style={{ display: "flex", flexDirection: "column" }}>
          {hiringManager.map((e) => {
            return <Space>RPA Trainer : {e?.name}</Space>;
          })}
          {contributors.map((e) => {
            return <Space>RPI Command : {e?.name}</Space>;
          })}
        </view>
      ),
    },
    {
      title: "Status",
      dataIndex: ["desired_end_date", "desired_start_date", date1],
      key: "status",
      render(_, { desired_start_date, desired_end_date }) {
        // const startDate = new Date(eventStartDate);
        if (date1 > desired_end_date) {
          return (
            <div>
              <b>
                <p>Completed</p>
              </b>
            </div>
          );
        } 
        else if (date1 >= desired_start_date && date1 <= desired_end_date)
        {
          return (
            <div>
              <b>
                <p style={{ color: "#389e0d" }}>Ongoing</p>
              </b>
            </div>
          );
        } 
        else {
          return (
            <div>
              <b>
                <p style={{ color: "#d48806" }}>Upcoming</p>
              </b>
            </div>
          );
        }
      },
      width: "10%",
    },
    {
      title: "Start Date",
      // dataIndex: ["updatedOn", "filledOn"],
      dataIndex: "desired_start_date",
      key: "desired_start_date",
      render: (desired_start_date) => (
        <b>
          <p>
            {" "}
            {desired_start_date !== null
              ? desired_start_date?.slice(0, 10)
              : desired_start_date?.slice(0, 10)}{" "}
          </p>
        </b>
      ),
      // sorter: {
      //   compare: (a, b) =>
      //     moment(a?.updatedOn || a?.filledOn, "YYYY-MM-DD") -
      //     moment(b?.updatedOn || b?.filledOn, "YYYY-MM-DD"),
      // },
    },
    {
      title: "Trainees",
      dataIndex: "jobApplicantsCount",
      key: "jobApplicantsCount",
      sorter: (a, b) => a?.jobApplicantsCount - b?.jobApplicantsCount,
      sortDirections: ["descend", "ascend"],
      render: (jobApplicantsCount) => (
        <b>
          <p> {jobApplicantsCount} </p>
        </b>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render(_, { id, is_published }) {
        if (is_published === false) {
          return profileDetails.role === "admin" ||
            profileDetails.role === "manager" ||
            profileDetails.role === "Hiring-manager" ? (
            <div>
              <Link to={{ pathname: `./positionSummary/${id}` }}>
                <Button style={{ color: "#d48806" }}> View Batch </Button>
              </Link>
            </div>
          ) : (
            <div>
              {/* <Link to={{ pathname: `./positionSummary/${id}` }}> */}
              <p style={{ color: "black" }}> - </p>
              {/* </Link> */}
            </div>
          );
        } else {
          return (
            <div>
              <Link to={{ pathname: `./positionSummary/${id}` }}>
                <Button> View Batch </Button>
              </Link>
            </div>
          );
        }
      },
    },
  ];

  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <AddSinglejob onClose={onClose} open={open} />
      <Card
        style={{
          marginBottom: "1%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Col xs={8} sm={8} lg={16}>
            <Title level={4} style={{ fontSize: "22px", fontWeight: "normal" }}>
              Your Batches
            </Title>
          </Col>
          <Col
            xs={16}
            sm={16}
            lg={8}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Space>
              {/* <Button type="primary" onClick={showModal}>
        <UploadOutlined />
        Upload CSV File
      </Button> */}
              <Modal
                title="Add Bulk Batches"
                open={bulkOpen}
                centered
                // onOk={() => setOpen(false)}
                // onCancel={() => setOpen(false)}
                // okButtonProps={{
                //   disabled: true,
                // }}
              >
                <p>
                  Download the sample excel file and upload it back with your
                  data for bulk processing
                </p>
                <p>
                  <Button type="outlined-primary">
                    <a href="/Requisition (Sample Data).csv" download>
                      Download Sample
                    </a>
                  </Button>
                </p>
                <Divider></Divider>
                <p>
                  <b>Upload your file</b>
                </p>
                <Form>
                  <Form.Item
                    name="resume"
                    // label="Resume"
                    rules={[
                      {
                        required: true,
                        message: "Please add your csv file",
                      },
                    ]}
                  >
                    <input
                      type="file"
                      className="custom-file-input"
                      accept=".csv"
                      onChange={changeHandler}
                    />
                  </Form.Item>
                  <br />
                  <br />
                  <Row>
                    <Col xs={0} sm={12} lg={16}></Col>
                    <Col
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button onClick={() => setBulkOpen(false)}>Cancel</Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleUploadfile}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Space>
            <Button type="primary" onClick={showDrawer}>
              Add Batch
            </Button>
          </Col>
        </Row>
        <Table
          style={{ textAlign: "center", padding: "0px" }}
          columns={columns}
          pagination={false}
          scroll={{ x: 200 }}
          size="medium"
          dataSource={jobdetails}
        />
      </Card>
    </Layout>
  );
};
export default Requisition;
