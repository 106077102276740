import React, { useEffect } from "react";
import { Button, Col, Drawer, Form, Row, Space, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplicant,
  addApplicant,
} from "../store/reducers/applicantReducer";
import {
  addJobApplicant,
  fetchJobApplicant,
} from "../store/reducers/jobApplicantReducer";
import { useParams } from "react-router-dom";
import Requisition from "../ats_pages/RequisitionPage";
// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };

function AddRequisitionDrawer(props) {
  // const dispatch = useDispatch();
  // const authData = useSelector((state) => state?.auth);
  // const applicantData = useSelector(
  //   (state) => state?.applicantReducer?.talentPool
  // );
  // let url_params = useParams();

  // useEffect(() => {
  //   dispatch(fetchApplicant(authData?.token));
  // }, []);

  // const onFinish = (values) => {
  //   const applicant_data = {
  //     accessToken: authData?.token,
  //     JobApplicant: values?.JobApplicant,
  //     jobId: url_params?.id,
  //   };
  //   const data = { token: authData?.token, jobId: url_params?.id };
  //   dispatch(addJobApplicant(applicant_data))
  //     .then((response) => {
  //       if (response.payload.data.success == true) {
  //         message.success("Job Applicant Added successfully");
  //         dispatch(fetchJobApplicant(data)).then((response) => props?.onClose());
  //       } else {
  //         message.error("Job Applicant already exist");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };

  // const onSubmit = (event) => {
  //   event.preventDefault();
  // };

  return (
    <>
      <Drawer
        title="Add Applicants to Your Requisition"
        width="75%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Requisition/>
        {/* <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col xs={24} sm={24} lg={12}  style={{margin: "3%"}}>
              <Form.Item name="JobApplicant" label="Applicant">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="select Job Applicant"
                  onChange={handleChange}
                  optionLabelProp="label"
                  options={
                    applicantData &&
                    applicantData?.map((e) => ({
                      label:[e?.id],
                      value: e?.id,
                    }))
                  }
                />
              </Form.Item>
              <Space>
                <Button onClick={props?.onClose}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form> */}


      </Drawer>
    </>
  );
}

export default AddRequisitionDrawer;
