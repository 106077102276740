import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRecruiterProfile,
  updateRecruiterProfile,
} from "../store/reducers/authReducer";

function EditRecruiterProfile(props) {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);

  const onFinish = (values) => {
    var raw = {
      name: values.name,
      phone: values.phone,
      linkedIn: values.linkedIn,
      // companyName: values.companyName,
      // companyLogo: "adad",
      // companyWebsite: values.companyWebsite,
      // companyLocation: values.companyLocation,
      // companyAddress: values.companyAddress,
    };
    const recruiter_data = { values: raw, accessToken: authData?.token };
    // console.log(recruiter_data, "Profile VALUES");
    dispatch(updateRecruiterProfile(recruiter_data))
      .then((response) => {
        dispatch(fetchRecruiterProfile(authData?.token)).then((response) =>
          props?.onClose()
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Drawer
        title="Edit Profile"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col sm={24} lg={12}>
              <Form.Item
                name="name"
                label="Name"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Name",
                //   },
                // ]}
              >
                <Input placeholder="Name" defaultValue={props?.profileDetails?.name} />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                name="phone"
                label="Phone"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter phone number",
                //   },
                // ]}
              >
                <Input
                  type="text"
                  pattern="\d{10}"
                  title="Error: 10 digits are required."
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode &gt;= 48 && event.charCode &lt;= 57"
                  placeholder="Enter Phone Number"
                  defaultValue={props?.profileDetails?.phone}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                name="linkedIn"
                label="Linkedin Url"
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  defaultValue={props?.profileDetails?.linkedin_url}
                  placeholder="LinkedIn Url"
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12} >
              <Form.Item
                name="companyName"
                label="Company Name"
              >
                <Input placeholder="Company name" defaultValue={props?.profileDetails?.company} disabled/>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col >
              <Form.Item
                name="companyWebsite"
                label="Company website"
                rules={[
                  {
                    required: true,
                    message: "Please add your resume",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  addonBefore="https://"
                  addonAfter=".com"
                  placeholder="Company website url"
                />
              </Form.Item>
            </Col>
            <Col >
              <Form.Item
                name="companyLocation"
                label="Company Location"
                rules={[
                  {
                    required: true,
                    message: "Please add your resume",
                  },
                ]}
              >
                <Input placeholder="Please enter company location" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col >
              <Form.Item
                name="companyAddress"
                label="Company Address"
                rules={[
                  {
                    required: true,
                    message: "Please add your resume",
                  },
                ]}
              >
                <Input placeholder="Company address" />
              </Form.Item>
            </Col> */}
          {/* <Col >
              <Form.Item
                name="resume"
                label="Resume"
                rules={[
                  {
                    required: true,
                    message: "Please add your resume",
                  },
                ]}
              >
                <Upload action="" listType="picture" maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col> */}
          {/* </Row> */}
          <Space>
            <Button onClick={props?.onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
}

export default EditRecruiterProfile;
