import React, { useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  Divider,
  Form,
  Card,
  Col,
  Row,
  Space,
  message,
  Progress,
} from "antd";
import NewInterview from "./NewInterview";
import FeedBackPage from "./FeedBackPage";
import { fetchCompanyProcess } from "../store/reducers/otherReducer";
import { MinusOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchInterview } from "../store/reducers/jobReducer";
import {
  fetchApplicantReview,
  UpdateApplicantReview,
} from "../store/reducers/reviewStatesReducer";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";
import { fetchJobApplicant } from "../store/reducers/jobApplicantReducer";
import CertificateGenerator from "../ats_pages/CertificateGenerator";
const { Title, Text } = Typography;

function ApplicantInterviewSchedule(props) {
  const dispatch = useDispatch();
  let url_params = useParams();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const authDashboard = useSelector((state) => state?.jobReducer?.jobs?.jobs);
  const Djangotoken = useSelector((state) => state?.auth?.token);
  const recruiterDetails = useSelector((state) => state?.auth?.auth_profile);
  const jobapplicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicants
  );
  const interviewDetails = useSelector(
    (state) => state?.jobReducer?.InterviewList
  );
  const applicantreviewStateData = useSelector(
    (state) => state?.reviewReducer?.applicantReview?.reviewstates
  );
  const filteredjobapplicantData = jobapplicantData?.find(
    (data) => data?.id == url_params?.applicantid
  );
  const filteredjobData = authDashboard?.find(
    (data) => data?.id == url_params?.id
  );
  const [selectedFile, setSelectedFile] = useState();
  const [open, setOpen] = useState(false);
  const [intopen, setIntOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const showIntDrawer = () => {
    setIntOpen(true);
  };
  const onIntClose = () => {
    setIntOpen(false);
  };
  const process_data = { jobId: url_params?.id, accessToken: Djangotoken };
  const data = { jobId: url_params?.id, token: Djangotoken };
  const interview_data = {
    jobApplicantId: url_params?.applicantid,
    accessToken: Djangotoken,
  };
  const applicantStatusChange = interview_data;

  const releaseOfferLetter = () => {
    const applicants_review_state = applicantreviewStateData.find(
      (data) => data?.name === "Offered"
    );
    applicantStatusChange.values = JSON.stringify({
      reviewStatusId: applicants_review_state?.id,
    });
    dispatch(UpdateApplicantReview(applicantStatusChange)).then((response) => {
      dispatch(fetchRecruiterDashboard(Djangotoken));
      dispatch(fetchJobApplicant(data));
      message.success("Offer Letter Sent Successfully");
      setModalOpen(false);
    });
  };

  const hireCandidate = () => {
    const applicants_review_state = applicantreviewStateData.find(
      (data) => data?.name === "Hired"
    );
    applicantStatusChange.values = JSON.stringify({
      reviewStatusId: applicants_review_state?.id,
    });
    dispatch(UpdateApplicantReview(applicantStatusChange)).then((response) => {
      dispatch(fetchRecruiterDashboard(Djangotoken));
      dispatch(fetchJobApplicant(data));
      message.success("Candidate Hired Successfully");
    });
  };

  function updateTraineeStatus() {
    dispatch(UpdateApplicantReview(applicantStatusChange)).then((res) => {
      dispatch(fetchJobApplicant(data));
      dispatch(fetchRecruiterDashboard(Djangotoken));
    });
  }

  useEffect(() => {
    dispatch(fetchCompanyProcess(process_data));
    dispatch(fetchInterview(interview_data));
    dispatch(fetchApplicantReview(Djangotoken));
    if (filteredjobapplicantData.status == "Regulations & Legal Compliances") {
      setGroundPercent(33);
    } else if (
      filteredjobapplicantData.status == "Safety & Emergency Procedure"
    ) {
      setGroundPercent(66);
    } else if (filteredjobapplicantData.status == "Simulator Training") {
      setGroundPercent(100);
    } else if (filteredjobapplicantData.status == "Basic Flight Training") {
      setOverallPercent(50);
      setGroundPercent(100);
      setFlightPercent(50);
    } else if (filteredjobapplicantData.status == "Advance Flight Training") {
      setOverallPercent(75);
      setGroundPercent(100);
      setFlightPercent(100);
    } else if (filteredjobapplicantData.status == "License Issued") {
      setOverallPercent(100);
      setGroundPercent(100);
      setFlightPercent(100);
      setPercent(100);
    }
  }, []);

  const [overallPercent, setOverallPercent] = useState(25);

  const [groundPercent, setGroundPercent] = useState(0);
  const [flightPercent, setFlightPercent] = useState(0);

  const [percent, setPercent] = useState(0);

  const flightIncrease = () => {
    setFlightPercent((prevPercent) => {
      if (flightPercent == 0) {
        const applicants_review_state = applicantreviewStateData.find(
          (data) => data?.name === "Basic Flight Training"
        );
        applicantStatusChange.values = JSON.stringify({
          reviewStatusId: applicants_review_state?.id,
        });
        updateTraineeStatus();
      } else {
        const applicants_review_state = applicantreviewStateData.find(
          (data) => data?.name === "Advance Flight Training"
        );
        applicantStatusChange.values = JSON.stringify({
          reviewStatusId: applicants_review_state?.id,
        });
        updateTraineeStatus();
      }
      const newPercent = prevPercent + 50;
      if (newPercent >= 100) {
        setOverallPercent((prevOverallPercent) => {
          const newOverallPercent = prevOverallPercent + 25;
          return newOverallPercent;
        });
        return 100;
      }
      return newPercent;
    });
  };

  const groundIncrease = () => {
    setGroundPercent((prevPercent) => {
      if (groundPercent == 0) {
        const applicants_review_state = applicantreviewStateData.find(
          (data) => data?.name === "Regulations & Legal Compliances"
        );
        applicantStatusChange.values = JSON.stringify({
          reviewStatusId: applicants_review_state?.id,
        });
        updateTraineeStatus();
      } else if (groundPercent == 33) {
        const applicants_review_state = applicantreviewStateData.find(
          (data) => data?.name === "Safety & Emergency Procedure"
        );
        applicantStatusChange.values = JSON.stringify({
          reviewStatusId: applicants_review_state?.id,
        });
        updateTraineeStatus();
      } else {
        const applicants_review_state = applicantreviewStateData.find(
          (data) => data?.name === "Simulator Training"
        );
        applicantStatusChange.values = JSON.stringify({
          reviewStatusId: applicants_review_state?.id,
        });
        updateTraineeStatus();
      }
      if (prevPercent == 66) {
        const newPercent = prevPercent + 34;
        if (newPercent >= 100) {
          setOverallPercent((prevOverallPercent) => {
            const newOverallPercent = prevOverallPercent + 25;
            return newOverallPercent;
          });
          return 100;
        }
        return newPercent;
      } else {
        const newPercent = prevPercent + 33;
        if (newPercent >= 100) {
          setOverallPercent((prevOverallPercent) => {
            const newOverallPercent = prevOverallPercent + 25;
            return newOverallPercent;
          });
          return 100;
        }
        return newPercent;
      }
    });
  };

  const percentIncrease = () => {
    const applicants_review_state = applicantreviewStateData.find(
      (data) => data?.name === "License Issued"
    );
    applicantStatusChange.values = JSON.stringify({
      reviewStatusId: applicants_review_state?.id,
    });
    updateTraineeStatus();
    setPercent((prevPercent) => {
      const newPercent = prevPercent + 100;
      if (newPercent >= 100) {
        setOverallPercent((prevOverallPercent) => {
          const newOverallPercent = prevOverallPercent + 25;
          return newOverallPercent;
        });
        return 100;
      }
      return newPercent;
    });
  };

  return (
    <>
      <NewInterview
        isAuthenticated={props?.isAuthenticated}
        // login={() => props?.login()}
        onClose={onClose}
        open={open}
        applicantName={filteredjobapplicantData?.name}
        positionName={filteredjobData?.title}
        jobapplicantId={url_params?.applicantid}
        applicantEmail={filteredjobapplicantData?.email}
        recruiterDetails={recruiterDetails}
      />

      <Title
        level={3}
        style={{ padding: "0px", fontSize: "22px", fontWeight: "normal" }}
      >
        Applicant Report
        <Row style={{display: "flex", float: "right" }}>
          {filteredjobapplicantData?.status == "License Issued" ? (
            <CertificateGenerator name={filteredjobapplicantData?.name} />
          ) : (
            <></>
          )}
        </Row>
      </Title>
      {/* <Button >Feedback</Button> */}
      {/* <FeedBackPage /> */}
      <Space
        direction="vertical"
        size="large"
        style={{
          display: "flex",
        }}
      >
        <Card
          title="Ground Training"
          extra={
            groundPercent == 100 ? (
              <Button disabled onClick={groundIncrease}>
                Stage Completed
              </Button>
            ) : (
              <>
                {groundPercent < 33 ? (
                  <> Regulations & Legal Compliances </>
                ) : (
                  <>
                    {groundPercent < 66 ? (
                      <> Safety & Emergency Procedure </>
                    ) : (
                      <> Simulator Training </>
                    )}
                  </>
                )}
                <Button onClick={groundIncrease}>Promote To Next Stage</Button>
              </>
            )
          }
          size="large"
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={12}>
              <b>Regulations & Legal Compliances</b>{" "}
              {filteredjobapplicantData.status}
              <Progress
                percent={
                  groundPercent == 33 ||
                  groundPercent == 66 ||
                  groundPercent == 100
                    ? 100
                    : 0
                }
              />
              <br />
              <br />
              <b>Safety & Emergency Procedure</b>
              <Progress
                percent={groundPercent == 66 || groundPercent == 100 ? 100 : 0}
              />
              <br />
              <br />
              <b>Simulator Training</b>
              <Progress percent={groundPercent == 100 ? 100 : 0} />
            </Col>
            <Col>
              <Progress
                type="circle"
                percent={groundPercent}
                style={{
                  marginRight: 16,
                }}
              />
            </Col>
          </Row>
        </Card>

        <Card
          title="Flight Training"
          extra={
            (flightPercent != 100) & (groundPercent == 100) ? (
              <>
                {flightPercent < 50 ? (
                  <> Basic Flight Training </>
                ) : (
                  <> Advance Flight Training </>
                )}
                <Button onClick={flightIncrease}>Promote To Next Stage</Button>
              </>
            ) : (
              <Button disabled onClick={flightIncrease}>
                Stage Completed
              </Button>
            )
          }
          size="large"
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={12}>
              <b>Basic Flight Training</b>
              <Progress
                percent={flightPercent == 50 || flightPercent == 100 ? 100 : 0}
              />
              <br />
              <br />
              <b>Advance Flight Training</b>
              <Progress percent={flightPercent == 100 ? 100 : 0} />
            </Col>
            <Col>
              <Progress
                type="circle"
                percent={flightPercent}
                style={{
                  marginRight: 16,
                }}
              />
            </Col>
          </Row>
        </Card>

        <Card
          title="Overall Performance"
          extra={
            overallPercent == 75 ? (
              <Button onClick={percentIncrease}>Issue Certificate</Button>
            ) : (
              <Button disabled onClick={percentIncrease}>
                Issue Certificate
              </Button>
            )
          }
          size="large"
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={12}>
              <b>Enrollment & Verification</b>
              <Progress percent={100} />
              <br />
              <br />
              <b>Ground School</b>
              <Progress percent={groundPercent} />
              <br />
              <br />
              <b>Flight Training</b>
              <Progress percent={flightPercent} />
              <br />
              <br />
              <b>License Issue</b>
              <Progress percent={percent} />
            </Col>
            <Col>
              <Progress
                type="circle"
                percent={overallPercent}
                style={{
                  marginRight: 16,
                }}
              />
            </Col>
          </Row>
        </Card>
      </Space>

      {/* {interviewDetails
        ? interviewDetails?.map((interview) => {
            const dd = interview?.interviewStart?.split(" ")[0].split("-");
            const intStartDate = `${dd[2]}-${months[dd[1] - 1]}-${dd[0]}`;
            return (
              <Card
                style={{ width: "100%", height: "30vh", marginBlock: "0.25%" }}
              >
                <FeedBackPage
                  onClose={onIntClose}
                  open={intopen}
                  interviewId={interview?.id}
                  interview_data={interview_data}
                  interviewFeedback={interview}
                />
                <Row>
                  <Col xs={12} sm={12} lg={12}>
                    <Title level={5}>
                      <b>
                        {interview?.process?.name} ({interview?.process?.round})
                        Interview
                      </b>
                    </Title>
                    <Col xs={12} sm={12} lg={12}>
                      <Text>
                        <b>{intStartDate ? intStartDate : ""}</b>
                      </Text>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Text>
                        Interview For : <b>{interview?.jobTitle}</b>
                      </Text>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Text>
                        Interview By : <b>{interview?.evaluator}</b>
                      </Text>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                      <Text>
                        Scheduled By : <b>{interview?.createdBy}</b>
                      </Text>
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    {interview?.status === "On-Going Interview" ? (
                      <Button
                        type="primary"
                        style={{
                          color: "white",
                          float: "right",
                        }}
                        onClick={showIntDrawer}
                      >
                        Submit Feedback
                      </Button>
                    ) : (
                      <Card style={{ width: "70%" }}>
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col xs={24} sm={24} lg={12}>
                            <Title level={5}>
                              <b>Feedback</b>
                            </Title>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                borderRadius: "10px",
                              }}
                              onClick={showIntDrawer}
                            >
                              Update Feedback
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Text>
                            <div>Status</div>
                            <b>{interview?.status} </b>
                          </Text>
                        </Row>
                        <Col>
                          <Text>
                            <div>Reason</div>
                            <b>{interview?.feedback}</b>
                          </Text>
                        </Col>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card>
            );
          })
        : "No Interviews Scheduled"} */}
    </>
  );
}

export default ApplicantInterviewSchedule;
