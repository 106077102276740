import React from "react";
import { Card, Col, Divider, Row, Typography, Statistic } from "antd";
import ViewResume from "./ViewResume";
const { Text, Title } = Typography;

function ApplicantSummary(props) {
  return (
    <>
      <Card
        style={{
          width: "100%",
          marginBlock: "0.5%",
          paddingLeft: 14,
          paddingRight: 18,
        }}
      >
        <Title
          level={3}
          style={{
            paddingLeft: "0px",
            fontSize: "22px",
            fontWeight: "normal",
          }}
        >
          Trainee Summary
        </Title>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col style={{ marginRight: "2%" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-person-fill"
              viewBox="0 0 16 16"
              style={{
                background: "#dee2e6",
                borderRadius: "50%",
                padding: "10%",
              }}
            >
              {" "}
              <g clipPath="url(#clip0_54_3319)">
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  fill="white"
                />
              </g>{" "}
              <defs>
                <clipPath id="clip0_54_3319">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Col>
          <Col
            style={{
              padding: "1vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <b>{props?.jobapplicantData?.name}</b>
            </Text>
            <Text>
              <span style={{ fontWeight: 500, paddingRight: 8 }}> Email:</span>{" "}
              {props?.jobapplicantData?.email}
            </Text>
            <Text>
              <span style={{ fontWeight: 500 }}> Status:</span>{" "}
              {props?.jobapplicantData?.status}
            </Text>
          </Col>

          <Col offset={14} style={{ display: "flex", float: "right" }}>
            <ViewResume resume_url={props?.jobapplicantData?.resume} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ApplicantSummary;
