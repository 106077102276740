import React, { useEffect } from "react";
import { Button, Card, Row, Typography } from "antd";
import PositionActivity from "./PositionActivity";
import AddJobApplicant from "./AddJobApplicant";
import { useState } from "react";
import { fetchApplicant } from "../store/reducers/applicantReducer";
import { useDispatch, useSelector } from "react-redux";
const { Title, Text } = Typography;

function OngoingActivity(props) {
  const [open, setOpen] = useState(false);
  const jobDashboard = useSelector(
    (state) => state?.jobApplicantReducer?.jobdashboard
  );
  const applicantData = useSelector(
    (state) => state?.applicantReducer?.talentPool
  );
  // console.log(applicantData, "APPLICANT DATA");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AddJobApplicant onClose={onClose} open={open} />
      <Card
        style={{
          // height: '38vh',
          // padding: "1vw",
          // paddingRight: "4vw",
          // marginBottom: "1%",
          marginBlock: "0.5%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Title
            style={{
              fontSize: "22px",
              fontWeight: "normal",
            }}
            level={4}
          >
            Ongoing Activity
          </Title>
          <Button
            type="primary"
            // style={{ borderRadius: "5px" }}
            onClick={showDrawer}
          >
            Batch Trainee
          </Button>
        </Row>
        <PositionActivity
          jobDashboard={jobDashboard}
          openings={props?.openings}
        />
      </Card>
    </>
  );
}

export default OngoingActivity;
