import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; // fetchDriverAPIsHelper
import {
  fetchApplicantAPIsHelper, fetchApplicantDashboardAPIsHelper,
  // addApplicantAPIsHelper,
  // updateApplicantAPIsHelper,
  // deleteApplicantAPIsHelper,
} from "../../helpers/applicantAPI";

// TODO:
/**
 * Talent Pool - Reducer.
 *
 * Gives list of candidates in the company or account.
 *
 *
 */
const initialState = {
  talentPool: [], // all candidates
  applicant: {}, // selected profile
  applicantData:{},
  jobApplicantData:[]

  // TODO: remove
  // addapplicantData: "", // why are storing API payload???
  // fetchapplicantData: "", // why are storing API payload???
  // deleteapplicantData: "", // why are storing API payload???
  // updateapplicantData: "", // why are storing API payload???
};

// TODO :: is it one driver or all driver?? Rename appropriately/
export const fetchApplicant = createAsyncThunk(
  "recruiter/fetch-applicants/",
  async (token, thunkAPI) => {
    try {
      const response = await fetchApplicantAPIsHelper(token); // userAPI.fetchById(userId)
      console.log(response, "responseresponse");
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchApplicantDashboard = createAsyncThunk(
  "applicant/fetch-applicants-dashboard/",
  async (token, thunkAPI) => {
    try {
      const response = await fetchApplicantDashboardAPIsHelper(token); // userAPI.fetchById(userId)
      console.log(response, "responseresponse");
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// export const addApplicant = createAsyncThunk(
//   "recruiter/add-applicants/",
//   async (applicant_data, thunkAPI) => {
//     try {
//       const response = await addApplicantAPIsHelper(applicant_data); // userAPI.fetchById(userId) deleteDrivers
//       return response;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.message);
//     }
//   }
// );

// export const updateApplicant = createAsyncThunk(
//   "recruiter/applicants/?applicantId=7",
//   async (driver_data, thunkAPI) => {
//     // console.log("1234" + JSON.stringify(driver_data.values))
//     try {
//       const response = await updateApplicantAPIsHelper(driver_data); // userAPI.fetchById(userId) deleteDrivers
//       return response;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.message);
//     }
//   }
// );

// // TODO: Rename function... how many are we deleting?
// export const deleteApplicant = createAsyncThunk(
//   "recruiter/applicants/?applicantId=7",
//   async (access_token, thunkAPI) => {
//     try {
//       const response = await deleteApplicantAPIsHelper(access_token);
//       return response;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.message);
//     }
//   }
// );

export const applicantSlice = createSlice({
  name: "applicant",
  initialState,
  reducers: {
    // fetch_Applicant: (state, action) => {
    //   state.fetchapplicantData = action.payload;
    // },
    fetch_Applicant: (state, action) => {
      state.talentPool = action.payload;
    },
    // add_Applicant: (state, action) => {
    //   state.addapplicantData = action.payload;
    // },
    // update_Applicant: (state, action) => {
    //   state.updateapplicantData = action.payload;
    // },
    // delete_Applicant: (state, action) => {
    //   state.deleteapplicantData = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // TODO: Add cases for - deleted driver, updated driver,
    // TODO: everytime an action is made on a driver profile, we should update state.drivers array accordingly. Otherwise, stale data will be shown to users.
    builder
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.talentPool = action.payload.data.applicant;
      })
      .addCase(fetchApplicantDashboard.fulfilled, (state, action) => {
        state.applicantData = action.payload.data.applicant;
        state.jobApplicantData = action.payload.data.jobApplicant
      })
      .addCase(fetchApplicantDashboard.rejected, (state, action) => {
        // state.talentPool = action.payload.data.applicant;
      });
    // .addCase(fetchApplicant.rejected, (state, action) => {
    //   // state.fetchapplicantData = null;
    //   console.log("action", JSON.stringify(action));
    // })
    // .addCase(addApplicant.fulfilled, (state, action) => {
    //   // console.log("action", JSON.stringify(state));
    //   state.addapplicantData = action.payload.data;
    // })
    // .addCase(addApplicant.rejected, (state, action) => {
    //   console.log("action", JSON.stringify(action));
    //   state.addapplicantData = "";
    // });
  },
});

// Action creators are generated for each case reducer function
export const {
  fetch_Applicant,
  // add_Applicant,
  // update_Applicant,
  // delete_Applicant,
} = applicantSlice.actions;

export default applicantSlice.reducer;
