import React, { useState, useRef } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Upload,
  Modal,
  TimePicker,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";
import { addJob, fetchJob } from "../store/reducers/jobReducer";
import { PlusOutlined } from "@ant-design/icons";
import {
  addClientCompany,
  fetchClientCompany,
} from "../store/reducers/otherReducer";

let index = 0;

function AddSinglejob(props) {
  const [llocation, setLlocation] = useState("");
  const profileDetails = useSelector((state) => state?.auth?.auth_profile);
  const [published, setPublished] = useState(false);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);
  const skillData = useSelector((state) => state?.otherReducer?.skillList);
  const memberDetails = useSelector((state) => state?.teamReducer?.member);
  const choiceOptions = useSelector((state) => state?.otherReducer?.Choices);
  const clientCompany = useSelector(
    (state) => state?.otherReducer?.clientCompany
  );
  const [value, setValue] = useState();
  const [quill, setQuill] = useState("");
  const [name, setName] = useState("");
  const inputrref = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const [intopen, setintOpen] = useState(false);
  const showintDrawer = () => {
    setintOpen(true);
  };

  // const handleOk = (e) => {
  //   // console.log(e);
  //   setOpen(false);
  // };
  // const handleCancel = (e) => {
  //   // console.log(e);
  //   setOpen(false);
  // };

  const addClientName = (e) => {
    e.preventDefault();
    const client_data = {
      accessToken: authData?.token,
      client_values: {
        name: name,
        // location: e?.location,
      },
    };
    dispatch(addClientCompany(client_data))
      .then((response) => {
        dispatch(fetchClientCompany(authData?.token));
        if (response.payload.success === true) {
          message.success("Client Added successfully");
          setName("");
          setTimeout(() => {
            inputrref.current?.focus();
          }, 0);
        } else {
          message.error("Client already exist");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChange = () => {
    setPublished(true);
  };

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    // if (place) {
    //   console.log(place.formatted_address);
    //   console.log(place.geometry.location.lat());
    //   console.log(place.geometry.location.lng());
    // }
  };

  const onFinish = (values) => {
    var raw = JSON.stringify({
      title: values.title,
      batchTiming: values.batchTiming,
      desiredStartDate: values.desiredStartDate,
      desiredEndDate: values.desiredEndDate,
      openings: values.openings,
      jobType: values.jobType,
      hiringManager: values.hiringManager,
      memberRecruiter: values.memberRecruiter,
      // otherRecruiters: values.otherRecruiters,
      description: values.description,
      isPublished: true,
    });

    const job_data = { values: raw, accessToken: authData?.token };
    dispatch(addJob(job_data))
      .then((response) => {
        if (response.payload.data.success == true) {
          message.success("Batch Added Successfully");
          dispatch(fetchJob(authData?.token));
          dispatch(fetchRecruiterDashboard(authData?.token)).then(
            (response) => showintDrawer(),
            props?.onClose()
          );
        } else {
          message.error("Batch already exists");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Drawer
        title="New Batch"
        placement="right"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="title"
                label="Batch Category"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Batch Category",
                  },
                ]}
              >
                <Select
                  // mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Batch Category"
                  optionLabelProp="label"
                  options={[
                    {
                      value: "Basic (VLOS)",
                      label: "Basic (VLOS)",
                    },
                    {
                      value: "Special (BVLOS)",
                      label: "Special (BVLOS)",
                    },
                    {
                      value: "Advance (BVLOS)",
                      label: "Advance (BVLOS)",
                    },
                  ]}
                />
                {/* <Input placeholder="Batch Category" /> */}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="batchTiming"
                label="Batch Timing"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Batch Timing",
                  },
                ]}
              >
                <TimePicker.RangePicker
                  use12Hours
                  format="h:mm a"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="desiredStartDate"
                label="Batch Start Date"
                rules={[
                  {
                    required: true,
                    message: "Enter valid start date",
                  },
                ]}
              >
                <Input
                  type="date"
                  placeholder="Batch Start Date"
                  default={"01/01/2023"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="desiredEndDate"
                label="Batch End Date"
                rules={[
                  {
                    required: true,
                    message: "Enter Batch End date",
                  },
                ]}
              >
                <Input
                  type="date"
                  placeholder="Batch End Date"
                  default={"01/02/2023"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="openings"
                label="Availale Slots"
                rules={[
                  {
                    required: true,
                    message: "Enter Availale Slots",
                  },
                ]}
              >
                <Input
                  type="number"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Availale Slots"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="jobType"
                label="Drone Type"
                rules={[
                  {
                    required: true,
                    message: "Enter Drone Type",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Drone Type"
                  optionLabelProp="label"
                  options={[
                    {
                      value: "Multirotor Drones",
                      label: "Multirotor Drones",
                    },
                    {
                      value: "Fixed-Wing Drones",
                      label: "Fixed-Wing Drones",
                    },
                    {
                      value: "Single-Rotor Helicopters",
                      label: "Single-Rotor Helicopters",
                    },
                    {
                      value: "Hybrid VTOL Drones",
                      label: "Hybrid VTOL Drones",
                    },
                    {
                      value: "Nano Drones",
                      label: "Nano Drones",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="hiringManager"
                label="RPA Trainer"
                rules={[
                  {
                    required: true,
                    message: "Enter valid RPA Trainer",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select RPA Trainer"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails
                      ?.filter(
                        (p) =>
                          p?.role === "lead-instructor" ||
                          p?.role === "manager" ||
                          p?.role === "admin"
                      )
                      ?.map((e) => ({
                        label: e?.name,
                        value: e?.id,
                      }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="memberRecruiter" label="Remote Pilot in Command">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select RPIC"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails
                      ?.filter(
                        (p) =>
                          p?.role === "technical-specialist" ||
                          p?.role === "asst-instructor" ||
                          p?.role === "admin"
                      )
                      ?.map((e) => ({
                        label: e?.name,
                        value: e?.id,
                      }))
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="otherRecruiters" label="Other Instructor">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Instructor"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails?.map((e) => ({
                      label: e?.name,
                      value: e?.id,
                    }))
                  }
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="description" label="Other Description">
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  placeholder="Other Description"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ flexDirection: "row-reverse" }}>
            <Col lg={5} xs={24} sm={24}>
              <Space>
                <Button onClick={props?.onClose} type="danger">
                  Cancel
                </Button>

                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default AddSinglejob;
