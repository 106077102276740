import { configureStore } from '@reduxjs/toolkit';
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';

import authReducer from './reducers/authReducer';
import jobReducer from './reducers/jobReducer';
import applicantReducer from './reducers/applicantReducer';
import otherReducer from './reducers/otherReducer';
import microsoftReducer from './reducers/microsoftReducer';
import jobApplicantReducer from './reducers/jobApplicantReducer';
// import interviewReducer from './reducers/interviewReducer';
import reviewStatesReducer from './reducers/reviewStatesReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AddTeamMemberReducer from './reducers/AddTeamMemberReducer';

const persistConfig = {
  key: 'persist-root',
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  jobReducer: jobReducer,
  applicantReducer: applicantReducer,
  otherReducer: otherReducer,
  microsoftReducer: microsoftReducer,
  jobApplicantReducer: jobApplicantReducer,
  // interviewReducer: interviewReducer,
  reviewReducer: reviewStatesReducer,
  teamReducer: AddTeamMemberReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

// thunk is already inside the toolkit
