import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Rate,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplicantReview,
  fetchProcessReview,
  UpdateApplicantReview,
  UpdateProcessReview,
} from "../store/reducers/reviewStatesReducer";
import { fetchInterview } from "../store/reducers/jobReducer";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";

const ref = React.createRef();

function FeedBackPage(props) {
  let url_params = useParams();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);
  const reviewStateData = useSelector(
    (state) => state?.reviewReducer?.processReview?.reviewstates
  );
  const applicantreviewStateData = useSelector(
    (state) => state?.reviewReducer?.applicantReview?.reviewstates
  );
  // console.log(applicantreviewStateData, "dsbvbdsjvbjdvjdffv");
  const interviewDetails = useSelector(
    (state) => state?.jobReducer?.InterviewList
  );
  // console.log(interviewDetails, "niuhsjdzkjdskjvsdjkvnkj");
  const [productValue, setProductValue] = useState("N/A");
  const [skillsValue, setSkillsValue] = useState("N/A");
  const [communicationValue, setCommunicationValue] = useState("N/A");
  const [leadershipValue, setLeadershipValue] = useState("N/A");
  const [productRate, setProductRate] = useState("N/A");
  const [skillsRate, setSkillsRate] = useState("N/A");
  const [communicationRate, setCommunicationRate] = useState("N/A");
  const [leadershipRate, setLeadershipRate] = useState("N/A");
  const [grossRate, setGrossRate] = useState("N/A");

  const onFinish = (values) => {
    var raw = JSON.stringify({
      productKnowledge: values?.productValue,
      productRating: values?.productRate,
      skills: values?.skillsValue,
      skillsRating: values?.skillsRate,
      communications: values?.communicationValue,
      communicationsRating: values?.communicationRate,
      Leadership: values.leadershipValue,
      LeadershipRating: values.leadershipRate,
      grossRating: values.grossRate,
      feedback: values?.reason,
      reviewStatusId: values?.feedback,
    });
    const feedback_data = {
      interviewId: props?.interviewId,
      values: raw,
      accessToken: authData?.token,
    };

    const process_review_state = reviewStateData.find(
      (data) => data.id === values?.feedback
    );
    const applicants_review_state = applicantreviewStateData.find(
      (data) => data?.name === process_review_state?.name
    );
    // console.log(process_review_state, "kncknkednknckn");

    var body_data = JSON.stringify({
      reviewStatusId: applicants_review_state?.id,
    });
    const applicantStatusChange = {
      accessToken: authData?.token,
      jobApplicantId: url_params?.applicantid,
      values: body_data,
    };

    dispatch(UpdateProcessReview(feedback_data))
      .then((response) => {
        dispatch(fetchInterview(props?.interview_data)).then((res) => {
          if (process_review_state.name === "Selected") {
            applicantStatusChange.values = JSON.stringify({
              reviewStatusId: applicantreviewStateData?.find(
                (data) => data?.name === "Interviewd" || "Rejected"
              )?.id,
            });
            dispatch(UpdateApplicantReview(applicantStatusChange)).then(
              (response) => props?.onClose()
            );
          } else {
            dispatch(UpdateApplicantReview(applicantStatusChange)).then(
              (response) => props?.onClose()
            );
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(fetchProcessReview(authData?.token));
    dispatch(fetchApplicantReview(authData?.token));
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showDrawer}>Feedback</Button>
      <Drawer
        title="Interview Feedback"
        placement="right"
        width="55%"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <div ref={ref}>
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onSubmit={onSubmit}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ marginLeft: "3%" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="productKnowledge" label="Product Knowledge">
                  <ReactQuill
                    theme="snow"
                    value={productValue}
                    onChange={setProductValue}
                    placeholder="Job Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name="productratings"
                  label="Rating"
                  value={productRate}
                  onChange={setProductRate}
                >
                  <Rate allowHalf defaultValue={2.5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="skills" label="Skills">
                  <ReactQuill
                    theme="snow"
                    value={skillsValue}
                    onChange={setSkillsValue}
                    placeholder="Job Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name="skillsratings"
                  label="Rating"
                  value={skillsRate}
                  onChange={setSkillsRate}
                >
                  <Rate allowHalf defaultValue={2.5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="communications" label="Communications">
                  <ReactQuill
                    theme="snow"
                    value={communicationValue}
                    onChange={setCommunicationValue}
                    placeholder="Job Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name="communicationsratings"
                  label="Rating"
                  value={communicationRate}
                  onChange={setCommunicationRate}
                >
                  <Rate allowHalf defaultValue={2.5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="leadership" label="Leadership">
                  <ReactQuill
                    theme="snow"
                    value={leadershipValue}
                    onChange={setLeadershipValue}
                    placeholder="Job Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name="productratings"
                  label="Rating"
                  value={leadershipRate}
                  onChange={setLeadershipRate}
                >
                  <Rate allowHalf defaultValue={2.5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name="overallRating"
                  label="Gross Rating"
                  value={grossRate}
                  onChange={setGrossRate}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Gross Rating",
                    },
                  ]}
                >
                  <Rate allowHalf defaultValue={2.5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name="feedback"
                  label="Feedback"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Interview feedback",
                    },
                  ]}
                >
                  <Select
                    placeholder="Add your feedback"
                    optionLabelProp="label"
                    options={
                      reviewStateData &&
                      reviewStateData?.map((e) => ({
                        label: e?.name,
                        value: e?.id,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="reason" label="Reason">
                  <Input
                    placeholder="Please enter Reason"
                    defaultValue={props?.interviewFeedback?.feedback}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ flexDirection: "row-reverse" }}>
              <Col>
                <Space>
                  <Button onClick={props?.onClose}>Cancel</Button>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
        <Pdf targetRef={ref} filename="Interviewdoc.pdf">
          {({ toPdf }) => (
            <button onClick={toPdf} style={{ marginLeft: "3%" }}>
              Download
            </button>
          )}
        </Pdf>
      </Drawer>
    </>
  );
}

export default FeedBackPage;
