import React, { useState, useRef } from "react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { fetchJob, updateJob } from "../store/reducers/jobReducer";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function UpdatePosition(props) {
  const [llocation, setLlocation] = useState("");
  const [published, setPublished] = useState(false);
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const choiceOptions = useSelector((state) => state?.otherReducer?.Choices);
  const authData = useSelector((state) => state?.auth);
  const skillData = useSelector((state) => state?.otherReducer?.skillList);
  const memberDetails = useSelector((state) => state?.teamReducer?.member);
  const clientCompany = useSelector(
    (state) => state?.otherReducer?.clientCompany
  );
  let url_params = useParams();

  const [value, setValue] = useState(1);
  // const [quill, setQuill] = useState("");
  // const onnChange = (e) => {
  //   console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setPublished(true);
    // console.log(published);
  };

  const onChangeactive = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setActive(true);
    // console.log(active);
  };

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    // if (place) {
    //   console.log(place.formatted_address);
    //   console.log(place.geometry.location.lat());
    //   console.log(place.geometry.location.lng());
    // }
  };

  const onFinish = (values) => {
    var raw = JSON.stringify({
      title: values.title,
      postingType: values.postingType,
      desiredStartDate: values.desiredStartDate,
      location: llocation,
      jobType: values.jobType,
      payRate: values.payRate,
      category: values.category,
      department: values.department,
      billRate: values.billRate,
      memberRecruiter: values.memberRecruiter,
      otherRecruiters: values.otherRecruiters,
      subheading: "Job",
      description: values.description,
      hiringManager: values.hiringManager,
      ctc: values.ctc,
      client_name: values.client_name,
      skills: values.skills,
      isPublished: published,
      openings: values.openings,
    });

    const job_data = {
      values: raw,
      accessToken: authData?.token,
      jobId: url_params?.id,
    };
    // console.log(job_data, "JOBS VALUES");
    dispatch(updateJob(job_data))
      .then((response) => {
        dispatch(fetchJob(authData?.token)).then((response) =>
          props?.onClose()
        );
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Drawer
        title="Update Batch"
        placement="right"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="postingType"
                label="Posting Type"
              >
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select PostingType"
                  optionLabelProp="label"
                  options={
                    choiceOptions &&
                    choiceOptions?.PostingType?.map((e) => ({
                      label: e[1],
                      value: e[0],
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="title"
                label="Title"
              >
                <Input placeholder="Job Title" defaultValue={props?.updatepositionDetails?.title} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="desiredStartDate"
                label="Desired Start Date"
              >
                <Input
                  type="date"
                  placeholder="Desired Start Date"
                  default={"01/01/2023"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="openings"
                label="Openings"
              >
                <Input
                  type="number"
                  style={{
                    width: "100%",
                  }}
                  placeholder="job vacancy"
                  defaultValue={props?.updatepositionDetails?.vacancy_count}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="skills"
                label="Skills"
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Skills"
                  optionLabelProp="label"
                  options={
                    skillData &&
                    skillData?.map((e) => ({
                      label:
                        e?.name?.slice(0, 1).toUpperCase() + e?.name?.slice(1),
                      value: e?.id,
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="location" label="Job Location">
                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    type="text"
                    className="form-control  ant-input"
                    placeholder="Enter Location"
                    onChange={(event) => setLlocation(event.target.value)}
                  />
                </StandaloneSearchBox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="payRate"
                label="Cost (Lacs per year)"
              >
                <Input type="number" placeholder="250000" defaultValue={props?.updatepositionDetails?.cost} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="billRate"
                label="Bill Rate"
              >
                <Input type="number" placeholder="Bill Rate" defaultValue={props?.updatepositionDetails?.bill_rate}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="client_name"
                label="Client Name"
              >
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Company"
                  optionLabelProp="label"
                  options={
                    clientCompany &&
                    clientCompany?.map((e) => ({
                      label:
                        e?.name?.slice(0, 1).toUpperCase() + e?.name?.slice(1),
                      value: e?.id,
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="jobType"
                label="Job Type"
              >
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select JobType"
                  optionLabelProp="label"
                  options={
                    choiceOptions &&
                    choiceOptions?.JobType?.map((e) => ({
                      label: e[1]?.slice(0, 1).toUpperCase() + e[1]?.slice(1),
                      value: e[0],
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="category"
                label="Category"
              >
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Category"
                  optionLabelProp="label"
                  options={
                    choiceOptions &&
                    choiceOptions?.Category?.map((e) => ({
                      label: e[1]?.slice(0, 1).toUpperCase() + e[1]?.slice(1),
                      value: e[0],
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="memberRecruiter"
                label="Recruiter"
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Recruiter"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails
                      ?.filter(
                        (p) =>
                          p?.role === "technical-specialist" ||
                          p?.role === "recruiter" ||
                          p?.role === "admin"
                      )
                      ?.map((e) => ({
                        label: e?.name,
                        value: e?.id,
                      }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="hiringManager"
                label="Hiring Manager"
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Hiring Manager"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails
                      ?.filter(
                        (p) =>
                          p?.role === "hiring-manager" ||
                          p?.role === "manager" ||
                          p?.role === "admin"
                      )
                      ?.map((e) => ({
                        label: e?.name,
                        value: e?.id,
                      }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="otherRecruiters" label="Other Recruiters">
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Recruiter"
                  optionLabelProp="label"
                  options={
                    memberDetails &&
                    memberDetails?.map((e) => ({
                      label: e?.name,
                      value: e?.id,
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="description"
                label="Job Description"
              >
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  placeholder="Job Description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} lg={12}>
              <Form.Item name="isPublished" label="Publish">
                <Checkbox name="checkb" onChange={onChange}>
                  Is Published
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="isactive" label="Active">
                <Checkbox name="checkactiveb" onChange={onChangeactive}>
                  Is Active
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ flexDirection: "row-reverse" }}>
            <Col>
              <Space>
                <Button type="danger" onClick={props?.onClose}>Cancel</Button>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
export default UpdatePosition;
