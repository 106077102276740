import API from "../store/utils/APIUtils";

export const fetchJobApplicantAPIsHelper = async (data) => {
  //     recruiter/job/dashboard
  const response = await API.get(`recruiter/job/dashboard/${data.jobId}/`, {
    headers: {
      Authorization: "Token " + data.token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchJobApplicantAPIsHelper
};

export const addJobApplicantAPIsHelper = async (applicant_data) => {
  const response = await API.post(
    `/recruiter/job-applicants/`,
    {
      applicantId: applicant_data.JobApplicant, // TODO:: what is 'values' ??
      jobId: applicant_data.jobId,
    },
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // postJobApplicantAPIsHelper
};

export const addBulkApllicantAPIsHelper = async (applicant_data) => {
  const response = await API.post(
    `/recruiter/bulk-applicants/`,
    {
      applicantId: applicant_data.JobApplicant, // TODO:: what is 'values' ??
      jobId: applicant_data.jobId,
    },
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // postJobApplicantAPIsHelper
};

export const updateJobApplicantAPIsHelper = async (applicant_data) => {
  if (applicant_data) {
    const response = await API.put(
      `/recruiter/jobapplicants/?applicantId=${applicant_data.id}`,
      {
        name: applicant_data.name,
      },
      {
        headers: {
          Authorization: `token ${applicant_data.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // updateJobApplicantAPIsHelper
  }
};

export const deleteSingleJobApplicantAPIsHelper = async (applicant_data) => {
  const response = await API.delete(
    `/recruiter/jobapplicants/?applicantId=${applicant_data.id}`,
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // deleteJobApplicantAPIsHelper
};

export const addJobApplicantNotesAPIsHelper = async (applicant_data) => {
  const response = await API.post(
    `/recruiter/jobapplicant/note/?jobApplicantId=${applicant_data.id}`,
    {
      note: applicant_data.note, // TODO:: what is 'values' ??
    },
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // postJobApplicantAPIsHelper
};

export const fetchJobApplicantNotesAPIsHelper = async (applicant_data) => {
  const response = await API.get(
    `/recruiter/jobapplicant/note/?jobApplicantId=${applicant_data.id}`,
    {
      headers: {
        Authorization: "Token " + applicant_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // postJobApplicantAPIsHelper
};