import API from "../store/utils/APIUtils";

export const addinterviewAPIsHelper = async (interview_data) => {
  const response = await API.post(
    `recruiter/schedule-interview/${interview_data.jobApplicantId}/`,
    {
      jobApplicantId: interview_data.jobApplicantId,
      processId: interview_data.processId,
      evaluatorId: interview_data.evaluatorId,
      interviewStart: interview_data.interviewStart,
      interviewEnd: interview_data.interviewEnd,
      interviewLink: interview_data.interviewLink,
      interviewType: interview_data.interviewType,
    },
    {
      headers: {
        Authorization: "Token " + interview_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // AddInterviewAPIsHelper
};

export const fetchinterviewAPIsHelper = async (interview_data) => {
  const response = await API.get(
    `recruiter/schedule-interview/${interview_data.jobApplicantId}/`,
    {
      headers: {
        Authorization: "Token " + interview_data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // fetchInterviewAPIsHelper
};

export const fetchupcominginterviewAPIsHelper = async (accessToken) => {
  const response = await API.get(`recruiter/upcoming/interview/`, {
    headers: {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchUpcomingInterviewAPIsHelper
};
