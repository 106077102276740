import { Card, Col, Divider, Row, Typography, Button } from "antd";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import EditRecruiterProfile from "./EditRecruiterProfile";
import {
  BankOutlined,
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { Text, Title } = Typography;

function DashboardSummary() {
  const profileDetails = useSelector((state) => state?.auth?.auth_profile);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <EditRecruiterProfile
        onClose={onClose}
        open={open}
        profileDetails={profileDetails}
      />
      <Card
        style={{
          marginBottom: "0.5%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Title style={{ fontSize: "22px", fontWeight: "normal" }} level={4}>
            Your Organization
          </Title>
          <Button
            style={{
              color: "white",
              backgroundColor: "#1890FF",
              border: "none",
            }}
            onClick={showDrawer}
          >
            Edit
            <i
              className="fa-solid fa-user-pen"
              style={{ marginLeft: "5px" }}
            ></i>
          </Button>
        </Row>
        <Divider style={{ marginTop: "2px" }} />
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col style={{ marginRight: "2%" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-person-fill"
              viewBox="0 0 16 16"
              style={{
                background: "#dee2e6",
                borderRadius: "50%",
                padding: "10%",
              }}
            >
              {" "}
              <g clipPath="url(#clip0_54_3319)">
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  fill="white"
                />
              </g>{" "}
              <defs>
                <clipPath id="clip0_54_3319">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Col>

          <Col>
            <Title level={4}>
              <b style={{ fontWeight: "semibold", fontSize: "22px" }}>
                {profileDetails?.company}
              </b>
            </Title>
            <Text style={{ fontWeight: "regular", fontSize: "15px" }}>
              {/* <br /> */}
              <UserOutlined /> : {profileDetails?.name}
              <br />
              <MailOutlined /> : {profileDetails?.email}
              <br />
              {profileDetails?.phone ? (
                <>
                  (<PhoneOutlined />: {profileDetails?.phone})
                </>
              ) : (
                ""
              )}
            </Text>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default DashboardSummary;
