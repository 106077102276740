import React, { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, Row, Space, message, Upload, List, Skeleton, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplicant } from "../store/reducers/applicantReducer";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";
import { UploadOutlined } from "@ant-design/icons";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
const pdfjs = require("pdfjs-dist");
const winkNLP = require("wink-nlp"); // Load wink-nlp package.
const model = require("wink-eng-lite-web-model"); // Load english language model.
const nlp = winkNLP(model); // Instantiate winkNLP.
const its = nlp.its; // Obtain "its" helper to extract item properties.
const as = nlp.as; // Obtain "as" reducer helper to reduce a collection.


function BulkUpload() {
  const [selectedFile, setSelectedFile] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [resumeAnalytics, setResumeAnalytics] = useState([]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files);
    setIsFilePicked(true);
  };

  async function getResumeDetails(src) {
    // console.log(src,'getResumeDetailsgetResumeDetails')
    const srcdoc = await pdfjs.getDocument(src).promise;
    // console.log('srcdocsrcdocsrcdocsrcdoc',srcdoc)
    const numPages = await srcdoc.numPages;
    // console.log(numPages,'numPagesnumPagesnumPages')
    var text = "";
    var text1 = "";
    for (let i = 1; i < numPages + 1; i++) {
      const page = await srcdoc.getPage(i);
      const page_data = await page.getTextContent();
      const items = page_data.items.map((item) => {
        text = text.concat(" ", item.str);
      });
    }
    // console.log(text)
    text1 = text.replace(/ +(?= )/g, "");
    const emailtext = text1.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        ? /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        : ""
    );
    const doc = nlp.readDoc(text1);

    // Finding Skill
    var skillwordFreq = doc
      .customEntities()
      .filter((customEntity) => {
        return customEntity.out(its.type) === "skill";
      })
      .out(its.normal, as.freqTable);

    var instanceCount = 0;
    var skillsDetail = skillwordFreq.map((skillword) => {
      instanceCount = instanceCount + skillword[1];
      return { skillname: skillword[0], count: skillword[1] };
    });

    // Finding Experience
    var expwordFreq = doc
      .customEntities()
      .filter((customEntity) => {
        return customEntity.out(its.type) === "Experience";
      })
      .out(its.normal, as.freqTable);
    var expDetail = expwordFreq.map((expword) => {
      return { expwordName: expword[0], count: expwordFreq[1] };
    });

    const candidateDetails = {
      id: src.id,
      name: src.name,
      email: src.email, // email: emailtext ? emailtext[0] : "",
      totalexperience: src.totalexperience,
      skillcount: skillwordFreq.length,
      skillsFound: skillsDetail,
      skillinstanceCount: instanceCount,
    };
    return candidateDetails;
  }

  async function getAllResumes(testData, patterns) {
    // console.log(testData,'testDatatestDatatestData')
    nlp.learnCustomEntities(patterns);
    var resumeDetails = [];
    // for (const src of testData) {
    //   console.log(src,'srcsrcsrcsrcsrc')
    //   const result = await getResumeDetails(src);
    //   resumeDetails.push(result);
    // }
    const result = await getResumeDetails(testData);
    resumeDetails.push(result);
    resumeDetails.sort((a, b) => {
      if (a.skillcount === b.skillcount) {
        return b.skillinstanceCount - a.skillinstanceCount;
      }
      return b.skillcount - a.skillcount;
    });
    // console.log(resumeDetails)
    return resumeDetails;
  }
  
  const submitfile = () => {
    const fileArray = new Uint8Array(selectedFile)
    getAllResumes(fileArray, patterns).then((result) => {
      const sorted = [];
      setResumeAnalytics(result);
    });
  }

  const patterns = [
    { name: "skill", patterns: ["CNN"] },
    { name: "skill", patterns: ["RNN"] },
    { name: "skill", patterns: ["DL"] },
    { name: "skill", patterns: ["react"] },
    { name: "skill", patterns: ["javascript"] },
    { name: "skill", patterns: ["django"] },
    { name: "skill", patterns: ["python"] },
    { name: "skill", patterns: ["Data Analysis"] },
    { name: "skill", patterns: ["machine Learning"] },
    { name: "skill", patterns: ["Data Science"] },
    { name: "skill", patterns: ["artificial Intelligence"] },
    { name: "skill", patterns: ["Problem Solving"] },
    { name: "skill", patterns: ["mysql"] },
    { name: "skill", patterns: ["postgres"] },
    { name: "skill", patterns: ["docker"] },
    { name: "skill", patterns: ["vb.net"] },
    { name: "skill", patterns: ["plsql"] },
    { name: "skill", patterns: ["redux"] },

    { name: "Experience", patterns: ["Machine Learning Engineer"] },
    { name: "Experience", patterns: ["Artificial Intelligence Engineer"] },
    { name: "Experience", patterns: ["ML Engineer"] },
    { name: "Experience", patterns: ["AI Engineer"] },
    { name: "Experience", patterns: ["Data scientist"] },
    { name: "Experience", patterns: ["Data Analyst"] },
    { name: "Experience", patterns: ["software engineer"] },
    { name: "Experience", patterns: ["software developer"] },
    { name: "Experience", patterns: ["junior software engineer"] },
    { name: "Experience", patterns: ["junior software developer"] },
    { name: "Experience", patterns: ["senior software engineer"] },
    { name: "Experience", patterns: ["senior software developer"] },
    { name: "Experience", patterns: ["associate software engineer"] },
    { name: "Experience", patterns: ["associate software developer"] },
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        style={{
          color: "white",
          backgroundColor: "#1890FF",
          border: "none",
          marginLeft: "10%",
        }}
      >
        Bulk Upload
      </Button>
      <Drawer
        title="Add Team Member"
        width="55%"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >

        <div>
          <input id="fileUpload" type="file" onChange={changeHandler}/>
          <label htmlFor="fileUpload">
            <a>Upload Files</a>
          </label>
          <button onClick={submitfile}>SUBMIT</button>
        </div>
      </Drawer>
    </>
  );
}
export default BulkUpload;