import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMember,
  fetchMember,
} from "../store/reducers/AddTeamMemberReducer";

function EditTeamMember(props) {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);
  const choiceOptions = useSelector((state) => state?.otherReducer?.Choices);
  const memberdetails = useSelector((state) => state?.teamReducer?.member);
  const memberData = memberdetails?.find((data) => data?.id == props?.id)
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    
    var raw = JSON.stringify({
      name: values.name,
      role: values.role,
      email: values.email,
      phoneNumber: values.phoneNumber,
      linkedInUrl: values.linkedInUrl,
    });

    const member_data = {
      values: raw,
      accessToken: authData?.token,
      Id: props.id,
    };
    dispatch(updateMember(member_data))
      .then((response) => {
        if (response.payload.data.success == true) {
          message.success(" Successfully updated team member");
          dispatch(fetchMember(authData?.token)).then((response) => onClose());
        } else {
          message.error("Member Cannot be Updated");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
    { memberData?.role == "admin"?
    (<Button
        type="secondary"
        onClick={showDrawer}
        disabled
        style={{
            color: "white",
            backgroundColor: "grey",
            border: "none",
        }}
      >Edit 
      </Button>):
      (<Button
        type="primary"
        onClick={showDrawer}
        style={{
            color: "white",
            backgroundColor: "#1890FF",
            border: "none",
        }}
      >Edit 
      </Button>)}
      <Drawer
        title="Edit Team Member"
        width="55%"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col sm={24} lg={12}>
              <Form.Item name="name" label="Name">
                <Input
                  placeholder="Name"
                  defaultValue={memberData?.name}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item name="phone" label="Phone">
                <Input
                  type="text"
                  pattern="\d{10}"
                  title="Error: 10 digits are required."
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode &gt;= 48 && event.charCode &lt;= 57"
                  placeholder="Enter Phone Number"
                  defaultValue={memberData?.phone}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item name="role" label="Role">
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Role"
                  optionLabelProp="label"
                  options={
                    choiceOptions &&
                    choiceOptions?.Roles?.map((e) => ({
                      label: e[1]?.slice(0, 1).toUpperCase() + e[1]?.slice(1),
                      value: e[0],
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item name="email" label="Email">
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please enter Email"
                  defaultValue={memberData?.email}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item name="linkedIn" label="Linkedin Url">
                <Input
                  style={{
                    width: "100%",
                  }}
                  defaultValue={memberData?.linkedin_url}
                  placeholder="LinkedIn Url"
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item name="companyName" label="Company Name">
                <Input
                  placeholder="Company name"
                  defaultValue={memberData?.company}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
}

export default EditTeamMember;
