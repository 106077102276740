import React from "react";
import { Steps, Avatar, Col, Row, Progress, Typography } from "antd";
import {useSelector } from "react-redux";
const { Title, Text } = Typography;
const { Step } = Steps;

function PositionActivity(props) {
  const applicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicants
  );

  const regulationsLegalCount = applicantData.filter(obj => obj.status === 'Regulations & Legal Compliances').length;
  const safetyEmergencyProcedureCount = applicantData.filter(obj => obj.status === 'Safety & Emergency Procedure').length;
  const simulatorTrainingCount = applicantData.filter(obj => obj.status === 'Simulator Training').length;
  const basicFlightCount = applicantData.filter(obj => obj.status === 'Basic Flight Training').length;
  const advanceFlightCount = applicantData.filter(obj => obj.status === 'Advance Flight Training').length;
  const licenseIssuedCount = applicantData.filter(obj => obj.status === 'License Issued').length;

  return (
    <>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={regulationsLegalCount? regulationsLegalCount:0
              // props?.jobDashboard?.rejectedCount
              //   ? (props?.jobDashboard?.rejectedCount / props?.openings) * 100
              //   : 0
            }
            format={() =>
              `${ regulationsLegalCount? regulationsLegalCount:0
                // props?.jobDashboard?.rejectedCount
                //   ? props?.jobDashboard?.rejectedCount
                //   : 0
              }`
            }
          />
          <Title level={5}>Regulations & Legal Compliances</Title>
        </Col>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={safetyEmergencyProcedureCount ? safetyEmergencyProcedureCount : 0
              // props?.jobDashboard?.rejectedCount
              //   ? (props?.jobDashboard?.rejectedCount / props?.openings) * 100
              //   : 0
            }
            format={() =>
              `${safetyEmergencyProcedureCount ? safetyEmergencyProcedureCount : 0
                // props?.jobDashboard?.rejectedCount
                //   ? props?.jobDashboard?.rejectedCount
                //   : 0
              }`
            }
          />
          <Title level={5}>Safety & Emergency Procedure</Title>
        </Col>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={simulatorTrainingCount ? simulatorTrainingCount : 0
              // props?.jobDashboard?.totalappliedcount
              //   ? (props?.jobDashboard?.totalappliedcount / props?.openings) *
              //     100
              //   : 0
            }
            format={() =>
              `${ simulatorTrainingCount ? simulatorTrainingCount : 0
                // props?.jobDashboard?.totalappliedcount
                //   ? props?.jobDashboard?.totalappliedcount
                //   : 0
              }`
            }
          />
          <Title level={5}>Simulator Training</Title>
        </Col>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={ basicFlightCount ? basicFlightCount : 0
              // props?.jobDashboard?.shortlistedCount
              //   ? (props?.jobDashboard?.shortlistedCount / props?.openings) *
              //     100
              //   : 0
            }
            format={() =>
              `${ basicFlightCount ? basicFlightCount : 0
                // props?.jobDashboard?.shortlistedCount
                //   ? props?.jobDashboard?.shortlistedCount
                //   : 0
              }`
            }
          />
          <Title level={5}>Basic Flight Training</Title>
        </Col>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={ advanceFlightCount ? advanceFlightCount : 0
              // props?.jobDashboard?.offeredCount
              //   ? (props?.jobDashboard?.offeredCount / props?.openings) * 100
              //   : 0
            }
            format={() =>
              `${ advanceFlightCount ? advanceFlightCount : 0
                // props?.jobDashboard?.offeredCount
                //   ? props?.jobDashboard?.offeredCount
                //   : 0
              }`
            }
          />
          <Title level={5}>Advance Flight Training</Title>
        </Col>
        <Col
          style={{
            maxWidth: 160,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Progress
            type="circle"
            percent={ licenseIssuedCount ? licenseIssuedCount : 0
              // props?.jobDashboard?.offeredCount
              //   ? (props?.jobDashboard?.offeredCount / props?.openings) * 100
              //   : 0
            }
            format={() =>
              `${ licenseIssuedCount ? licenseIssuedCount : 0
                // props?.jobDashboard?.offeredCount
                //   ? props?.jobDashboard?.offeredCount
                //   : 0
              }`
            }
          />
          <Title level={5}>License Issued</Title>
        </Col>
        {/* <Steps current={4} labelPlacement="vertical">
          <Step
            title="Regulations & Legal Compliances"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#228B22" }}
                size={32}
              >
                {props?.jobDashboard?.rejectedCount
                  ? props?.jobDashboard?.rejectedCount
                  : 0}
              </Avatar>
            }
          />
          <Step
            title="Safety & Emergency Procedure"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#028A0F" }}
                size={32}
              >
                {props?.jobDashboard?.rejectedCount
                  ? props?.jobDashboard?.rejectedCount
                  : 0}
              </Avatar>
            }
          /> */}
        {/* <Step
            title="Ground School"
            labelPlacement="vertical"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor:""  }}
                size={32}
              >
                {props?.jobDashboard?.totalappliedcount
                  ? props?.jobDashboard?.totalappliedcount
                  : 0}
              </Avatar>
            }
          /> */}
        {/* <Step
            title="Simulator Training"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#7ACACB" }}
                size={32}
              >
                {props?.jobDashboard?.shortlistedCount
                  ? props?.jobDashboard?.shortlistedCount
                  : 0}
              </Avatar>
            }
          />
          <Step
            title="Basic Flight Training"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#7ACACB" }}
                size={32}
              >
                {props?.jobDashboard?.offeredCount
                  ? props?.jobDashboard?.offeredCount
                  : 0}
              </Avatar>
            }
          />
          <Step
            title="Advanced Flight Training"
            icon={
              <Avatar
                style={{ color: "white", backgroundColor: "#5DBB63" }}
                size={32}
              >
                {props?.jobDashboard?.hiredCount
                  ? props?.jobDashboard?.hiredCount
                  : 0}
              </Avatar>
            }
          />
        </Steps> */}
      </Row>
    </>
  );
}

export default PositionActivity;
