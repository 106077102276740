import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; // fetchDriverAPIsHelper
import {
  fetchJobAPIsHelper,
  addJobAPIsHelper,
  addBulkJobAPIsHelper,
  updateJobAPIsHelper,
  deleteJobAPIsHelper,
} from "../../helpers/jobAPI";
import {
  addinterviewAPIsHelper,
  fetchinterviewAPIsHelper,
  fetchupcominginterviewAPIsHelper,
} from "../../helpers/interviewAPI";

const initialState = {
  jobs: [],
  activeJob: {},
  activeApplicantsForJob: [], // we should have JobApplicants here
  InterviewList: [],
  UpcomingInterview: [],

};

// TODO :: is it one driver or all driver?? Rename appropriately/
export const fetchJob = createAsyncThunk(
  "recruiter/fetch-jobList",
  async (token, thunkAPI) => {
    try {
      const response = await fetchJobAPIsHelper(token); // userAPI.fetchById(userId)
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addJob = createAsyncThunk(
  "recruiter/add-job",
  async (job_data, thunkAPI) => {
    try {
      const response = await addJobAPIsHelper(job_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateJob = createAsyncThunk(
  "recruiter/update-job",
  async (job_data, thunkAPI) => {
    try {
      const response = await updateJobAPIsHelper(job_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// TODO: Rename function... how many are we deleting?
export const deleteJob = createAsyncThunk(
  "recruiter/delete-job",
  async (job_data, thunkAPI) => {
    try {
      const response = await deleteJobAPIsHelper(job_data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addBulkJob = createAsyncThunk(
  "recruiter/add-bulk-job",
  async (job_data, thunkAPI) => {
    try {
      const response = await addBulkJobAPIsHelper(job_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const AddInterview = createAsyncThunk(
"interview/Add-Interview",
async (interview_data, thunkAPI) => {
  try {
    const response = await addinterviewAPIsHelper(interview_data); // userAPI.fetchById(userId)
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
}
);

export const fetchInterview = createAsyncThunk(
"interview/fetch-Interview",
async (interview_data, thunkAPI) => {
  try {
    const response = await fetchinterviewAPIsHelper(interview_data); // userAPI.fetchById(userId)
    console.log(response, "gsdgrdgdbgdbd");
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
}
);

export const fetchUpcomingInterview = createAsyncThunk(
"interview/fetch-Upcoming-Interview",
async (accessToken, thunkAPI) => {
  try {
    const response = await fetchupcominginterviewAPIsHelper(accessToken); // userAPI.fetchById(userId)
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
}
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    addJobDetails: (state, action) => {
      // console.log(action.payload, "STATE.AUTHProfile");
      state.jobData = action.payload;
    },
    fetchInterviewStatus: (state, action) => {
      state.InterviewList = action.value;
    },
    fetchUpcomingInterviewStatus: (state, action) => {
      state.UpcomingInterview = action.value;
    },
  },
  extraReducers: (builder) => {
    // TODO: Add cases for - deleted driver, updated driver,
    // TODO: everytime an action is made on a driver profile, we should update state.drivers array accordingly. Otherwise, stale data will be shown to users.
    // builder;
    // .addCase(fetchJob.fulfilled, (state, action) => {
    //   state.drivers = action.payload.data;
    //   console.log("action", JSON.stringify(state.drivers));
    // })
    // .addCase(fetchJob.rejected, (state, action) => {
    //   state.drivers = null;
    //   console.log("action", JSON.stringify(action));
    // });
    builder
      .addCase(addJob.fulfilled, (state, action) => {
        state.jobData = action.payload.data;
      })
      .addCase(addJob.rejected, (state, action) => {
        // state.jobData = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchJob.fulfilled, (state, action) => {
        state.jobs = action.payload;
      })
      .addCase(fetchJob.rejected, (state, action) => {
        // state.jobs = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        // state.jobData = action.payload.data;
      })
      .addCase(updateJob.rejected, (state, action) => {
        // state.jobs = null;
        // console.log("action", JSON.stringify(action));
      })
      .addCase(addBulkJob.fulfilled, (state, action) => {
        // state.jobData = action.payload.data;
      })
      .addCase(addBulkJob.rejected, (state, action) => {
        // state.jobs = null;
        console.log("addbulkjobaction", JSON.stringify(action));
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        // console.log("action JOBS", JSON.stringify(action.payload));
        // state.jobData = action.payload.data;
      })
      .addCase(deleteJob.rejected, (state, action) => {
        // state.jobs = null;
        // console.log("action", JSON.stringify(action));
      })
      .addCase(fetchUpcomingInterview.fulfilled, (state, action) => {
        state.UpcomingInterview = action.payload.data;
        console.log(action.payload.data, "uhjhbsjadbjsb");
      })
      .addCase(fetchUpcomingInterview.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // TODO: show errors
      })
      .addCase(fetchInterview.fulfilled, (state, action) => {
        state.InterviewList = action.payload.data.data;
      })
      .addCase(fetchInterview.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(AddInterview.fulfilled, (state, action) => {
        // state.auth_profile = action.payload.data.recruiter;
        // console.log("Interview action", JSON.stringify(action));
      })
      .addCase(AddInterview.rejected, (state, action) => {
        console.log("action", JSON.stringify(action.payload));
      });
  },
});

// Action creators are generated for each case reducer function
export const { addJobDetails, fetchInterviewStatus, fetchUpcomingInterviewStatus } = jobSlice.actions;

export default jobSlice.reducer;
