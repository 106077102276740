import { MS_AUTH_TOKEN } from "../actions/authActions";

// TODO: Move this to Auth Reducer.
const initialState = {
  accesstoken: null,
};

const microsoftReducer = (state = initialState, action) => {
  switch (action.type) {
    case MS_AUTH_TOKEN:
      return { ...state, accesstoken: action.value };
    default:
      return state;
  }
};

export default microsoftReducer;
