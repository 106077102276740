import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  applicantLoginAPIHelper,
  applicantOnboardingAPIHelper,
  fetchRecruiterDashboardAPIHelper,
  fetchRecruiterProfileAPIHelper,
  recruiterLoginAPIHelper,
  recruiterOnboardingAPIHelper,
  updateRecruiterProfileAPIHelper,
} from "../../helpers/authAPI";

/**
 * Should contain information related to authorization and profile only
 *
 * profile shall contain - standard contact information
 * authorization shall contain - headers, accessToken
 * firebase
 */
const initialState = {
  is_applicantPortal: false,
  firebaseResponse: "",
  firebaseIdToken: "",
  is_authenticated: false,
  auth_profile: {
    company: "",
    linkedIn_url: "",
    id: "",
    name: "",
    email: "",
    phone: "",
  },
  auth_dashboard: {
    applicants: {
      hired: "",
      interviewed: "",
      offered: "",
      shortlisted: "",
      rejected: "",
      totalApplicants: "",
    },
    jobs: {
      expiredJobs: "",
      jobsCount: "",
      publishedJobCount: "",
      unpublishedJobCount: "",
    },
    sucess: "",
  },
  value: 0,
  errors: null,
  token: "",
  refreshToken: "",
};

export const signInApplicant = createAsyncThunk(
  "auth/signInApplicant",
  async (token, thunkAPI) => {
    try {
      const response = await applicantLoginAPIHelper(token); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const signInUser = createAsyncThunk(
  "auth/signInUser",
  async (token, thunkAPI) => {
    try {
      const response = await recruiterLoginAPIHelper(token); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const onBoardingApplicant = createAsyncThunk(
  "auth/onBoardingApplicant",
  async (recruiter_data, thunkAPI) => {
    try {
      const response = await applicantOnboardingAPIHelper(recruiter_data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const onBoardingRecruiter = createAsyncThunk(
  "auth/onBoardingRecruiter",
  async (recruiter_data, thunkAPI) => {
    try {
      const response = await recruiterOnboardingAPIHelper(recruiter_data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateRecruiterProfile = createAsyncThunk(
  "auth/updateRecruiterProfile",
  async (recruiter_data, thunkAPI) => {
    try {
      const response = await updateRecruiterProfileAPIHelper(recruiter_data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchRecruiterProfile = createAsyncThunk(
  "auth/fetchRecruiterProfile",
  async (token, thunkAPI) => {
    try {
      const response = await fetchRecruiterProfileAPIHelper(token);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchRecruiterDashboard = createAsyncThunk(
  "auth/fetchRecruiterDashboard",
  async (token, thunkAPI) => {
    try {
      // recruiter/dashboard/
      const response = await fetchRecruiterDashboardAPIHelper(token);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const logOutRecruiterProfile = createAsyncThunk(
  "auth/logOutRecruiterProfile",
  async (thunkAPI) => {
    return true;
  }
);

export const resetAuthState = createAsyncThunk(
  "auth/resetAuthState",
  async () => {}
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthStatus: (state, action) => {
      state.is_authenticated = true;
    },
    updateAuthProfile: (state, action) => {
      state.auth_profile = action.payload;
    },
    updateAuthDasboard: (state, action) => {
      state.auth_dashboard = action.payload;
    },
    resetState: (state, action) => {
      state.token = "";
      state = [];
    },
    saveFirebaseIdToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    resetAuthState: (state) => {
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onBoardingApplicant.fulfilled, (state, action) => {})
      .addCase(onBoardingApplicant.rejected, (state, action) => {
        state.errors = action.payload.value;
      })
      .addCase(onBoardingRecruiter.fulfilled, (state, action) => {})
      .addCase(onBoardingRecruiter.rejected, (state, action) => {
        state.errors = action.payload.value;
      })
      .addCase(signInApplicant.fulfilled, (state, action) => {
        state.firebaseResponse = action.payload.data.success;
        state.is_authenticated = true;
        state.is_applicantPortal = true;
        state.token = action.payload.data.token;
      })
      .addCase(signInApplicant.rejected, (state, action) => {
        state.auth_profile = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.firebaseResponse = action.payload.data.success;
        state.is_authenticated = true;
        state.is_applicantPortal = false;
        state.token = action.payload.data.token;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.auth_profile = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchRecruiterProfile.fulfilled, (state, action) => {
        state.auth_profile = action.payload.recruiter;
      })
      .addCase(fetchRecruiterDashboard.fulfilled, (state, action) => {
        state.auth_dashboard = action.payload;
      })
      .addCase(updateRecruiterProfile.fulfilled, (state, action) => {
        console.log("Recruiter Profile action updated successfully");
        // state.auth_profile = action.payload.data;
      })
      .addCase(logOutRecruiterProfile.fulfilled, (state, action) => {
        state.is_authenticated = false;
      })
      // .addCase(renewAccessToken.fulfilled, (state, action) => {
      //   state.token = action.payload.data.token;
      //   state.refreshToken = action.payload.data.refreshToken;
      //   console.log("action", JSON.stringify(action));
      // })
      // .addCase(renewAccessToken.rejected, (state, action) => {
      //   console.log("action", JSON.stringify(action));
      // })
      .addCase(resetAuthState.fulfilled, (state) => {
        state.token = "";
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAuthStatus,
  updateAuthProfile,
  updateAuthDasboard,
  incrementByAmount,
  resetState,
  saveFirebaseIdToken,
} = authSlice.actions;

export default authSlice.reducer;
