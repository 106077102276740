import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgYGulsDfu4VFt_tcPfQwAPjZccMe7nA0",
  authDomain: "pelagic-core-297908.firebaseapp.com",
  databaseURL: "https://pelagic-core-297908-default-rtdb.firebaseio.com",
  projectId: "pelagic-core-297908",
  storageBucket: "pelagic-core-297908.appspot.com",
  messagingSenderId: "376262739834",
  appId: "1:376262739834:web:30b9e5eabf6fba5bcbf8c3",
  measurementId: "G-R6KFJW41RT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const googleprovider = new GoogleAuthProvider();
export const microsoftprovider = new OAuthProvider("microsoft.com");
