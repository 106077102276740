import React from "react";
import { Button, Form, Input, Select, Row, Col, Drawer, Space, message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch, useSelector } from "react-redux";
import {
  AddInterview,
  fetchInterview,
} from "../store/reducers/jobReducer";

dayjs.extend(customParseFormat);
const { TextArea } = Input;
const oneChange = (e) => {
  // console.log(`checked = ${e.target.checked}`);
};

function NewInterview(props) {
  const authData = useSelector((state) => state?.auth);
  const Accesstoken = useSelector(
    (state) => state?.microsoftReducer?.accesstoken
  );
  const companyProcessList = useSelector(
    (state) => state?.otherReducer?.companyprocessList
  );
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const Data_body = JSON.stringify({
      subject: `Interview For ${props?.positionName}`,
      body: {
        contentType: "HTML",
        content: `Interview for ${values?.interviewstage} Round for ${props?.positionName}`,
      },
      start: {
        dateTime: `${values?.interviewdatetime}`,
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: `${values?.interviewenddatetime}`,
        timeZone: "Asia/Kolkata",
      },
      location: {
        displayName: `${values?.interviewtype}`,
      },
      attendees: [
        {
          emailAddress: {
            address: `${props?.applicantEmail}`,
            name: `${props?.applicantName}`,
          },
          type: "required",
        },
        {
          emailAddress: {
            address: `${props?.recruiterDetails?.email}`,
            name: `${props?.recruiterDetails?.name}`,
          },
          type: "required",
        },
      ],
      isOrganizer: true,
      responseRequested: true,
      reminderMinutesBeforeStart: 15,
      isReminderOn: true,
      allowNewTimeProposals: false,
      isOnlineMeeting: true,
      onlineMeetingProvider: "teamsForBusiness",
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Accesstoken}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: Data_body,
      redirect: "follow",
    };

    const interview_data = {
      jobApplicantId: props?.jobapplicantId,
      processId: values?.interviewstage,
      evaluatorId: props?.recruiterDetails?.id,
      interviewStart: values?.interviewdatetime,
      interviewEnd: values?.interviewenddatetime,
      interviewLink: "InterviewLink",
      interviewType: values?.interviewtype,
      accessToken: authData?.token,
    };

    if (values?.interviewType != "Walkin Interview") {
      fetch("https://graph.microsoft.com/v1.0/me/events", requestOptions)
        .then((responseRequested) => console.log(responseRequested))
        .catch((error) => console.log("error", error));
    }
    dispatch(AddInterview(interview_data))
      .then((response) => {
        // console.log("kjhkhjhjhk", response.data);
        if (response.payload.data.success == true) {
          message.success("Interview scheduled successfully");
          dispatch(fetchInterview(interview_data)).then((response) =>
            props.onClose()
          );
        } else {
          message.error("Interview not scheduled");
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Drawer
        title="Schedule Interview"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        {/* {console.log(props.isAuthenticated, "isAuthenticated")} */}
        {props?.isAuthenticated ? (
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onSubmit={onSubmit}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="applicantname" label="Applicant">
                  <Input
                    placeholder="Please enter Applicant"
                    defaultValue={props?.applicantName}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="applicantemail" label="Email">
                  <Input
                    placeholder="Please enter Applicant Email"
                    defaultValue={props?.applicantEmail}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name="positionname" label="Position">
                  <Input
                    placeholder="Please enter Position"
                    defaultValue={props?.positionName}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Interview Stage" name="interviewstage">
                  <Select>
                    {companyProcessList
                      ? companyProcessList?.map((companyProcess) => {
                          return (
                            <Select.Option value={companyProcess?.id}>
                              {companyProcess?.name} ({companyProcess?.round})
                            </Select.Option>
                          );
                        })
                      : "Not Available"}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Interview Type" name="interviewtype">
                  <Select>
                    <Select.Option value="Telephonic Interview">
                      Telephonic Interview
                    </Select.Option>
                    <Select.Option value="Walkin Interview">
                      Walkin Interview
                    </Select.Option>
                    <Select.Option value="Video Conference">
                      Video Conference
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Interviewer" name="interviewer">
                  <Select>
                    <Select.Option value={props?.recruiterDetails.id}>
                      {props?.recruiterDetails?.name}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  label="Interview Date & Time"
                  name="interviewdatetime"
                >
                  <Input
                    type="datetime-local"
                    placeholder="Enter Date & Time"
                    default={"01/01/2023 10:00 AM"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  label="Interview End Date & Time"
                  name="interviewenddatetime"
                  default={"01/01/2023 10:00 AM"}
                >
                  <Input
                    type="datetime-local"
                    placeholder="Enter Date & Time"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item label="Messages(optional)" name="interviewermessage">
                  <TextArea rows={4} maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Space>
                <Button onClick={props?.onClose}>Cancel</Button>
                <Button htmlType="submit" type="primary">
                  Schedule Interview
                </Button>
              </Space>
            </Row>
          </Form>
        ) : (
          <Button type="primary" as="button" onClick={props?.login}>
            Sign-In
          </Button>
        )}
      </Drawer>
    </>
  );
}

export default NewInterview;
