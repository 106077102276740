import React, { useState } from "react";
import { Card, Row, Col, Button, Divider, Typography } from "antd";
import AddSingleApplicant, { show } from "./AddSingleApplicant";
import { useDispatch, useSelector } from "react-redux";
const { Text, Title } = Typography;

function ApplicantsCount() {
  const authData = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AddSingleApplicant onClose={onClose} open={open} />
      <Card style={{ width: "100%", marginBlock: "0.1%" }}>
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            <Title
              level={4}
              style={{ padding: "0px", fontSize: "22px", fontWeight: "normal" }}
            >
              Total Trainee 
              ({authData?.auth_dashboard
                ? authData?.auth_dashboard?.applicants?.totalApplicants
                : 0})
            </Title>
          </Col>
          <Col>
            <Button type="primary" onClick={showDrawer}>
              Add Trainee
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row style={{ justifyContent: "space-between" }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#d48806",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
            <i className="fa-solid  fa-user-plus" />
              <b style={{ marginLeft: "5%" }}>
                {authData?.auth_dashboard
                  ? authData?.auth_dashboard?.applicants?.shortlisted
                  : 0}
              </b>
            </span>
            <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
              <b>Enrolled</b>
            </span>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#d4b106",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid  fa-user" />
              <b style={{ marginLeft: "5%" }}>
                {authData?.auth_dashboard
                  ? authData?.auth_dashboard?.applicants?.interviewed
                  : 0}
              </b>
            </span>
            <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
              <b>Ground School</b>
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#7cb305",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid  fa-plane" />
              <b style={{ marginLeft: "5%" }}>
                {authData?.auth_dashboard
                  ? authData?.auth_dashboard?.applicants?.offered
                  : 0}
              </b>
            </span>
            <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
              <b>Flight Training</b>
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#389e0d",
              fontSize: " 1rem",
              textAlign: "center",
              // width: 50,
            }}
          >
            <span>
              <i className="fa-solid fa-address-card" />
              <b style={{ marginLeft: "5%" }}>
                {authData?.auth_dashboard
                  ? authData?.auth_dashboard?.applicants?.hired
                  : 0}
              </b>
            </span>
            <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
              <b>License Issued</b>
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#cf1322",
              fontSize: " 1rem",
              textAlign: "center",
            }}
          >
            <span>
              <i className="fa-solid  fa-user-xmark" />
              <b style={{ marginLeft: "5%" }}>
                {authData?.auth_dashboard
                  ? authData?.auth_dashboard?.applicants?.rejected
                  : 0}
              </b>
            </span>

            <span style={{ fontSize: "calc(1px + 2.1vmin)" }}>
              <b>Drop out</b>
            </span>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ApplicantsCount;
