import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Card, Layout, Row, Button, Col } from "antd";
import OngoingActivity from "../ats_components/OngoingActivity";
import PositionSummary from "../ats_components/PositionSummary";
import AppliedCandidateList from "../ats_components/AppliedCandidateList";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobApplicant } from "../store/reducers/jobApplicantReducer";
import { useEffect } from "react";
import Header from "../ats_components/Header";

function PositionTracker() {
  const authData = useSelector((state) => state?.auth);
  const authDashboard = useSelector((state) => state?.jobReducer?.jobs?.jobs);
  // const jobapplicantsdata = useSelector(
  //   (state) => state.jobApplicantReducer.fetchjobapplicantData
  // );
  const dispatch = useDispatch();
  let url_params = useParams();
  const filteredData = authDashboard.filter(
    (data) => data?.id == url_params?.id
  );
  // console.log(filteredData, "bfjshfejfj");
  useEffect(() => {
    const data = { token: authData?.token, jobId: url_params?.id };
    dispatch(fetchJobApplicant(data));
  }, []);
  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <PositionSummary
        title={filteredData[0]?.title}
        description={filteredData[0]?.description}
        client_name={filteredData[0]?.id}
        openings={filteredData[0]?.vacancy_count}
        updatepositionDetails={filteredData[0] ? filteredData[0] : null}
        instructor={filteredData[0]?.hiringManager[0]?.name}
      />
      <OngoingActivity openings={filteredData[0]?.vacancy_count} />
      <AppliedCandidateList />
    </Layout>
  );
}

export default PositionTracker;
