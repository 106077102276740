export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const RECRUITER_LOGIN = "RECRUITER_LOGIN";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const MS_AUTH_TOKEN = "MS_AUTH_TOKEN";
export const IS_USER_EXISTS = "IS_USER_EXISTS";
export const FIREBASE_TOKEN = "FIREBASE_TOKEN"; // OTP_RESPONSE OTP_VERIFICATION_RES
export const OTP_RESPONSE = "OTP_RESPONSE";
export const OTP_VERIFICATION_RES = "OTP_VERIFICATION_RES";
export const RECRUITER_ONBOARDING = "RECRUITER_ONBOARDING";
export const RECRUITER_PROFILE = "RECRUITER_PROFILE";

// import SyncStorage from "sync-storage"; FIREBASE_TOKEN
// import SyncStorage from "@react-native-async-storage/async-storage";

export const error = (payload) => ({
  type: ERROR,
  value: payload,
});

export const success = (payload) => ({
  type: SUCCESS,
  value: payload,
});

export const firebaseToken = (payload) => ({
  type: FIREBASE_TOKEN,
  value: payload,
});
export const authToken = (payload) => ({
  type: AUTH_TOKEN,
  value: payload,
});

export const msauthToken = (payload) => ({
  type: MS_AUTH_TOKEN,
  value: payload,
});
// export const sentOTPResponse = (payload) => ({
//   type: OTP_RESPONSE,
//   value: payload,
// });
// export const OTPVerificationResponse = (payload) => ({
//   type: OTP_VERIFICATION_RES,
//   value: payload,
// });
export const show_Loader = () => ({ type: "SHOW_LOADER" });
export const hide_Loader = () => ({ type: "HIDE_LOADER" });

export const recruiterLogin = (payload) => ({
  type: RECRUITER_LOGIN,
  value: payload,
});
export function saveFirebaseTokenToApp(payload) {
  console.log("payload data", payload);
  return (dispatch, getState) => {
    dispatch(firebaseToken(payload));
  };
}
