import React, { useEffect } from "react";
import { Layout } from "antd";
import DashboardSummary from "../ats_components/DashboardSummary";
import { useDispatch, useSelector } from "react-redux";
import { fetchJob } from "../store/reducers/jobReducer";
import { fetchRecruiterProfile } from "../store/reducers/authReducer";
import ViewTeammembers from "../ats_components/ViewTeamMembers";

function Dashboard() {
  const authData = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRecruiterProfile(authData?.token));
    dispatch(fetchJob(authData?.token));
  }, []);

  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <DashboardSummary />
      <ViewTeammembers />
    </Layout>
  );
}

export default Dashboard;
