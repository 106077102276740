import { Col, Layout, Row } from "antd";
import React, { useEffect } from "react";
import ApplicantsCount from "../ats_components/ApplicantsCount";
import PositionsCount from "../ats_components/PositionsCount";
import RecentActivities from "../ats_components/RecentActivities";
import UpcomingInterviews from "../ats_components/UpcomingInterviews";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRecruiterDashboard,
  fetchRecruiterProfile
} from "../store/reducers/authReducer";
import { fetchUpcomingInterview } from "../store/reducers/jobReducer";
import { fetchJob } from "../store/reducers/jobReducer";
import { fetchChoices, fetchSkills, fetchClientCompany } from "../store/reducers/otherReducer";
import { fetchMember } from "../store/reducers/AddTeamMemberReducer";
import { fetchApplicant } from "../store/reducers/applicantReducer";

function Home() {
  const authData = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSkills(authData?.token));
    dispatch(fetchMember(authData?.token));
    dispatch(fetchChoices(authData?.token));
    dispatch(fetchRecruiterDashboard(authData?.token));
    dispatch(fetchRecruiterProfile(authData?.token));
    dispatch(fetchJob(authData?.token));
    dispatch(fetchUpcomingInterview(authData?.token));
    dispatch(fetchApplicant(authData?.token));
    dispatch(fetchClientCompany(authData?.token))
  }, []);

  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <Row
        gutter={4}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col xl={16} lg={15} xs={24}>
          <ApplicantsCount />
        </Col>
        <Col xl={8} lg={9} xs={24}>
          <PositionsCount />
        </Col>
      </Row>

      <Row
        gutter={4}
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col> 
          <UpcomingInterviews />
        </Col>
        {/* <Col lg={12} sm={24} md={24} xs={24}>
          <RecentActivities />
        </Col> */}
      </Row>
    </Layout>
  );
}

export default Home;
