import React, { useEffect, useState } from "react";
// import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Avatar, List, Skeleton } from "antd";
import { Button, Drawer } from "antd";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"; //Important please dont comment
import { useSelector } from "react-redux";
const pdfjs = require("pdfjs-dist");
const winkNLP = require("wink-nlp"); // Load wink-nlp package.
const model = require("wink-eng-lite-web-model"); // Load english language model.
const nlp = winkNLP(model); // Instantiate winkNLP.
const its = nlp.its; // Obtain "its" helper to extract item properties.
const as = nlp.as; // Obtain "as" reducer helper to reduce a collection.

const ViewAnalytics = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const applicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicants
  );
  // console.log(applicantData, "bjbjbbjebjerj");
  const [resumeAnalytics, setresumeAnalytics] = useState([]);

  useEffect(() => {
    async function getResumeDetails(src) {
      // console.log(src.resume);
      const srcdoc = await pdfjs.getDocument(src.resume).promise;
      const numPages = await srcdoc.numPages;
      var text = "";
      var text1 = "";

      for (let i = 1; i < numPages + 1; i++) {
        const page = await srcdoc.getPage(i);
        const page_data = await page.getTextContent();
        const items = page_data.items.map((item) => {
          text = text.concat(" ", item.str);
        });
      }

      text1 = text.replace(/ +(?= )/g, "");
      const emailtext = text1.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
          ? /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
          : ""
      );
      const doc = nlp.readDoc(text1);

      // Finding Skill
      var skillwordFreq = doc
        .customEntities()
        .filter((customEntity) => {
          return customEntity.out(its.type) === "skill";
        })
        .out(its.normal, as.freqTable);

      var instanceCount = 0;
      var skillsDetail = skillwordFreq.map((skillword) => {
        instanceCount = instanceCount + skillword[1];
        return { skillname: skillword[0], count: skillword[1] };
      });

      // Finding Experience
      var expwordFreq = doc
        .customEntities()
        .filter((customEntity) => {
          return customEntity.out(its.type) === "Experience";
        })
        .out(its.normal, as.freqTable);
      var expDetail = expwordFreq.map((expword) => {
        return { expwordName: expword[0], count: expwordFreq[1] };
      });

      const candidateDetails = {
        id: src.id,
        name: src.name,
        email: src.email, // email: emailtext ? emailtext[0] : "",
        totalexperience: src.totalexperience,
        skillcount: skillwordFreq.length,
        skillsFound: skillsDetail,
        skillinstanceCount: instanceCount,
      };
      return candidateDetails;
    }

    async function getAllResumes(testData, patterns) {
      nlp.learnCustomEntities(patterns);
      var resumeDetails = [];
      for (const src of testData) {
        const result = await getResumeDetails(src);
        resumeDetails.push(result);
      }
      resumeDetails.sort((a, b) => {
        if (a.skillcount === b.skillcount) {
          return b.skillinstanceCount - a.skillinstanceCount;
        }
        return b.skillcount - a.skillcount;
      });
      return resumeDetails;
    }

    getAllResumes(applicantData, patterns).then((result) => {
      const sorted = [];
      setresumeAnalytics(result);
    });
  }, []);

  const patterns = [
    { name: "skill", patterns: ["CNN"] },
    { name: "skill", patterns: ["RNN"] },
    { name: "skill", patterns: ["DL"] },
    { name: "skill", patterns: ["react"] },
    { name: "skill", patterns: ["javascript"] },
    { name: "skill", patterns: ["django"] },
    { name: "skill", patterns: ["python"] },
    { name: "skill", patterns: ["Data Analysis"] },
    { name: "skill", patterns: ["machine Learning"] },
    { name: "skill", patterns: ["Data Science"] },
    { name: "skill", patterns: ["artificial Intelligence"] },
    { name: "skill", patterns: ["Problem Solving"] },
    { name: "skill", patterns: ["mysql"] },
    { name: "skill", patterns: ["postgres"] },
    { name: "skill", patterns: ["docker"] },
    { name: "skill", patterns: ["vb.net"] },
    { name: "skill", patterns: ["plsql"] },
    { name: "skill", patterns: ["redux"] },

    { name: "Experience", patterns: ["Machine Learning Engineer"] },
    { name: "Experience", patterns: ["Artificial Intelligence Engineer"] },
    { name: "Experience", patterns: ["ML Engineer"] },
    { name: "Experience", patterns: ["AI Engineer"] },
    { name: "Experience", patterns: ["Data scientist"] },
    { name: "Experience", patterns: ["Data Analyst"] },
    { name: "Experience", patterns: ["software engineer"] },
    { name: "Experience", patterns: ["software developer"] },
    { name: "Experience", patterns: ["junior software engineer"] },
    { name: "Experience", patterns: ["junior software developer"] },
    { name: "Experience", patterns: ["senior software engineer"] },
    { name: "Experience", patterns: ["senior software developer"] },
    { name: "Experience", patterns: ["associate software engineer"] },
    { name: "Experience", patterns: ["associate software developer"] },
  ];

  const handleClick = () => {
    showDrawer();
  };

  return (
    <>
      <Button
        type="primary"
        onClick={handleClick}
        style={{
          color: "white",
          backgroundColor: "#1890FF",
          border: "none",
        }}
      >
        View Analytics
      </Button>
      <Drawer
        title="Position Analytics"
        width="55%"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        {/* <Card
          hoverable
          style={
            {
              //   width: 240,
            }
          }
        >
         <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={8}>
                <Card bordered={false}>
                  <Avatar
                    style={{ width: 120, height: 120 }}
                    src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpghttps://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Eo_circle_deep-purple_number-1.svg/2048px-Eo_circle_deep-purple_number-1.svg.png"
                  />
                  <p>
                    {resumeAnalytics[0]?.email
                      ? resumeAnalytics[0]?.email
                      : "Name"}
                  </p>
                  <p>
                    <MailOutlined />{" "}
                    {resumeAnalytics[0]?.email
                      ? resumeAnalytics[0]?.email
                      : "Email"}
                  </p>
                  <p>
                    <b>
                      {resumeAnalytics[0]?.skillcount
                        ? resumeAnalytics[0]?.skillcount
                        : ""}{" "}
                      Skills Matched
                    </b>
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <Avatar
                    style={{ width: 120, height: 120 }}
                    src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpghttps://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Eo_circle_deep-purple_number-1.svg/2048px-Eo_circle_deep-purple_number-1.svg.png"
                  />
                  <p>
                    {resumeAnalytics[1]?.email
                      ? resumeAnalytics[1]?.email
                      : "Name"}
                  </p>
                  <p>
                    <MailOutlined />{" "}
                    {resumeAnalytics[1]?.email
                      ? resumeAnalytics[1]?.email
                      : "Email"}
                  </p>
                  <p>
                    <b>
                      {resumeAnalytics[1]?.skillcount
                        ? resumeAnalytics[1]?.skillcount
                        : ""}{" "}
                      Skills Matched
                    </b>
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <Avatar
                    style={{ width: 120, height: 120 }}
                    src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpghttps://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Eo_circle_deep-purple_number-1.svg/2048px-Eo_circle_deep-purple_number-1.svg.png"
                  />
                  <p>
                    {resumeAnalytics[2]?.email
                      ? resumeAnalytics[2]?.email
                      : "Name"}
                  </p>
                  <p>
                    <MailOutlined />{" "}
                    {resumeAnalytics[2]?.email
                      ? resumeAnalytics[2]?.email
                      : "Email"}
                  </p>
                  <p>
                    <b>
                      {resumeAnalytics[2]?.skillcount
                        ? resumeAnalytics[2]?.skillcount
                        : ""}{" "}
                      Skills Matched
                    </b>
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
         </Card> */}
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={resumeAnalytics}
          renderItem={(item) => (
            <List.Item>
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={item?.picture?.large} />}
                  title={
                    <b>
                      <p href="">{item?.name ? item.name : "Not Available"}</p>
                    </b>
                  }
                  description={
                    <p>
                      <p>{`${item?.email ? item.email : "No Available"}`}</p>
                      <p>
                        {`${
                          item?.totalexperience
                            ? item.totalexperience
                            : "No Available"
                        } Years Experience`}
                      </p>
                      <p>
                        <b>
                          {`${
                            item?.skillcount ? item.skillcount : "No Available"
                          } Skills Found in Resume `}
                        </b>
                      </p>
                      {item?.skillsFound?.map((e) => {
                        return (
                          <b>{`${
                            e?.skillname ? e.skillname : "No Available"
                          }, `}</b>
                        );
                      })}
                    </p>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};
export default ViewAnalytics;
