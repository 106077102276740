import { BankOutlined, GlobalOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  message,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onBoardingApplicant,
  onBoardingRecruiter,
  signInApplicant,
  signInUser,
} from "../store/reducers/authReducer";
import ReactQuill from "react-quill";

function Signup(props) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.firebaseToken);

  const onFinish = (values) => {
    setLoading(true);
    const recruiter_data = {
      token_id: accessToken,
      name: values?.name,
      companyName: values?.company,
      companyWebsite: values?.company_website,
    };
    dispatch(onBoardingRecruiter(recruiter_data))
      .then((result) => {
        // TODO: why are we not checking if the result is success or false?
        if (result.success === false) {
          alert(result.error);
          return;
        } else {
          dispatch(signInUser(accessToken)).then((response) => {
            if (response.payload.data.success === true) {
              props?.onClose();
              message.success("Sign up successfully");
              navigate({ pathname: "/home" });
            }
          });
        }
      })
      .catch((error) => {
        navigate({ pathname: "/" });
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
  };

  const onFinishApplicant = (values) => {
    setLoading(true);
    const applicant_data = {
      token_id: accessToken,
      name: values.name,
      email: values.email,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate ? values.birthdate : "None",
    };

    dispatch(onBoardingApplicant(applicant_data))
      .then((result) => {
        // TODO: why are we not checking if the result is success or false?
        if (result.success === false) {
          alert(result.error);
          return;
        } else {
          dispatch(signInApplicant(accessToken)).then((response) => {
            if (response.payload.data.success === true) {
              props?.onApplicantClose();
              message.success("sign up successfully");
              navigate({ pathname: "/applicant/home" });
            }
          });
        }
      })
      .catch((error) => {
        navigate({ pathname: "/" });
      });
  };

  const onFinishFailedApplicant = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmitApplicant = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal title="Create Your Account" open={props?.isModalOpen}>
        <Row lg={24}>
          <Col span={24}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onSubmit={onSubmit}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Enter valid Name!" }]}
              >
                <Input
                  type="text"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                name="company"
                rules={[{ message: "Enter valid Company Name!" }]}
              >
                <Input
                  prefix={<BankOutlined className="site-form-item-icon" />}
                  placeholder="Company Name"
                />
              </Form.Item>
              <Form.Item
                name="company_website"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Company Website!",
                  },
                ]}
              >
                <Input
                  prefix={<GlobalOutlined className="site-form-item-icon" />}
                  placeholder="Company Website"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal title="Register Your Account" open={props?.isapplicantModalOpen}>
        <Form
          id="applicantform"
          encType="multipart/form-data"
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmitApplicant}
          onFinish={onFinishApplicant}
          onFinishFailed={onFinishFailedApplicant}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Trainee name",
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Email",
                  },
                ]}
              >
                <Input
                  type="email"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Email"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Phone Number",
                  },
                ]}
              >
                <Input
                  type="text"
                  pattern="\d{10}"
                  title="Error: 10 digits are required."
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode &gt;= 48 && event.charCode &lt;= 57"
                  placeholder="Enter Phone Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="birthdate" label="Date of Birth">
                <Input
                  type="date"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Date of Birth"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space>
                {/* <Button onClick={props?.onClose}>Cancel</Button> */}
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Signup;
