import API from "../store/utils/APIUtils";

export const fetchMemberAPIsHelper = async (token) => {
  const response = await API.get(`recruiter/member/`, {
    headers: {
      Authorization: "token " + token,
      "Content-Type": "application/json",
    },
  });
  console.log(response,'respnose')
  return response; // fetchMemberAPIsHelper
};

export const addMemberAPIsHelper = async (member_data) => {
  const response = await API.post(`/recruiter/member/`, member_data.values, {
    headers: {
      Authorization: "Token " + member_data.accessToken,
      "Content-Type": "application/json",
    },
  });
  return response; // addMemberAPIsHelper
};

export const updateMemberAPIsHelper = async (member_data) => {
  if (member_data) {
    const response = await API.put(
      `recruiter/member/?memberId=${member_data.Id}`,
      member_data.values,
      {
        headers: {
          Authorization: `token ${member_data.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // updateMemberAPIsHelper
  }
};

export const deleteMemberAPIsHelper = async (member_data) => {
  const response = await API.delete(
    `/recruiter/member/?memberId=${member_data.memberId}`,
    {
      headers: {
        Authorization: "Token " + member_data.access_token,
        "Content-Type": "application/json",
      },
    }
  );

  return response; // deleteMemberAPIsHelper
};
