//Process and Applicant Review API
import API from "../store/utils/APIUtils";

export const fetchProcessReviewAPIsHelper = async (token) => {
  const response = await API.get(`recruiter/process/reviewstate/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchInterviewAPIsHelper
};

export const fetchApplicantReviewAPIsHelper = async (token) => {
  const response = await API.get(`recruiter/applicant/reviewstate/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  return response; // fetchstatusAPIsHelper
};

export const UpdateProcessReviewAPIsHelper = async (data) => {
  const response = await API.post(
    `recruiter/update/applicant/process/status/${data.interviewId}`,
    data.values,
    {
      headers: {
        Authorization: `Token ${data.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // updatestatusAPIsHelper
};

export const UpdateApplicantReviewAPIsHelper = async (data) => {
  const response = await API.post(
    `recruiter/update/applicant/status/${data.jobApplicantId}`,
    data.values,
    {
      headers: {
        Authorization: "Token " + data.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response; // updateprocessAPIsHelper ?applicantId=${job_data.id}
};
