import {
  ContainerOutlined,
  UserOutlined,
  HomeOutlined,
  TeamOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import AddClient from "./AddClient";


const MenuBar = () => {
  const authData = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Menu mode="inline" style={{ height: "100%" }}>
      <Menu.Item key="1">
        <HomeOutlined />
        <span>
          <Link to="/home">Home</Link>
        </span>
      </Menu.Item>
      <Menu.Item key="3">
        <ContainerOutlined />
        <span>
          <Link to="/requisition">Batches</Link>
        </span>
      </Menu.Item>
      <Menu.Item key="4">
        <TeamOutlined />
        <span>
          <Link to="/applicants">Trainees</Link>
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <UserOutlined />
        <span>
          <Link to="/profile">Profile</Link>
        </span>
      </Menu.Item>
      {/* <AddClient onClose={onClose} open={open} />
      <Menu.Item key="5">
      <SmileOutlined />
        <span>
        <Link onClick={showDrawer}>Client</Link>
        </span>
      </Menu.Item> */}
    </Menu>
  );
};
export default MenuBar;
