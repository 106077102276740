import React from "react";
import { Button, Card, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutRecruiterProfile } from "../store/reducers/authReducer";
import { auth } from "../config/firebaseConfig";
import imglog from "../assets/images/dronepad.png";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    auth.signOut().then(() => {
      dispatch(logOutRecruiterProfile()).then(() =>
        navigate({ pathname: "/" })
      );
    });
  };
  return (
    <>
      <div style={{ marginBottom: "0.5%", background: "white" }}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={12}>
            <img
              src={imglog}
              alt="Logo"
              style={{ marginLeft: 16, width: "100px", height: "70px" }}
            />
          </Col>
          <Col xs={6} sm={24} md={3} lg={2}>
            <Button onClick={() => handleLogOut()} type="primary">
              Sign out
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Header;
