import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchApplicantReviewAPIsHelper,
  fetchProcessReviewAPIsHelper,
  UpdateApplicantReviewAPIsHelper,
  UpdateProcessReviewAPIsHelper,
} from "../../helpers/reviewStatesAPI";

const initialState = {
  applicantReview: "",
  processReview: "",
};

// Process Review ASYNC THUNK FUNCTIONS

export const fetchProcessReview = createAsyncThunk(
  "review/fetch-Process-Review",
  async (token, thunkAPI) => {
    try {
      const response = await fetchProcessReviewAPIsHelper(token); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const UpdateProcessReview = createAsyncThunk(
  "review/update-Process-Review",
  async (data, thunkAPI) => {
    try {
      const response = await UpdateProcessReviewAPIsHelper(data); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// Applicant Review ASYNC THUNK FUNCTIONS

export const fetchApplicantReview = createAsyncThunk(
  "review/fetch-Applicant-Review",
  async (token, thunkAPI) => {
    try {
      const response = await fetchApplicantReviewAPIsHelper(token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const UpdateApplicantReview = createAsyncThunk(
  "review/Update-Applicant-Review",
  async (data, thunkAPI) => {
    try {
      const response = await UpdateApplicantReviewAPIsHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    fetchProcessReviewStatus: (state, action) => {
      state.processReview = action.value;
    },
    fetchApplicantReviewStatus: (state, action) => {
      state.applicantReview = action.value;
    },
    updateProcessReviewStatus: (state, action) => {
      state.processReview = action.payload;
    },
    updateApplicantReviewStatus: (state, action) => {
      state.applicantReview = action.payload;
    },
    // resetState: (state, action) => {
    //   state.token = "";
    //   state = [];
    //   console.log("reset state", action);
    //   console.log("reset state View", state);
    // },
    // resetAuthState: (state) => {
    //   state.token = "";
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcessReview.fulfilled, (state, action) => {
        state.processReview = action.payload.data;
      })
      .addCase(fetchProcessReview.rejected, (state, action) => {
        console.log("Error", JSON.stringify(action));
      })
      .addCase(UpdateProcessReview.fulfilled, (state, action) => {
        // state.processReview = action.payload.data;
      })
      .addCase(UpdateProcessReview.rejected, (state, action) => {
        console.log("Error", JSON.stringify(action));
      })
      .addCase(fetchApplicantReview.fulfilled, (state, action) => {
        state.applicantReview = action.payload.data;
      })
      .addCase(fetchApplicantReview.rejected, (state, action) => {
        console.log("Error", JSON.stringify(action));
      })
      .addCase(UpdateApplicantReview.fulfilled, (state, action) => {
        // state.applicantReview = action.payload.data.token;
      })
      .addCase(UpdateApplicantReview.rejected, (state, action) => {
        console.log("Error", JSON.stringify(action));
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchProcessReviewStatus,
  fetchApplicantReviewStatus,
  updateProcessReviewStatus,
  updateApplicantReviewStatus,
} = reviewSlice.actions;

export default reviewSlice.reducer;
