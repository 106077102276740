import React, { useState, useEffect } from "react";
import { Card, Typography, Steps, Col, Table, Button, Row, Space } from "antd";
import { useSelector } from "react-redux";

const { Title } = Typography;

function RecentActivities() {
  const data = useSelector((state) => state?.jobReducer?.UpcomingInterview);

  const results = data?.map((row) => ({
    key: row?.id, // I added this line
    name: row?.name,
    round: (
      <>
        {data?.map((e) => (
          <div key={e?.round}>
            <p>
              {e?.round?.name} ({e?.round?.round})
            </p>
          </div>
        ))}
      </>
    ),
    scheduledDate: row?.scheduledDate,
    evaluator: row?.evaluator,
    resume: row?.resume,
  }));

  const columns = [
    {
      title: "Batch ID",
      dataIndex: "name",
      key: "name",
      width: "10rem",
    },
    {
      title: "Round",
      dataIndex: "round",
      key: "round",
      width: "10rem",
      render: (round) => (
        <>
          {round?.name} ({round?.round})
        </>
      ),
    },
    {
      title: "Completed Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      width: "10rem",
    },
    {
      title: "Instructor",
      dataIndex: "evaluator",
      key: "evaluator",
      width: "10rem",
    },
  ];

  const source = [];
  for (let i = 0; i < data?.length - 1; i++) {
    source.push({
      key: i,
      name: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.name}>
                <p>{e?.name}</p>
              </div>
            ))}
          </div>
        </>
      ),
      round: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.round}>
                <p>
                  {e?.round?.name} ({e?.round?.round})
                </p>
              </div>
            ))}
          </div>
        </>
      ),
      scheduledDate: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.scheduledDate}>
                <p>{e?.scheduledDate}</p>
              </div>
            ))}
          </div>
        </>
      ),
      evaluator: (
        <>
          <div>
            {data?.map((e) => (
              <div key={e?.evaluator}>
                <p>{e?.evaluator}</p>
              </div>
            ))}
          </div>
        </>
      ),
    });
  }

  return (
    <Card style={{ marginBlock: "0.05%" }}>
      <Title level={3} style={{ fontSize: "22px", fontWeight: "normal" }}>
        Completed Batches
      </Title>
      <Table
        style={{ padding: "0px", textAlign: "center", height: "35vh" }}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 200 }}
        size="small"
      />
    </Card>
  );
}

export default RecentActivities;
