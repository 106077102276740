import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  Layout,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Modal,
  Form,
  Input,
  TimePicker,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplicantDashboard } from '../store/reducers/applicantReducer';
import ViewResume from '../ats_components/ViewResume';
import { fetchApplicantReview } from '../store/reducers/reviewStatesReducer';
import CertificateGenerator from './CertificateGenerator';
import { Divider, List } from 'antd';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from 'html-react-parser';

const { Text, Title } = Typography;

const data = [
  // 'Racing car sprays burning fuel into crowd.',
  // 'Japanese princess to wed commoner.',
  // 'Australian walks 100km after outback crash.',
  // 'Man charged over missing wedding girl.',
  // 'Los Angeles battles huge wildfires.',

  {
    title: 'ground training ',
    notes: 'covered theory',
    startDateTime: new Date().toLocaleString().toString(),
    endDateTime: new Date().toLocaleString().toString(),
    status: 'approved',
    approvedBy: 'Joe',
    recordDate: new Date().toDateString(),
  },
];

function ApplicantDashboard() {
  const cardStyle = {
    width: '100%',
    padding: '1vw',
    paddingRight: '2vw',
    marginBottom: '10px',
  };

  const [overallPercent, setOverallPercent] = useState(0);
  const [groundPercent, setGroundPercent] = useState(0);
  const [flightPercent, setFlightPercent] = useState(0);
  const [percent, setPercent] = useState(0);

  const dispatch = useDispatch();

  const authData = useSelector((state) => state?.auth);
  const DashboardData = useSelector((state) => state?.applicantReducer);
  const Djangotoken = useSelector((state) => state?.auth?.token);
  const applicantreviewStateData = useSelector(
    (state) => state?.reviewReducer?.applicantReview?.reviewstates
  );


  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    dispatch(fetchApplicantDashboard(authData?.token));
    dispatch(fetchApplicantReview(Djangotoken));

    if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status == 'Enrolled'
    ) {
      setOverallPercent(25);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status ==
        'Regulations & Legal Compliances'
    ) {
      setGroundPercent(33);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status ==
        'Safety & Emergency Procedure'
    ) {
      setGroundPercent(66);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status == 'Simulator Training'
    ) {
      setGroundPercent(100);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status == 'Basic Flight Training'
    ) {
      setOverallPercent(50);
      setGroundPercent(100);
      setFlightPercent(50);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status == 'Advance Flight Training'
    ) {
      setOverallPercent(75);
      setGroundPercent(100);
      setFlightPercent(100);
    } else if (
      DashboardData?.jobApplicantData != null &&
      DashboardData?.jobApplicantData[0]?.status == 'License Issued'
    ) {
      setOverallPercent(100);
      setGroundPercent(100);
      setFlightPercent(100);
      setPercent(100);
    }
  }, []);


  const onFinish = (values) => {

    /**
     *  title: 'ground training ',
    note: 'covered theory',
    startDateTime: new Date().toLocaleString().toString(),
    endDateTime: new Date().toLocaleString().toString(),
    status: 'approved',
    approvedBy: 'Joe',
    recordDate: new Date().toDateString(),
     */
    var raw = {
      startDateTime: new Date(values.sessionTime[0]).toLocaleString().toString() ,
      endDateTime: new Date(values.sessionTime[1]).toLocaleString().toString(),
      recordDate: values.sessionDate,
      'title': '',
      notes: values.description,
      status: "pending",

    };

    data.push(JSON.parse(JSON.stringify(raw)));
    const job_data = { values: JSON.stringify(raw), accessToken: authData?.token };
    setModal2Open(false);

    // dispatch(addJob(job_data))
    //   .then((response) => {
    //     if (response.payload.data.success == true) {
    //       message.success("Batch Added Successfully");
    //       dispatch(fetchJob(authData?.token));
    //       dispatch(fetchRecruiterDashboard(authData?.token)).then(
    //         (response) => showintDrawer(),
    //         props?.onClose()
    //       );
    //     } else {
    //       message.error("Batch already exists");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const showModal = () => {
    return (
      <Modal
        title="Add New Work Log"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div>
        <Form
          layout="vertical"
          initialValues={{ remember: false }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="sessionTime"
                label="Session Timing"
                rules={[
                  {
                    required: true,
                    message: "Enter valid session start and end time",
                  },
                ]}
              >
                <TimePicker.RangePicker
                  use12Hours
                  format="h:mm a"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="desiredStartDate"
                label="Batch Start Date"
                rules={[
                  {
                    required: true,
                    message: "Enter valid start date",
                  },
                ]}
              >
                <Input
                  type="datetime"
                  placeholder="Batch Start Date"
                  default={"01/01/2023"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="sessionDate"
                label="Session Date"
                rules={[
                  {
                    required: true,
                    message: "Enter session date",
                  },
                ]}
              >
                <Input
                  type="date"
                  placeholder="Session Date"
                  default={"01/02/2023"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

             <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="description" label="Notes">
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  placeholder="Your session notes "
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ flexDirection: "row-reverse" }}>
            <Col >
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        </div>
      </Modal>
    )
  }

  return (
    <Layout
      style={{
        marginRight: '3%',
        marginLeft: '3%',
        border: 'shadow',
      }}
    >
      <Card
        style={{
          width: '100%',
          marginBlock: '0.5%',
          paddingLeft: 14,
          paddingRight: 18,
        }}
      >
        <Title
          level={3}
          style={{
            paddingLeft: '0px',
            fontSize: '22px',
            fontWeight: 'normal',
          }}
        >
          Good day, <b>{DashboardData?.applicantData?.name}</b>
        </Title>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col style={{ marginRight: '2%' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-person-fill"
              viewBox="0 0 16 16"
              style={{
                background: '#dee2e6',
                borderRadius: '50%',
                padding: '10%',
              }}
            >
              {' '}
              <g clipPath="url(#clip0_54_3319)">
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  fill="white"
                />
              </g>{' '}
              <defs>
                <clipPath id="clip0_54_3319">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Col>
          <Col
            style={{
              padding: '1vw',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {/* <Text
              style={{
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <b>{DashboardData?.applicantData?.name}</b>
            </Text> */}
            <Text>
              <span style={{ fontWeight: 500, paddingRight: 8 }}> Email:</span>{' '}
              {DashboardData?.applicantData?.email}
            </Text>
            <Text>
              <span style={{ fontWeight: 500, paddingRight: 8 }}> Phone:</span>{' '}
              {DashboardData?.applicantData?.phoneNumber}
            </Text>
            <Text>
              <span style={{ fontWeight: 500 }}> Training Status:</span>{' '}
              {DashboardData?.applicantData?.status}
            </Text>
          </Col>

          {/* <Col offset={15} style={{ display: "flex", float: "right" }}>
            {DashboardData?.applicantData?.resume ? (
              <ViewResume resume_url={DashboardData?.applicantData?.resume} />
            ) : (
              <></>
            )}
          </Col> */}
        </Row>
      </Card>

      <Card style={cardStyle}>
        <Row>
          <div style={{ width: '100%' }}>
            <Title
              level={3}
              style={{ padding: '0px', fontSize: '22px', fontWeight: 'normal' }}
            >
              Training Report
              <Row style={{ display: 'flex', float: 'right' }}>
                {DashboardData?.jobApplicantData &&
                DashboardData?.jobApplicantData.length > 0 &&
                DashboardData?.jobApplicantData[0]?.status ==
                  'License Issued' ? (
                  <CertificateGenerator
                    name={DashboardData?.applicantData?.name}
                  />
                ) : (
                  <>
                    {/* <CertificateGenerator name={DashboardData.applicantData?.name} /> */}
                  </>
                )}
              </Row>
            </Title>

            <Space
              direction="vertical"
              size="large"
              style={{
                display: 'flex',
              }}
            >
              <Card
                title="Ground Training"
                extra={
                  <h1>
                    {DashboardData?.jobApplicantData &&
                    DashboardData?.jobApplicantData.length > 0
                      ? DashboardData?.jobApplicantData[0]?.job
                      : ''}
                  </h1>
                }
                size="large"
              >
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Col span={12}>
                    <b>Regulations & Legal Compliances</b>{' '}
                    <Progress
                      percent={
                        groundPercent == 33 ||
                        groundPercent == 66 ||
                        groundPercent == 100
                          ? 100
                          : 0
                      }
                    />
                    <br />
                    <br />
                    <b>Safety & Emergency Procedure</b>
                    <Progress
                      percent={
                        groundPercent == 66 || groundPercent == 100 ? 100 : 0
                      }
                    />
                    <br />
                    <br />
                    <b>Simulator Training</b>
                    <Progress percent={groundPercent == 100 ? 100 : 0} />
                  </Col>
                  <Col>
                    <Progress
                      type="circle"
                      percent={groundPercent}
                      style={{
                        marginRight: 16,
                      }}
                    />
                  </Col>
                </Row>
              </Card>

              <Card
                title="Flight Training"
                extra={
                  <h1>
                    {DashboardData?.jobApplicantData &&
                    DashboardData?.jobApplicantData.length > 0
                      ? DashboardData?.jobApplicantData[0]?.job
                      : ''}
                  </h1>
                }
                size="large"
              >
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Col span={12}>
                    <b>Basic Flight Training</b>
                    <Progress
                      percent={
                        flightPercent == 50 || flightPercent == 100 ? 100 : 0
                      }
                    />
                    <br />
                    <br />
                    <b>Advance Flight Training</b>
                    <Progress percent={flightPercent == 100 ? 100 : 0} />
                  </Col>
                  <Col>
                    <Progress
                      type="circle"
                      percent={flightPercent}
                      style={{
                        marginRight: 16,
                      }}
                    />
                  </Col>
                </Row>
              </Card>

              <Card
                title="Overall Performance"
                extra={
                  <h1>
                    {DashboardData?.jobApplicantData &&
                    DashboardData?.jobApplicantData.length > 0
                      ? DashboardData?.jobApplicantData[0]?.job
                      : ''}
                  </h1>
                }
                size="large"
              >
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Col span={12}>
                    <b>Enrollment & Verification</b>
                    <Progress
                      percent={
                        DashboardData?.applicantData?.status == 'Enrolled'
                          ? 100
                          : 0
                      }
                    />
                    <br />
                    <br />
                    <b>Ground School</b>
                    <Progress percent={groundPercent} />
                    <br />
                    <br />
                    <b>Flight Training</b>
                    <Progress percent={flightPercent} />
                    <br />
                    <br />
                    <b>License Issue</b>
                    <Progress percent={percent} />
                  </Col>
                  <Col>
                    <Progress
                      type="circle"
                      percent={overallPercent}
                      style={{
                        marginRight: 16,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Space>
          </div>
        </Row>
      </Card>

      <Card>
        <List
          header={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <h4> Work Logs </h4>
              </div>
              <div>
                <Button type={'primary'} onClick={() => setModal2Open(true)}> Add New Log</Button>
              </div>
            </div>
          }
          footer={
            <div>
              End of log history <small> {new Date().toISOString()} </small>
            </div>
          }
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              {/* <Typography.Text mark>[WorkLog]</Typography.Text> {item.startDateTime} {item.endDateTime} */}

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignContent: 'center',
                }}
              >
                <div>
                  <Typography.Text mark>[WorkLog]</Typography.Text>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                  {item.title? item.title : 'Notes'}
                  <small>
                    {/* {item.notes} */}
                    {parse(JSON.stringify(item.notes))}
                  </small>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '20vh',
                  }}
                >
                  {item.startDateTime} - {item.endDateTime}
                </div>
                <div>{item.approvedBy}</div>
                <div>{item.status}</div>
              </div>
            </List.Item>
          )}
        />
        {showModal()}
      </Card>

      <Row gutter={8}>
        {/* <Col lg={12} sm={24} md={24} xs={24}>
            <ApplicantTimeline />
          </Col> */}
        <Col lg={24} sm={24} md={24} xs={24}></Col>
      </Row>
    </Layout>
  );
}

export default ApplicantDashboard;
