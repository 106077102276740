import { Button, Card, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  addJobApplicantNotes,
  fetchJobApplicantNotes,
} from "../store/reducers/jobApplicantReducer";
const { Title } = Typography;
const { TextArea } = Input;

function ApplicantComments() {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  let url_params = useParams();
  const Djangotoken = useSelector((state) => state?.auth?.token);
  const notesData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicantNotes
  );

  useEffect(() => {
    var data = {
      accessToken: Djangotoken,
      id: url_params?.applicantid,
    };
    dispatch(fetchJobApplicantNotes(data));
  }, []);

  const onFinish = (values) => {
    var raw = {
      accessToken: Djangotoken,
      note: values?.notes,
      id: url_params?.applicantid,
    };
    dispatch(addJobApplicantNotes(raw))
      .then((response) => {
        dispatch(fetchJobApplicantNotes(raw));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        marginBlock: "0.1%",
        padding: 14,
      }}
    >
      <Title
        level={3}
        style={{ padding: "0px", fontWeight: "normal", fontSize: "22px" }}
      >
        Additional Notes
      </Title>
      <div>
        {notesData?.map((note) => (
          <p>{note?.notes}</p>
        ))}
      </div>
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        onSubmit={onSubmit}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="notes">
          <TextArea placeholder="Add a Comment" rows={4} maxLength={6} />
        </Form.Item>
        <Button style={{ marginTop: "10px" }} htmlType="submit" type="primary">
          Submit Comment
        </Button>
      </Form>
    </Card>
  );
}

export default ApplicantComments;
