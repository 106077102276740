import React from "react";
import { Typography, Card, Row, Table, Space } from "antd";
import { useSelector } from "react-redux";
import AddTeamMember from "./AddTeamMembers";
import EditTeamMember from "./EditTeamMember";
const { Title } = Typography;

function ViewTeammembers() {
  const memberdetails = useSelector((state) => state?.teamReducer?.member);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (id) => (  
        <Space wrap>
          <EditTeamMember id={id}/>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        style={{
          marginBottom: "1%",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-3px",
          }}
        >
          <Title level={4} style={{ fontSize: "22px", fontWeight: "normal" }}>
            Your Team
          </Title>
          <AddTeamMember />
        </Row>
        <Table
          style={{ padding: "0px", textAlign: "center" }}
          columns={columns}
          dataSource={memberdetails}
          scroll={{ y: 250 }}
          size="small"
        />
      </Card>
    </>
  );
}

export default ViewTeammembers;
