import React, { useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Button, Drawer, Form } from "antd";

function ViewResume(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showDrawer}>View Documents</Button>
      <Drawer title="Your Documents" onClose={onClose} open={open} width="55%">
        <div className="container">
          <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <Viewer
                fileUrl={props?.resume_url}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </div>
        </div>
      </Drawer>
    </>
  );
}
export default ViewResume;

// ------------------------------------------------------------------->
// import React, { useState, useRef } from 'react';
// import { usePdf } from '@mikecousins/react-pdf';
// import { useLocation } from 'react-router-dom';
// import { Button, Card, Drawer } from 'antd';

// export default function ViewResume(props){
//     const [page, setPage] = useState(1);
//     const canvasRef = useRef(null);

//     const { pdfDocument, pdfPage } = usePdf({
//         file: props?.resume_url,
//         page,
//         canvasRef,
//     });
//     const [open, setOpen] = useState(false);

//   const showDrawer = () => {
//     setOpen(true);
//   };

//   const onClose = () => {
//     setOpen(false);
//   };

//   return (
//   <>
//     <Button type="primary" onClick={showDrawer}>View Resume</Button>
//     <Drawer
//     title="View Resume"
//      onClose={onClose}
//       open={open}
//        width="55%"
//        >
//     <div >
//         {!pdfDocument && <span>Loading...</span>}
//         <Card style={{ alignItems:"center" ,marginBlock: "0.05%" }}>
//         <canvas ref={canvasRef} />
//         {Boolean(pdfDocument && pdfDocument.numPages)
//         && (
//             <nav>
//                 <>
//                     <button disabled={page === 1} onClick={() => setPage(page - 1)}>
//                         Previous
//                     </button>
//                     <button disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)}>
//                         Next
//                     </button>
//                 </>
//             </nav>
//         )}
//         </Card>
//     </div>
//     </Drawer>
//     </>
//     );
// };
