import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; // fetchDriverAPIsHelper
import {
  addJobApplicantAPIsHelper,
  addJobApplicantNotesAPIsHelper,
  addBulkApllicantAPIsHelper,
  deleteSingleJobApplicantAPIsHelper,
  fetchJobApplicantAPIsHelper,
  fetchJobApplicantNotesAPIsHelper,
  updateJobApplicantAPIsHelper,
} from "../../helpers/jobApplicantAPI";

const initialState = {

  // use activeJob from Jobs reducer
  jobApplicants: [], // TODO: move this to Jobs reducer. Everything related to one job must be in Jobs reducers only
  jobApplicantNotes:[]
  // jobdashboard: [], // TODO: why this? We should use JOBs reducer to manage this as well.
  // jobapplicants: [], // this is payload, no need to save.
  // addjobapplicantData: "", // this is payload, no need to save.
  // fetchjobapplicantData: "",  // this is payload, no need to save.
  // deletejobapplicantData: "", // this is payload, no need to save.
  // updatejobapplicantData: "", // this is payload, no need to save.
  // msAccessToken: "", // moved to authReducer
};

// TODO :: is it one driver or all driver?? Rename appropriately/
export const fetchJobApplicant = createAsyncThunk(
  "recruiter/fetch-Job-applicants/",
  async (data, thunkAPI) => {
    try {
      const response = await fetchJobApplicantAPIsHelper(data); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addJobApplicant = createAsyncThunk(
  "recruiter/add-Job-applicants/",
  async (applicant_data, thunkAPI) => {
    try {
      const response = await addJobApplicantAPIsHelper(applicant_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateJobApplicant = createAsyncThunk(
  "recruiter/update-Job-applicants/?applicantId=7",
  async (jobApplicant_data, thunkAPI) => {
    try {
      const response = await updateJobApplicantAPIsHelper(jobApplicant_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// TODO: Rename function... how many are we deleting?
export const deleteSingleJobApplicant = createAsyncThunk(
  "recruiter/delete-Job-applicants/?applicantId=7",
  async (access_token, thunkAPI) => {
    try {
      const response = await deleteSingleJobApplicantAPIsHelper(access_token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addBulkJobApplicant = createAsyncThunk(
  "recruiter/add-bulk-Job-applicants/",
  async (applicant_data, thunkAPI) => {
    try {
      const response = await addBulkApllicantAPIsHelper(applicant_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// JOB APPLICANT NOTES REDUCER 

export const addJobApplicantNotes = createAsyncThunk(
  "recruiter/add-Job-applicant-Note/",
  async (applicant_data, thunkAPI) => {
    try {
      const response = await addJobApplicantNotesAPIsHelper(applicant_data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchJobApplicantNotes = createAsyncThunk(
  "recruiter/fetch-Job-applicant-Note/",
  async (data, thunkAPI) => {
    try {
      const response = await fetchJobApplicantNotesAPIsHelper(data); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const JobapplicantSlice = createSlice({
  name: "Jobapplicant",
  initialState,
  reducers: {
    fetch_Job_Applicant: (state, action) => {
      state.jobApplicants = action.payload;
    },
    add_Job_Applicant: (state, action) => {
      state.addjobapplicantData = action.payload;
    },
    update_Job_Applicant: (state, action) => {
      state.updatejobapplicantData = action.payload;
    },
    delete_Job_Applicant: (state, action) => {
      state.deletesinglejobapplicantData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TODO: Add cases for - deleted driver, updated driver,
    // TODO: everytime an action is made on a driver profile, we should update state.drivers array accordingly. Otherwise, stale data will be shown to users.
    builder
      .addCase(fetchJobApplicant.fulfilled, (state, action) => {
        console.log(action.payload.data.data, "JOBAPPLICANT REDUCER");
        state.jobApplicants = action.payload.data.data.applicants;
        state.jobdashboard = action.payload.data.data;
      })
      .addCase(fetchJobApplicant.rejected, (state, action) => {
        // state.fetchapplicantData = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(addJobApplicant.fulfilled, (state, action) => {
        state.addjobapplicantData = action.payload.data;
      })
      .addCase(addJobApplicant.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // state.addjobapplicantData = "";
      })
      .addCase(addBulkJobApplicant.fulfilled, (state, action) => {
        console.log("action", action);
        // state.jobApplicantNotes = action.payload.data;
      })
      .addCase(addBulkJobApplicant.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // state.addjobapplicantData = "";
      })
      .addCase(addJobApplicantNotes.fulfilled, (state, action) => {
        console.log("action", action);
        // state.jobApplicantNotes = action.payload.data;
      })
      .addCase(addJobApplicantNotes.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // state.addjobapplicantData = "";
      })
      .addCase(fetchJobApplicantNotes.fulfilled, (state, action) => {
        console.log("actionactionaction", action.payload.data.data);
        state.jobApplicantNotes = action.payload.data.data;
      })
      .addCase(fetchJobApplicantNotes.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // state.addjobapplicantData = "";
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  fetch_Job_Applicant,
  add_Job_Applicant,
  update_Job_Applicant,
  delete_Job_Applicant,
} = JobapplicantSlice.actions;

export default JobapplicantSlice.reducer;
