import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Table, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobApplicant } from "../store/reducers/jobApplicantReducer";
import ApplicantPositionActivity from "./ApplicantPositionActivity";

const AppliedCandidateList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const applicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicants
  );

  // const authData = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // let url_params = useParams();
  // useEffect(() => {
  //   const data = { token: authData.token, jobId: url_params.id };
  //   dispatch(fetchJobApplicant(data));
  // }, []);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   width: "100%",
    //   render: (text, record, index) => (
    //     <div
    //       style={{
    //         width: "200px",
    //       }}
    //     >
    //       {" "}
    //       <Checkbox
    //         onClick={(e) => {
    //           console.log("corresponding email is :", record.name);
    //         }}
    //       >
    //         {" "}
    //         Click{" "}
    //       </Checkbox>
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "100%",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      width: "100%",
      // render: (_, { email }) => (
      //   <>
      //     {email?.map((email) => {
      //       return (
      //         // <Tooltip placement="topLeft" title={skill.name}>
      //         <p>{email}</p>
      //         // </Tooltip>
      //       );
      //     })}
      //   </>
      // ),
    },
    // {
    //   title: "Phone Number",
    //   dataIndex: "phoneNumber",
    //   key: "phoneNumber",
    //   width: "100%",
    // },
    // Table.EXPAND_COLUMN,
    {
      title: "Stage",
      dataIndex: "status",
      key: "status",
      width: "100%",
      expandable: {
        expandedRowRender: () => (
          <p
            style={{
              margin: 0,
            }}
          >
            <ApplicantPositionActivity/>
          </p>
        ),
        rowExpandable: (record) => record.name !== 'Not Expandable',
      },
    },
    {
      title: "Date",
      dataIndex: "status_last_modified",
      key: "status_last_modified",
      width: "100%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "100%",
      // fixed: "right",
      render: (id) => (
        <Space wrap>
          <Link to={{ pathname: `./applicantSummary/${id}` }}>
            <Button color="Success"> View Trainee</Button>
          </Link>
        </Space>
      ),
    },
  ];
  return (
    <Card style={{ width: "100%", height: "60vh" }}>
      <Table
        style={{ padding: "0px", textAlign: "center" }}
        columns={columns}
        
        dataSource={applicantData}
        scroll={{ x: 350, y: 200 }}
        size="small"
      />
    </Card>
  );
};
export default AppliedCandidateList;
