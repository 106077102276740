import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCompanyProcessAPIsHelper,
  fetchSkillAPIsHelper,
  fetchChoicesAPIsHelper,
  fetchCompanyClientsAPIsHelper,
  addCompanyClientAPIsHelper
} from "../../helpers/otherAPI";

//
const initialState = {
  skils: [],
  companyprocessList: [],
  Choices: [],
    // categories: [],
    // jobType: [],
    // postingType: [],
  // }, // [ Agriculture, Finance ]
  clientCompany: [], // department
  client: [],
};

export const fetchSkills = createAsyncThunk(
  "other/fetchskill",
  async (token, thunkAPI) => {
    try {
      const response = await fetchSkillAPIsHelper(token); // userAPI.fetchById(userId)
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchCompanyProcess = createAsyncThunk(
  "other/fetch-company-process",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCompanyProcessAPIsHelper(data); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchClientCompany = createAsyncThunk(
  "other/fetchclientcompany",
  async (accessToken, thunkAPI) => {
    try {
      const response = await fetchCompanyClientsAPIsHelper(accessToken); // userAPI.fetchById(userId)
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const addClientCompany = createAsyncThunk(
  "other/addclient",
  async (data, thunkAPI) => {
    try {
      const response = await addCompanyClientAPIsHelper(data); // userAPI.fetchById(userId)
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchChoices = createAsyncThunk(
  "other/fetchcategories",
  async (accessToken, thunkAPI) => {
    try {
      const response = await fetchChoicesAPIsHelper(accessToken); // userAPI.fetchById(userId)
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkills.fulfilled, (state, action) => {
        state.skillList = action.payload.skills;
      })
      .addCase(fetchSkills.rejected, (state, action) => {
        state.errors = action.payload.error;
      })
      .addCase(fetchCompanyProcess.fulfilled, (state, action) => {
        state.companyprocessList = action.payload.data.data;
      })
      .addCase(fetchCompanyProcess.rejected, (state, action) => {
        console.log(action, "Error");
        // state.errors = action.payload.value;
      })
      .addCase(fetchClientCompany.fulfilled, (state, action) => {
        state.clientCompany = action.payload.companyclients;
      })
      .addCase(fetchClientCompany.rejected, (state, action) => {
        console.log(action, "Error");
        // state.errors = action.payload.value;
      })
      .addCase(addClientCompany.fulfilled, (state, action) => {
        state.client = action.payload.data;
        console.log(state.client, "udjbwjhb");
      })
      .addCase(addClientCompany.rejected, (state, action) => {
        console.log(action, "Error");
        // state.errors = action.payload.value;
      })
      .addCase(fetchChoices.fulfilled, (state, action) => {
        state.Choices = action.payload;
        console.log(state.Choices,'choicesAction')
      })
      .addCase(fetchChoices.rejected, (state, action) => {
        console.log(action, "Error");
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateSkillStatus, fetchCompanyProcessStatus, fetchCategoriesStatus, fetchClientCompanyStatus, fetchjobTypeStatus, fetchPostingTypeStatus, addClientCompanyStatus } =
  otherSlice.actions;

export default otherSlice.reducer;
