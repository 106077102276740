import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Typography, Card, Layout, Row, Col, Button } from "antd";
import ApplicantSummary from "../ats_components/ApplicantSummary";
import AppliedCandidate from "../ats_components/AppliedCandidate";
import { useDispatch, useSelector } from "react-redux";
import ApplicantTimeline from "../ats_components/ApplicantTimeline";
import ApplicantComments from "../ats_components/ApplicantComments";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import { msauthToken } from "../store/actions/authActions";

// const ProfileContent = () => {
//   const { instance, accounts } = useMsal();
//   const [graphData, setGraphData] = useState(null);
//   const dispatch = useDispatch();

//   function RequestProfileData() {
//     // Silently acquires an access token which is then attached to a request for MS Graph data
//     // console.log(instance.config.auth.redirectUri, "INTSTANCE");
//     // console.log(instance, "INTSTANCE");

//     instance
//       .acquireTokenSilent({
//         ...loginRequest,
//         account: accounts[0],
//       })
//       .then((response) => {
//         callMsGraph(response.accessToken).then(
//           dispatch(msauthToken(response?.accessToken)),
//           setGraphData(response)
//         );
//       });
//   }

//   return <>{graphData ? console.log("graph Data") : RequestProfileData()}</>;
// };

function ApplicantTracker() {
  let url_params = useParams();
  const jobapplicantData = useSelector(
    (state) => state?.jobApplicantReducer?.jobApplicants
  );
  const filteredjobapplicantData = jobapplicantData?.find(
    (data) => data.id == url_params?.applicantid
  );
  // console.log(filteredjobapplicantData, "FILTERED JOB APPLICANT DATA");

  // const isAuthenticated = useIsAuthenticated();
  // const { instance } = useMsal();

  // console.log(isAuthenticated, "Authenticated");
  // const handleLogout = () => {
  //   instance.logoutPopup({
  //     postLogoutRedirectUri: "/",
  //     mainWindowRedirectUri: "/",
  //   });
  // };

  // const handleLogin = () => {
  //   // instance.config.auth.redirectUri = `http://localhost:3000/home/positionSummary/${url_params.id}/applicant/${url_params.applicantid}`;
  //   instance.loginPopup(loginRequest).catch((e) => {
  //     console.log(e);
  //   });
  // };

  return (
    <Layout
      style={{
        marginRight: "3%",
        marginLeft: "3%",
        border: "shadow",
      }}
    >
      <ApplicantSummary jobapplicantData={filteredjobapplicantData} />
      <AppliedCandidate
      // isAuthenticated={isAuthenticated}
      // login={() => handleLogin()}
      />
      {/* <ProfileContent /> */}
      <Row gutter={8}>
        {/* <Col lg={12} sm={24} md={24} xs={24}>
          <ApplicantTimeline />
        </Col> */}
        <Col lg={24} sm={24} md={24} xs={24}>
          <ApplicantComments />
        </Col>
      </Row>
    </Layout>
  );
}

export default ApplicantTracker;
