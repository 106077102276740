import React from "react";
import { Layout, Typography, Card, Row, Col } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import Login from "../ats_components/Login";
import imglog from "../assets/images/dronepad.png"


const { Text, Title } = Typography;
const { Content } = Layout;

function LoginPage() {
  const { Header, Content, Footer, Sider } = Layout;

  return (
    <Layout style={{ height: "100vh", backgroundColor: "white" }}>
      <Content>
        <Row style={{ backgroundColor: "white" }}>
          <Col
            xs={0}
            sm={12}
            lg={12}
            style={{
              backgroundColor: "#eceef1",
            }}
          >
            <Row style={{ justifyContent: "center", marginTop: "8%" }}>
              <img
                style={{ height: "25%", width: "25%" }}
                src={imglog}
              />
            </Row>

            <Row>
              <Col
                style={{
                  marginLeft: "3%",
                  marginTop: "7%",
                  alignItems: "left",
                  width: "51%"
                  }}>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%" }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      User Management
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Training Management
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Scheduling and Notification
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Tracking and Progress Monitoring
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Assessment and Certification
                    </Text>
                  </Typography>
                </Row>
              </Col>
              <Col
                style={{
                    // marginLeft: "7%",
                  marginTop: "7%",
                  alignItems: "left",
                  width: "46%",
                }}
              >
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Record Keeping and Reporting
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Compliance and Safety
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Integration and Scalability
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      User-friendly Interface
                    </Text>
                  </Typography>
                </Row>
                <Row style={{margin:"5%"}}>
                  <CheckCircleFilled
                    style={{ fontSize: "30px", color: "#2B4A9D", marginRight: "5%"  }}
                  />
                  <Typography  >
                    <Text style={{ width: "100%", fontSize: "18px" }}>
                      Security and Privacy
                    </Text>
                  </Typography>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={2} lg={3}></Col>
          <Col
            xs={24}
            sm={8}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Login />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default LoginPage;
