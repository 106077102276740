import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplicant } from "../store/reducers/applicantReducer";
import { fetchRecruiterDashboard } from "../store/reducers/authReducer";
import { StandaloneSearchBox } from "@react-google-maps/api";
import ReactQuill from "react-quill";

function AddSingleApplicant(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFilePan, setSelectedFilePan] = useState();
  const [isFilePickedPan, setIsFilePickedPan] = useState(false);
  const [llocation, setLlocation] = useState("");
  const [value, setValue] = useState();
  const [quill, setQuill] = useState("");

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    // if (place) {
    //   console.log(place.formatted_address);
    //   console.log(place.geometry.location.lat());
    //   console.log(place.geometry.location.lng());
    // }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files);
    setIsFilePicked(true);
  };

  const changeHandlerpan = (event) => {
    setSelectedFilePan(event.target.files);
    setIsFilePickedPan(true);
  };

  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);
  const skillData = useSelector((state) => state?.otherReducer?.skillList);

  const onFinish = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${authData.token}`);
    var formdata = new FormData();

    formdata.append("name", values.name);
    formdata.append("email", values.email);
    formdata.append("phoneNumber", values.phoneNumber);
    formdata.append("birthdate", values.birthdate ? values.birthdate : "None");
    formdata.append("gender", values.gender ? values.gender : "None");
    formdata.append(
      "maritalStatus",
      values.maritalStatus ? values.maritalStatus : "None"
    );
    formdata.append(
      "fathersName",
      values.fathersName ? values.fathersName : "None"
    );
    formdata.append(
      "description",
      values.description ? values.description : "None"
    );
    formdata.append("resume", selectedFile[0]);
    formdata.append("pan", selectedFilePan[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      // "http://localhost:8000/api/recruiter/applicants/",
      "https://dronepad-api.alamanceinc.com/api/recruiter/applicants/",
      requestOptions
    )
      .then((result) => {
        if (result.success == true) {
          message.success("Trainee Added Successfully");
        }

        dispatch(fetchRecruiterDashboard(authData?.token)).then((res) => {
          dispatch(fetchApplicant(authData?.token))
            .then((response) => props?.onClose())
            .catch((error) => {
              console.log("error", error);
            });
        });
      })

      .catch((error) => console.log("error", error));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Drawer
        title="Add New Trainee"
        width="55%"
        onClose={props?.onClose}
        open={props?.open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          id="applicantform"
          encType="multipart/form-data"
          layout="vertical"
          initialValues={{ remember: true }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Trainee name",
                  },
                ]}
              >
                <Input placeholder="Enter Trainee name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Email",
                  },
                ]}
              >
                <Input
                  type="email"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Email"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Phone Number",
                  },
                ]}
              >
                <Input
                  type="text"
                  pattern="\d{10}"
                  title="Error: 10 digits are required."
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode &gt;= 48 && event.charCode &lt;= 57"
                  placeholder="Enter Phone Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="birthdate" label="Date of Birth">
                <Input
                  type="date"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Date of Birth"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="gender" label="Gender">
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Gender"
                  optionLabelProp="label"
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "transgender",
                      label: "Transgender",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item name="maritalStatus" label="Marital Status">
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Add your Marital Status"
                  optionLabelProp="label"
                  options={[
                    {
                      value: "Married",
                      label: "Married",
                    },
                    {
                      value: "Single",
                      label: "Single",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="fathersName"
                label="Father's Name"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Father's name",
                  },
                ]}
              >
                <Input placeholder="Enter Father's Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="description" label="Other Description">
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  placeholder="Other Description"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="resume"
                label="Adhaar"
                rules={[
                  {
                    required: true,
                    message: "Upload Resume",
                  },
                ]}
              >
                <input
                  type="file"
                  accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={changeHandler}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="pan"
                label="PAN"
                rules={[
                  {
                    required: true,
                    message: "Upload Resume",
                  },
                ]}
              >
                <input
                  type="file"
                  accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={changeHandlerpan}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Space>
                <Button onClick={props?.onClose}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default AddSingleApplicant;
