import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { Button, Drawer } from "antd";

const CertificateGenerator = (props) => {
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const convertToPdf = () => {
    const content = contentRef.current;
    const options = {
      margin: 1,
      filename: personName +"_"+ Date().toString() + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(content).save();
  };

  // Example usage
  const personName = props?.name ;

  return (
    <>
        <Button onClick={showDrawer}>View Certificate</Button>
        <Drawer title='Course Completion Certificate' extra={<Button type='primary' onClick={convertToPdf}>Download Certificate</Button>} onClose={onClose} open={open} width="55%">
            <div ref={contentRef}>
                <div
                    style={{
                    color: "black",
                    display: "table",
                    fontFamily: "Georgia, serif",
                    fontSize: "24px",
                    textAlign: "center",
                    }}
                >
                    <div style={{
                        border: "20px solid tan",
                        width: "750px",
                        height: "563px",
                        display: "table-cell",
                        verticalAlign: "middle",
                    }}
                    >
                        <h1 style={{ color: "tan" }}>Garuda Aerospace</h1>
                        <div style={{ color: "tan" }}>Training Completion Certificate</div>
                        <div style={{ margin: "20px", textTransform:'uppercase' }}>
                            This is to ceritfy that
                        </div>

                        <div
                            style={{
                            borderBottom: "2px solid black",
                            fontSize: "32px",
                            fontStyle: "italic",
                            margin: "20px auto",
                            width: "400px",
                            }}
                        >
                         Mr/Mrs.    {personName}
                        </div>

                        <div style={{ margin: "20px" }}>
                            Has successfully compelted
                            <br />
                            <h5> Advanced BVLOS Drone Pilot Training</h5>
                        </div>

                        <div style={{ margin: "20px", fontSize:18, flexDirection:'row', justifyContent:'space-around', display:'flex', marginInline:18, color:'green' }}>
                          <small> License No: PDT-123 </small><small> Issue Date: {new Date().toISOString().split('T')[0]}</small><small> Valid Through 2024-06-26</small>
                        </div>

                    </div>
                </div>
            </div>
            <br />

        </Drawer>
    </>
  );
};

export default CertificateGenerator;
