import React, { useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Button, Card, Col, Row } from "antd";
import { MailOutlined } from "@ant-design/icons";

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"; //Important please dont comment

const pdfjs = require("pdfjs-dist");
const winkNLP = require("wink-nlp"); // Load wink-nlp package.
const model = require("wink-eng-lite-web-model"); // Load english language model.
const nlp = winkNLP(model); // Instantiate winkNLP.
const its = nlp.its; // Obtain "its" helper to extract item properties.
const as = nlp.as; // Obtain "as" reducer helper to reduce a collection.

function ResumeProcess() {
  const [resumeAnalytics, setresumeAnalytics] = useState([]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");
  const [viewPdf, setViewPdf] = useState(null);
  const fileType = ["application/pdf"];

  async function getResumeDetails(src) {
    const srcdoc = await pdfjs.getDocument(src).promise;
    const numPages = await srcdoc.numPages;
    var text = "";
    var text1 = "";

    for (let i = 1; i < numPages + 1; i++) {
      const page = await srcdoc.getPage(i);
      const page_data = await page.getTextContent();
      const items = page_data.items.map((item) => {
        text = text.concat(" ", item.str);
      });
    }

    text1 = text.replace(/ +(?= )/g, "");
    const emailtext = text1.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        ? /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        : ""
    );
    const doc = nlp.readDoc(text1);

    // Finding Skill
    var skillwordFreq = doc
      .customEntities()
      .filter((customEntity) => {
        return customEntity.out(its.type) === "skill";
      })
      .out(its.normal, as.freqTable);

    var instanceCount = 0;
    var skillsDetail = skillwordFreq.map((skillword) => {
      instanceCount = instanceCount + skillword[1];
      return { skillname: skillword[0], count: skillword[1] };
    });

    // Finding Experience
    var expwordFreq = doc
      .customEntities()
      .filter((customEntity) => {
        return customEntity.out(its.type) === "Experience";
      })
      .out(its.normal, as.freqTable);
    // console.log(expwordFreq, "expDetail");
    var expDetail = expwordFreq.map((expword) => {
      return { expwordName: expword[0], count: expwordFreq[1] };
    });
    // console.log(expDetail);
    const candidateDetails = {
      id: src.id,
      name: src.name,
      email: emailtext ? emailtext[0] : "", //src.email, //
      totalexperience: expDetail,
      skillcount: skillwordFreq.length,
      skillsFound: skillsDetail,
      skillinstanceCount: instanceCount,
    };
    // console.log(candidateDetails);
    return candidateDetails;
  }

  async function getAllResumes(testData, patterns) {
    nlp.learnCustomEntities(patterns);
    const result = await getResumeDetails(testData, patterns);
    return result;
  }

  const patterns = [
    { name: "skill", patterns: ["CNN"] },
    { name: "skill", patterns: ["RNN"] },
    { name: "skill", patterns: ["DL"] },
    { name: "skill", patterns: ["react"] },
    { name: "skill", patterns: ["javascript"] },
    { name: "skill", patterns: ["django"] },
    { name: "skill", patterns: ["python"] },
    { name: "skill", patterns: ["Data Analysis"] },
    { name: "skill", patterns: ["machine Learning"] },
    { name: "skill", patterns: ["Data Science"] },
    { name: "skill", patterns: ["artificial Intelligence"] },
    { name: "skill", patterns: ["Problem Solving"] },
    { name: "skill", patterns: ["mysql"] },
    { name: "skill", patterns: ["postgres"] },
    { name: "skill", patterns: ["docker"] },
    { name: "skill", patterns: ["vb.net"] },
    { name: "skill", patterns: ["plsql"] },
    { name: "skill", patterns: ["redux"] },
    { name: "skill", patterns: ["mongoDb"] },
    { name: "skill", patterns: ["React JS"] },
    { name: "skill", patterns: ["React Native"] },
    { name: "skill", patterns: ["JS"] },
    { name: "skill", patterns: ["PHP"] },
    { name: "skill", patterns: ["php"] },
    { name: "skill", patterns: ["HTML"] },
    { name: "skill", patterns: ["CSS"] },
    { name: "skill", patterns: ["NoSQL"] },
    { name: "skill", patterns: ["Perl"] },
    { name: "skill", patterns: ["Rust"] },
    { name: "skill", patterns: ["Scala"] },
    { name: "skill", patterns: ["Typescript"] },
    { name: "skill", patterns: ["Ruby"] },
    { name: "skill", patterns: ["Node"] },
    { name: "skill", patterns: ["Express"] },
    { name: "skill", patterns: ["Angular"] },

    // { name: "skill", patterns: ["C"] },
    { name: "skill", patterns: ["Swift"] },
    { name: "skill", patterns: ["C++"] },
    { name: "skill", patterns: ["C#"] },
    { name: "skill", patterns: [".NET"] },
    { name: "skill", patterns: ["Kotlin"] },
    { name: "skill", patterns: ["Go"] },
    { name: "skill", patterns: ["Container"] },
    { name: "skill", patterns: ["Kubernetes"] },
    { name: "skill", patterns: ["API"] },
    { name: "skill", patterns: ["FAST API"] },
    { name: "skill", patterns: ["REST API"] },
    { name: "skill", patterns: ["RESTFULL API"] },
    { name: "skill", patterns: ["Postman API"] },
    { name: "skill", patterns: ["AWS"] },
    { name: "skill", patterns: ["Google Cloud"] },
    { name: "skill", patterns: ["MS Azure"] },
    { name: "skill", patterns: ["Azure"] },
    { name: "skill", patterns: ["Programming Skills"] },
    { name: "skill", patterns: ["Problem Solving"] },
    { name: "skill", patterns: ["Critical Thinking"] },
    { name: "skill", patterns: ["Decision Making"] },
    { name: "skill", patterns: ["Predictive Modeling"] },
    { name: "skill", patterns: ["Data Structures"] },
    { name: "skill", patterns: ["Data Modeling"] },

    { name: "Experience", patterns: ["Machine Learning Engineer"] },
    { name: "Experience", patterns: ["Artificial Intelligence Engineer"] },
    { name: "Experience", patterns: ["ML Engineer"] },
    { name: "Experience", patterns: ["AI Engineer"] },
    { name: "Experience", patterns: ["Data scientist"] },
    { name: "Experience", patterns: ["Data Analyst"] },
    { name: "Experience", patterns: ["software engineer"] },
    { name: "Experience", patterns: ["software developer"] },
    { name: "Experience", patterns: ["junior software engineer"] },
    { name: "Experience", patterns: ["junior software developer"] },
    { name: "Experience", patterns: ["senior software engineer"] },
    { name: "Experience", patterns: ["senior software developer"] },
    { name: "Experience", patterns: ["associate software engineer"] },
    { name: "Experience", patterns: ["associate software developer"] },
    { name: "Experience", patterns: ["Front-end developer"] },
    { name: "Experience", patterns: ["Front End developer"] },
    { name: "Experience", patterns: ["Back-end developer"] },
    { name: "Experience", patterns: ["Back End developer"] },
    { name: "Experience", patterns: ["Full-stack developer"] },
    { name: "Experience", patterns: ["Full Stack developer"] },
    { name: "Experience", patterns: ["Intern"] },
    { name: "Experience", patterns: ["Tester"] },
    { name: "Experience", patterns: ["QA Automation"] },
    { name: "Experience", patterns: ["QA Tester"] },
    { name: "Experience", patterns: ["Automation Tester"] },
    { name: "Experience", patterns: ["Technical lead"] },
    { name: "Experience", patterns: ["Team Lead"] },
  ];

  // ------------------------------------>

  const handlePdfFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setPdfFile(e.target.result);
          setPdfFileError("");
        };
      } else {
        setPdfFile(null);
        setPdfFileError("Please select valid pdf file");
      }
    }
  };

  const handlePdfFileSubmit = (e) => {
    e.preventDefault();
    if (pdfFile !== null) {
      setViewPdf(pdfFile);

      getAllResumes(pdfFile, patterns).then((result) => {
        setresumeAnalytics(result);
      });
    } else {
      setViewPdf(null);
    }
  };

  return (
    <div style={{ alignItems: "center", backgroundColor: "#F0F2F4" }}>
      <h2 style={{ paddingTop: "5%", textAlign: "center" }}>
        Resume Analytics
      </h2>

      <Card
        style={{
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <br></br>
        <form className="form-group" onSubmit={handlePdfFileSubmit}>
          <input
            type="file"
            className="form-control"
            required
            onChange={handlePdfFileChange}
          />
          {pdfFileError && <div className="error-msg">{pdfFileError}</div>}
          <br></br>
          <Button
            htmlType="submit"
            type="primary"
            style={{
              color: "white",
              backgroundColor: "#1890FF",
              border: "none",
              marginTop: "5px",
            }}
          >
            View Results
          </Button>
        </form>
      </Card>

      <div
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "5%",
          alignItems: "center",
          backgroundColor: "#F0F2F4",
        }}
      >
        <Row gutter={4}>
          <Col span={10}>
            <Card bordered={false}>
              <h2 style={{ textAlign: "center" }}>Resume Details</h2>
              <Card>
                <p>
                  Name : {resumeAnalytics?.email ? resumeAnalytics?.email : "-"}
                </p>

                <p>
                  Email :{" "}
                  {resumeAnalytics?.email ? resumeAnalytics?.email : "-"}
                </p>
              </Card>
              <Card>
                <p>
                  <b>
                    {resumeAnalytics?.skillcount ? (
                      <b>{resumeAnalytics?.skillcount} </b>
                    ) : (
                      "0  "
                    )}
                    {"  "}
                    Skills Found
                  </b>
                </p>
                <p>
                  {resumeAnalytics?.skillsFound?.map((item) => {
                    return (
                      <>
                        {" "}
                        <b>{item?.skillname}</b>
                        <br />
                      </>
                    );
                  })}
                </p>
              </Card>

              <Card>
                <p>
                  <b>
                    {resumeAnalytics?.totalexperience ? (
                      <b>{resumeAnalytics?.totalexperience?.length} </b>
                    ) : (
                      "0  "
                    )}
                    {"  "}
                    Experience Found
                  </b>
                </p>
                <p>
                  {resumeAnalytics?.totalexperience?.map((item) => {
                    return (
                      <>
                        {" "}
                        <b>{item?.expwordName}</b>
                        <br />
                      </>
                    );
                  })}
                </p>
              </Card>
            </Card>
          </Col>
          <Col span={14}>
            <Card bordered={false}>
              <h2 style={{ textAlign: "center" }}>Your Resume</h2>
              <Card bordered={false}>
                {viewPdf && (
                  <>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </>
                )}
                {!viewPdf && <p>No pdf file selected</p>}
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ResumeProcess;
